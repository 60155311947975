<app-header></app-header>
<app-sidebar></app-sidebar>
<!--12 may work-->
<!--12 may work-->
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12">

                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Edit Profile</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card crd-brd">
                    <div class="main-profl row">
                        <div class="col-md-7 Rightline">
                            <div class="img-prrt1 clearfix">
                                <div class="i-pr1">
                                    <!-- <span><img [src]="[imageURL]" alt="user" class="rounded-circle"
                                            *ngIf="imageURL ; else dummyImage" /></span>

                                    <ng-template #dummyImage>
                                        <span><img src="./assets/assets/images/user.png" alt="user" class="rounded-circle"
                                                width="50" height="50" /></span>
                                    </ng-template> -->
                                    <span class="img_cam_upload">

                                        <img [src]="[imageURL]" alt="user" class="rounded-circle" *ngIf="imageURL"
                                            (click)="selectImage()" width="80" height="80" />
                                        <div class="ovrly_mng" (click)="selectImage()"><i class="fa fa-pencil"
                                                aria-hidden="true"></i></div>
                                    </span>
                                    <form [formGroup]="imageForm" enctype="multipart/form-data">
                                        <label>
                                            <input type="file" class="form-control d-none" id="file"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                (change)="onFileChange($event)" formControlName="profile_image">
                                        </label>
                                        <input class="form-control" type="hidden" formControlName="id"
                                            [ngModel]="user?.id" />
                                        <br><br>
                                        <div class="img-btn-custom">
                                            <button type="submit" class="btn btn-primary m-t-45 m-l-26 sve-vtbt"
                                                id="submitImage" (click)="submitImage()" [disabled]="!imageForm.valid"
                                                *ngIf="isimageChange">
                                                Save
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                            <button type="submit" class="btn btn-danger m-t-65 cansl-vtbt"
                                                (click)="cancelImage()" [disabled]="!imageForm.valid"
                                                *ngIf="isimageChange">
                                                Delete
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="contehnt-pr2">
                                    <h3 class="hd-con">
                                        {{user?.name}}
                                    </h3>
                                    <div *ngIf="sameUser; else notSameUser">
                                        <!-- <a class="" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                                            aria-controls="collapseExample" *ngIf="sameUser && user?.industry_id==null;" (click)="clickEvent()"
                                            [ngClass]="isIndustryShow ? 'show' : ''">
                                            Add your main industry
                                        </a>
                                        <p class="dsg-ttl xls-edtimn m-b-0" *ngIf="sameUser&&user?.industry_id">
                                            {{industry_name}}
                                            <a href="javascript:" class="text-center btn" role="button" aria-expanded="false" (click)="clickEvent()"
                                                [ngClass]="isHeadlineShow ? 'show' : ''">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                        </p> -->
                                        <p *ngIf="summary_title==null">
                                            <a href="javascript:" class="text-center" (click)="showHeadline()"
                                                [ngClass]="isHeadlineShow ? 'show' : ''">
                                                Add Headline
                                            </a>
                                        </p>
                                        <p *ngIf="sameUser && summary_title" class="xls-edtimn">{{summary_title}}
                                            <a href="javascript:" class="text-center btn" role="button"
                                                aria-expanded="false" (click)="showHeadline()"
                                                [ngClass]="isHeadlineShow ? 'show' : ''">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                        </p>
                                    </div>

                                    <ng-template #notSameUser>
                                        <p *ngIf="user.industry_id">{{industry_name}}</p>
                                        <p *ngIf="user.country_id"><small>Country :</small> {{country_name}} ,
                                            <small>State
                                                :</small> {{state_name}}
                                        </p>
                                        <p *ngIf="summary_title">{{summary_title}}</p>
                                    </ng-template>

                                    <p class="ProfileCompleted">Profile Completed {{user?.profile_percentage | number :
                                        '1.0-0'}}%</p>
                                    <div class="progress progress-xs">
                                        <div class="progress-bar progress-bar-warning" role="progressbar"
                                            attr.aria-valuenow="{{user?.profile_percentage}}" aria-valuemin="0"
                                            aria-valuemax="100" [style.width]="profilePercentage">
                                            <span class="sr-only">{{user?.profile_percentage}}% Complete</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="indus-content">
                                <ul>
                                    <li>
                                        <span class="rightHeading" style="font-weight: 500;">Industry :</span>
                                        <!-- <span *ngIf="user?.industry_id"> {{industry_name}}</span> -->
                                        <a class="" data-toggle="collapse" href="#collapseExample" role="button"
                                            aria-expanded="false" aria-controls="collapseExample"
                                            *ngIf="sameUser && user?.industry_id==null;" (click)="clickEvent()"
                                            [ngClass]="isIndustryShow ? 'show' : ''">
                                            Add your main industry
                                        </a>
                                        <span class="xls-edtimn m-b-0" *ngIf="sameUser&&user?.industry_id">
                                            {{industry_name}}
                                            <a href="javascript:" class="text-center btn" role="button"
                                                aria-expanded="false" (click)="clickEvent()"
                                                [ngClass]="isHeadlineShow ? 'show' : ''">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </li>
                                    <!-- <li>
                                        <span style="font-weight: 500;">Experience Years :</span>
                                        <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                            *ngIf="sameUser && user?.total_experience==null;" (click)="showFullExp()"
                                            [ngClass]="isfullExpShow ? 'show' : ''">
                                            Add Total Experience
                                        </a>
                                        <span *ngIf="user?.total_experience" class="xls-edtimn">
                                            {{user?.total_experience}}
                                            <a href="javascript:" class="text-center btn" role="button"
                                                aria-expanded="false" (click)="showFullExp()"
                                                [ngClass]="isfullExpShow ? 'show' : ''">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </li> -->
                                    <li>
                                        <span class="rightHeading" style="font-weight: 500;">Family or Non - Family Business :</span>
                                        <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                            *ngIf="sameUser && user?.total_experience==null;" (click)="showFullExp()"
                                            [ngClass]="isfullExpShow ? 'show' : ''">
                                            Select Family or Non - Family Business
                                        </a>
                                        <span *ngIf="user?.total_experience" class="xls-edtimn">
                                            {{user?.total_experience}}
                                            <a href="javascript:" class="text-center btn" role="button"
                                                aria-expanded="false" (click)="showFullExp()"
                                                [ngClass]="isfullExpShow ? 'show' : ''">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </li>
                                    <li class="icn-indus">
                                        <span class="rightHeading" style="font-weight: 500;">Email : </span>
                                        <!-- <i class="icon-envelope"></i> -->
                                        <span>{{user?.email}}</span>
                                    </li>
                                    <li class="icn-indus ">
                                        <!-- <i class="fa fa-map-marker" aria-hidden="true"
                                            style="vertical-align: baseline;"></i> -->
                                            <span class="rightHeading" style="font-weight: 500;">Country : </span>
                                        <a href="javascript:" class="text-center " role="button" aria-expanded="false"
                                            *ngIf="sameUser && user?.country_id==null;" (click)="showCountry()"
                                            [ngClass]="isCountryShow ? 'show' : ''">
                                            Add Location you are currently based at...
                                        </a>

                                        <span *ngIf="sameUser&&user?.country_id" class="">{{state_name}},
                                            {{country_name}}
                                            <a href="javascript:" class="text-center state-icon" role="button"
                                                aria-expanded="false" (click)="showCountry()"
                                                [ngClass]="isCountryShow ? 'show' : ''">
                                                <i class="fa fa-pencil " aria-hidden="true"></i>
                                            </a>

                                        </span>
                                    </li>
                                    <li>
                                        <p class="ic-img-cn">
                                            <span class="rightHeading" style="font-weight: 500;">Social Media Handles : </span>
                                            <a *ngIf="user?.linkedin_id!=null" href="{{ user?.linkedin_id }}"
                                                target="_blank"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                                            <!-- <a href="{{ user?.fb_id }}" target="_blank"><img src="./assets/assets/images/fb-hvr.png"></a> -->
                                            <a *ngIf="user?.fb_id!=null" href="{{ user?.fb_id }}" target="_blank"><img
                                                    src="./assets/assets/images/twit-hvr.png"></a>

                                            <a href="javascript:" class="text-center" role="button"
                                                aria-expanded="false" *ngIf="sameUser && user?.linkedin_id==null;"
                                                (click)="showSocial()" [ngClass]="isSocialShow ? 'show' : ''">
                                                Add Social Details
                                            </a>
                                            <em class="xls-edtimn">
                                                <a href="javascript:" class="text-center btn" role="button"
                                                    aria-expanded="false" *ngIf="sameUser && user?.linkedin_id!==null;"
                                                    (click)="showSocial()" [ngClass]="isSocialShow ? 'show' : ''">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </a>
                                            </em>
                                        </p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="body text-center">
                        <div class="" data-percent="100">
                            <span class="img_cam_upload">
                                <i class="fa fa-camera" aria-hidden="true"></i>
                                <img [src]="[imageURL]" alt="user" class="rounded-circle" *ngIf="imageURL" (click)="selectImage()" width="80" height="80" />
                                <div class="ovrly_mng"  (click)="selectImage()"></div>
                            </span>
                            <form [formGroup]="imageForm" enctype="multipart/form-data">
                                <label>
                                    <input type="file" class="form-control d-none"
                                        id="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        (change)="onFileChange($event)"
                                        formControlName="profile_image" >
                                    </label>
                                <input class="form-control" type="hidden" formControlName="id" [ngModel]="user?.id" />
                                <br><br>
                                <div class="img-btn-custom">
                                    <button type="submit" class="btn btn-primary m-t-45 m-l-26" id="submitImage" (click)="submitImage()" [disabled]="!imageForm.valid" *ngIf="isimageChange">
                                        Save
                                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                    </button>
                                    <button type="submit" class="btn btn-danger m-t-65" (click)="cancelImage()" [disabled]="!imageForm.valid" *ngIf="isimageChange">
                                        Delete
                                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <h2>{{user?.name}}</h2>

                        <div class="col-sm-12">
                            <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
                                <li  [ngClass]="{'selected': (star <= rate)}">
                                    <i class="fa fa-star"></i>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="sameUser; else notSameUser">
                            <a class="" data-toggle="collapse" href="#collapseExample" role="button"
                                aria-expanded="false" aria-controls="collapseExample"
                                *ngIf="sameUser && user?.industry_id==null;" (click)="clickEvent()"
                                [ngClass]="isIndustryShow ? 'show' : ''">
                                Add your main industry
                            </a>
                            <h5 *ngIf="sameUser&&user?.industry_id">{{industry_name}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                    (click)="clickEvent()" [ngClass]="isHeadlineShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>

                            <br />
                            <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                *ngIf="sameUser && user?.country_id==null;" (click)="showCountry()"
                                [ngClass]="isCountryShow ? 'show' : ''">
                                Add Location you are currently based at...
                            </a>
                            <h5 *ngIf="sameUser&&user?.country_id">
                                <small>Country :</small> {{country_name}} , <small>State :</small> {{state_name}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                    (click)="showCountry()" [ngClass]="isCountryShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>
                            <br />
                            <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                *ngIf="sameUser&&summary_title==null ;" (click)="showHeadline()"
                                [ngClass]="isHeadlineShow ? 'show' : ''">
                                Add Headline
                            </a>
                            <h5 *ngIf="sameUser&&summary_title">{{summary_title}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false"
                                    (click)="showHeadline()" [ngClass]="isHeadlineShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>
                        </div>

                        <ng-template #notSameUser>
                            <h5 *ngIf="user.industry_id">{{industry_name}}</h5>
                            <h5 *ngIf="user.country_id"><small>Country :</small> {{country_name}} , <small>State
                                    :</small> {{state_name}}</h5>
                            <h5 *ngIf="summary_title">{{summary_title}}</h5>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 m-b-5">
                <div class="card-body">
                    <div class="body text-center">
                        <p>Your profile is {{user?.profile_percentage}}% complete</p>
                        <div class="progress m-b-25 m-t-25">
                            <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                                [style.width]="profilePercentage">
                                <span class="sr-only">{{user?.profile_percentage}}% Complete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="body">
            <div class="row">
                <div class="col-md-12">
                    <div class="collapse" [ngClass]="isIndustryShow ? 'show' : ''">
                        <!-- id="collapseExample" -->
                        <div class="card card-body">
                            <form [formGroup]="editForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <label>Industry <span>*</span></label>
                                    <select formControlName="industry_id" class="form-control"
                                        [(ngModel)]="SelectedIndustry_id">
                                        <option [ngValue]="null" disabled>Select Industry</option>
                                        <option *ngFor="let industry of industryList" [ngValue]="industry.id">
                                            {{industry.title}}</option>
                                    </select>
                                    <span class="text-danger"
                                        *ngIf="control.industry_id.dirty && control.industry_id.hasError('required')">
                                        Industry field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="cancelEvent()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="submit()"
                                    [disabled]="!editForm.valid">
                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isCountryShow ? 'show' : ''">
                        <div class="card card-body" *ngIf="(isCountryShow)">
                            <form [formGroup]="countryForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <label>Country <span>*</span></label>
                                    <select formControlName="country_id" class="form-control"
                                        [(ngModel)]="Selectedcountry_id"
                                        (change)="onChangeCountry($event.target.value)">
                                        <option [ngValue]="null" disabled>Select Country</option>
                                        <option *ngFor="let country of countryList" [ngValue]="country.id">
                                            {{country.name}}</option>
                                    </select>
                                    <span class="text-danger"
                                        *ngIf="ccontrol.country_id.dirty && ccontrol.country_id.hasError('required')">
                                        Country field Required.
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label>State/County <span>*</span></label>
                                    <select formControlName="state_id" class="form-control"
                                        [(ngModel)]="Selectedstate_id">
                                        <option [ngValue]="null" disabled>Select State</option>
                                        <option *ngFor="let state of stateList" [ngValue]="state.id">{{state.name}}
                                        </option>
                                    </select>
                                    <span class="text-danger"
                                        *ngIf="ccontrol.state_id.dirty && ccontrol.state_id.hasError('required')">
                                        State field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="cancelCountry()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveCountry()"
                                    [disabled]="!countryForm.valid">
                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isHeadlineShow ? 'show' : ''">
                        <div class="card card-body" *ngIf="(isHeadlineShow)">
                            <form [formGroup]="headlineForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <label>Profile Summary <span>*</span></label>
                                    <input class="form-control" type="text" formControlName="summary_title"
                                        [(ngModel)]="summary_title" />
                                    <span class="text-danger"
                                        *ngIf="hcontrol.summary_title.dirty && hcontrol.summary_title.hasError('required')">
                                        Headline field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="cancelHeadline()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveHeadline()"
                                    [disabled]="!headlineForm.valid">
                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isfullExpShow ? 'show' : ''">
                        <div class="card card-body" *ngIf="(isfullExpShow)">
                            <form [formGroup]="fullExpForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <!-- <label>Total Experience [ In Year(s) ] <span>*</span></label> -->
                                    <label>Family or Non -Family Business <span>*</span></label>
                                    <!-- <input class="form-control" type="number" formControlName="total_experience"
                                        placeholder="Enter total experience in year" /> -->
                                    <select formControlName="total_experience" class="form-control" [(ngModel)]="Seltotal_experience">
                                        <option [ngValue]="null" selected hidden>Select</option>
                                        <option>Yes</option>
                                         <option>No</option>
                                    </select>
                                    <span class="text-danger"
                                        *ngIf="FExpControl.total_experience.dirty && FExpControl.total_experience.hasError('required')">
                                        Family or Non -Family Business field Required.
                                    </span>
                                    <!-- <span class="text-danger"
                                        *ngIf="FExpControl.total_experience.dirty && (FExpControl.total_experience.hasError('min') || FExpControl.total_experience.hasError('max'))">
                                        Total Experience should be between 1 and 50.
                                    </span> -->
                                </div>
                                <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="cancelFullExp()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveFullExp()"
                                    [disabled]="!fullExpForm.valid">
                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isSocialShow ? 'show' : ''">
                        <div class="card card-body" *ngIf="(isSocialShow)">
                            <form [formGroup]="socialForm" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Linkedin Link</label>
                                        <input class="form-control" type="text" formControlName="linkedin_id"
                                            placeholder="https://www.linkedin.com" />
                                        <span class="text-danger"
                                            *ngIf="socialControl.linkedin_id.dirty && socialControl.linkedin_id.hasError('required')">
                                            Linkedin Link field Required.
                                        </span>
                                        <span class="text-danger" *ngIf="socialControl.linkedin_id.errors?.pattern">
                                            Invalid Linkedin Link.
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Twitter Link</label>
                                        <input class="form-control" type="text" formControlName="fb_id"
                                            placeholder="https://twitter.com" />
                                        <span class="text-danger"
                                            *ngIf="socialControl.fb_id.dirty && socialControl.fb_id.hasError('required')">
                                            Twitter Link field Required.
                                        </span>
                                        <span class="text-danger" *ngIf="socialControl.fb_id.errors?.pattern">
                                            Invalid Twitter Link.
                                        </span>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample" (click)="cancelSocial()">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                            (click)="saveSocial()" [disabled]="!socialForm.valid">
                                            Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="Profiletabs">
            <div class="jobTabs">
                <ul class="nav nav-tabs ">
                    <li class="nav-item"><a class="nav-link active show" data-toggle="tab" href="#Summary"
                            (click)="showSummary()">Summary</a></li>
                    <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Experience"
                            (click)="showExperience()">Experience</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Education"
                            (click)="showEducation()">Education</a></li> -->
                    <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Certificate"
                            (click)="showCertificate()">Certification</a></li> -->
                    <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Achievement"
                            (click)="showAchievement()">Qualifications </a></li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane " id="Summary" [ngClass]="isSummaryShow ? 'show active' : ''">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card card-summary-vt">
                                <h5>Summary</h5>
                                <!-- <article class="pls-btn" (click)="addExperience()"><i class="fa fa-plus"></i></article> -->
                                <article class="pls-btn" (click)="addSummary(SummaryModal)"><i class="fa fa-plus"></i>
                                </article><!--  *ngIf="user.summary_description" -->
                                <div class="card-body "> <!-- *ngIf="(isSummaryShow)" -->
                                    <span>
                                        <div class="m-t-5 pull-right m-r-5 xls-edtimn">
                                            <button type="button" class="btn btn-primary"
                                                (click)="addSummary(SummaryModal)"
                                                *ngIf="user?.summary_description!=null">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger m-l-5" (click)="deleteSummary()"
                                                *ngIf="user?.summary_description!=null">
                                                <i class="fa fa-close"></i>
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                            <!-- <button class="btn btn-warning" type="button" (click)="deleteSummary()" aria-controls="collapseExample"
                                                *ngIf="user?.summary_description!=null">
                                                Delete
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button> -->
                                        </div>
                                    </span>
                                    <div class="col-md-11 col-sm-11 Description"
                                        *ngIf="user?.summary_description!=null">
                                        <!-- <label>Description</label> -->
                                        <p style="padding:0px;" [innerHTML]="user?.summary_description"></p>
                                    </div>
                                    <div class="col-md-11 col-sm-11 Description"
                                        *ngIf="user?.summary_description == null">
                                        <label>No Summary Found</label><br />
                                        <label>You can add Summary by clicking plus button.</label>
                                    </div>
                                    <!-- <div class="card-body" *ngIf="summary_description.length==0">
                                        <div class="col-md-12 col-sm-12">
                                            <label>No Summary Found</label><br />
                                            <label>You can add Summary by clicking plus button.</label>
                                        </div>
                                    </div> -->
                                    <!-- <form [formGroup]="summaryForm" enctype="multipart/form-data">
                                        <div class="form-group">
                                            <label>Your summary should make a big impact in short amount of time, so
                                                we recommended sticking to around of 200 words.
                                                <span>*</span></label>
                                            <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig"
                                                formControlName="summary_description" [(ngModel)]="summary_description"></angular-editor>
                                            <span class="text-danger"
                                                *ngIf="scontrol.summary_description.dirty && scontrol.summary_description.hasError('required')">
                                                Summary description field Required.
                                            </span>
                                        </div>
                                        <button class="btn btn-warning" type="button" (click)="deleteSummary()" aria-controls="collapseExample">
                                            Delete
                                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5" (click)="saveSummary()" [disabled]="!summaryForm.valid">
                                            Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form> -->
                                </div>

                                <ng-template #SummaryModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Add / Edit Summary</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isSummaryShow && isSummaryEdit">
                                            <!-- *ngIf="isAchievmentShow && isEditAchiv" -->
                                            <form [formGroup]="summaryForm" enctype="multipart/form-data">
                                                <div class="form-group">
                                                    <label>Your summary should make a big impact in short amount of
                                                        time, so
                                                        we recommended sticking to around of 200 words.
                                                        <span>*</span></label>
                                                    <angular-editor [placeholder]="'Enter text here...'"
                                                        [config]="editorConfig" formControlName="summary_description"
                                                        [(ngModel)]="summary_description"></angular-editor>
                                                    <span class="text-danger"
                                                        *ngIf="scontrol.summary_description.dirty && scontrol.summary_description.hasError('required')">
                                                        Summary description field Required.
                                                    </span>
                                                    <span class="text-danger"
                                                        *ngIf="scontrol.summary_description.dirty && scontrol.summary_description.hasError('maxlength')">
                                                        Allowed max 600 chatactors.
                                                    </span>
                                                </div>
                                                <!-- <button class="btn btn-warning" type="button" (click)="deleteSummary()" aria-controls="collapseExample"
                                                *ngIf="user?.summary_description!=null">
                                                    Delete
                                                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button> -->
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="saveSummary()" [disabled]="!summaryForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                                <button class="btn btn-danger m-l-5 cansl-vtbt" type="button"
                                                    (click)="cancelForm('summary', 'editTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <!--./end summary-->

                <div class="tab-pane" id="Experience" [ngClass]="isExperienceShow ? 'show active' : ''">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card card-experiences-vt "> <!-- *ngIf="experiences.length>0" -->
                                <h5>Experience</h5>
                                <!-- <article class="pls-btn" (click)="addExperience()"><i class="fa fa-plus"></i></article> -->
                                <article class="pls-btn" (click)="addExperienceModal(ExpAddModal)"><i
                                        class="fa fa-plus"></i></article>
                                <div class="card-body" *ngFor="let value of experiences; let j=index;let last = last;">
                                    <span>
                                        <h6>{{value.job_title}}</h6>
                                        <p>{{j+1}}</p>
                                        <div class="m-t-5 pull-right m-r-5 xls-edtimn">
                                            <!-- <button type="button" class="btn btn-primary" (click)="editExperience(value.id, j)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button> -->
                                            <button type="button" class="btn btn-primary"
                                                (click)="editExperienceModal(value.id, j, ExpModal)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button>

                                            <button type="button" class="btn btn-danger m-l-5"
                                                (click)="deleteExperience(value.id)">
                                                <i class="fa fa-close"></i>
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                    </span>
                                    <div class="col-md-11 col-sm-11">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>Company:</b> {{value.company}}</li>
                                                        <li><b>Start Date:</b>
                                                            <em *ngIf="value.start_month!=null">{{
                                                                getMonth(value.start_month) }},
                                                                {{value.start_year}}</em>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>Location:</b> {{value.location}}</li>
                                                        <span *ngIf="value.is_current==1">
                                                            <li><b>Currently working: </b> Yes </li>
                                                        </span>
                                                        <span *ngIf="value.is_current==0">
                                                            <li><b>End Date:</b> <em *ngIf="value.end_month!=null">{{
                                                                    getMonth(value.end_month) }},
                                                                    {{value.end_year}}</em>
                                                            </li>
                                                        </span>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12 Description">
                                        <label>Description</label>
                                        <p [innerHTML]="value.description"></p>
                                    </div>
                                    <hr *ngIf="!last">
                                </div>

                                <div class="card-body" *ngIf="experiences.length==0">
                                    <div class="col-md-12 col-sm-12">
                                        <label>No Experience Found</label><br />
                                        <label>You can add experience by clicking plus button.</label>
                                    </div>
                                </div>

                                <!-- <div class="card-body" *ngIf="isExperienceShow && isFirstExp">
                                    <hr>
                                    <form [formGroup]="ExperienceForm" enctype="multipart/form-data">
                                        <section formArrayName="experience_field">
                                            <div *ngFor="let quantity of experience_field().controls; let i=index" [formGroupName]="i">
                                                <div class="row" *ngIf="i>0">
                                                    <div class="col-md-12 form-group ">
                                                        <h5>
                                                            Experience{{i+1}}
                                                            <button (click)="removeExperience(i)" class=" pull-right btn-sm-vr">
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </button>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Job Title<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="job_title" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.job_title.dirty && quantity.controls.job_title.hasError('required')">
                                                            Job title field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Company<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="company" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.company.dirty && quantity.controls.company.hasError('required')">
                                                            Company field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Location<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="location" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.location.dirty && quantity.controls.location.hasError('required')">
                                                            Location field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.start_month.dirty && quantity.controls.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.start_year.dirty && quantity.controls.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.end_month.dirty && quantity.controls.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.end_year.dirty && quantity.controls.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">

                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input" formControlName="is_current" [value]="1" (change)="checkstateAdd($event, i, 'experience')" id="">
                                                            <label class="form-check-label" for="">Currently working here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig"
                                                            formControlName="description"></angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.description.dirty && quantity.controls.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </section>
                                        <button class="btn btn-danger cansl-vtbt" type="button" (click)="cancelForm('experience', 'firstTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveExperience()"
                                            [disabled]="!ExperienceForm.valid">
                                            save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div> -->

                                <ng-template #ExpModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Edit Experience</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isExperienceShow && isEditExp"><!--  -->
                                            <form [formGroup]="ExpEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Job Title<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="job_title" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.job_title.dirty && expEditcontrol.job_title.hasError('required')">
                                                            Job title field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Company<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="company" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.company.dirty && expEditcontrol.company.hasError('required')">
                                                            Company field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Location<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="location" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.location.dirty && expEditcontrol.location.hasError('required')">
                                                            Location field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month
                                                            </option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.start_month.dirty && expEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.start_year.dirty && expEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.end_month.dirty && expEditcontrol.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.end_year.dirty && expEditcontrol.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="ExpEditForm.errors !== null && ExpEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group pull-right ">
                                                        <!-- <label class="fancy-checkbox">
                                                            <input type="checkbox" formControlName="is_current" [value]="1">
                                                            <span><i></i>Currently working here</span>
                                                        </label> -->
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_current" [value]="1"
                                                                (change)="checkstate($event, 'experience')" id="">
                                                            <label class="form-check-label" for="">Currently working
                                                                here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.description.dirty && expEditcontrol.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <input type="hidden" formControlName="id">
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('experience', 'editTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="updateExperience()" [disabled]="!ExpEditForm.valid">
                                                    Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #ExpAddModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Add Experience</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isExperienceShow && isFirstExp">
                                            <form [formGroup]="ExpEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Job Title<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="job_title" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.job_title.dirty && expEditcontrol.job_title.hasError('required')">
                                                            Job title field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Company<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="company" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.company.dirty && expEditcontrol.company.hasError('required')">
                                                            Company field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Location<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="location" />
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.location.dirty && expEditcontrol.location.hasError('required')">
                                                            Location field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month
                                                            </option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.start_month.dirty && expEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.start_year.dirty && expEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.end_month.dirty && expEditcontrol.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>

                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.end_year.dirty && expEditcontrol.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="ExpEditForm.errors !== null && ExpEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group pull-right ">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_current" [value]="1"
                                                                (change)="checkstate($event, 'experience')" id="">
                                                            <label class="form-check-label" for="">Currently working
                                                                here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="expEditcontrol.description.dirty && expEditcontrol.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <input type="hidden" formControlName="id"> -->
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('experience', 'firstTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="saveExperience()" [disabled]="!ExpEditForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <div class="card-body" *ngIf="isExperienceShow && isEditExp">
                                    <hr>
                                    <form [formGroup]="ExpEditForm" enctype="multipart/form-data">
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Job Title<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="job_title" />
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.job_title.dirty && expEditcontrol.job_title.hasError('required')">
                                                    Job title field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>Company<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="company" />
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.company.dirty && expEditcontrol.company.hasError('required')">
                                                    Company field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>Location<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="location" />
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.location.dirty && expEditcontrol.location.hasError('required')">
                                                    Location field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>End Date</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 form-group">
                                                <label>Month<span>*</span></label>
                                                <select class="form-control" formControlName="start_month">
                                                    <option [ngValue]="null" disabled>Select Start Month</option>
                                                    <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.start_month.dirty && expEditcontrol.start_month.hasError('required')">
                                                    Start month field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Year<span>*</span></label>
                                                <select class="form-control" formControlName="start_year">
                                                    <option [ngValue]="null" disabled>Select Start Year</option>
                                                    <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.start_year.dirty && expEditcontrol.start_year.hasError('required')">
                                                    Start year field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Month<span>*</span></label>
                                                <select class="form-control" formControlName="end_month">
                                                    <option [ngValue]="null" disabled>Select End Month</option>
                                                    <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.end_month.dirty && expEditcontrol.end_month.hasError('required')">
                                                    End month field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Year<span>*</span></label>
                                                <select class="form-control" formControlName="end_year">
                                                    <option [ngValue]="null" disabled>Select End Year</option>
                                                    <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.end_year.dirty && expEditcontrol.end_year.hasError('required')">
                                                    End year field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 form-group pull-right">
                                                <label class="fancy-checkbox">
                                                    <input type="checkbox" formControlName="is_current" [value]="1">
                                                    <span><i></i>Currently working here</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Description<span>*</span></label>
                                                <angular-editor [placeholder]="'Enter text here...'"
                                                    [config]="editorConfig" formControlName="description">
                                                </angular-editor>
                                                <span class="text-danger"
                                                    *ngIf="expEditcontrol.description.dirty && expEditcontrol.description.hasError('required')">
                                                    Description field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <input type="hidden" formControlName="id">
                                        <button class="btn btn-danger cansl-vtbt" type="button"
                                            (click)="cancelForm('experience', 'editTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                            (click)="updateExperience()" [disabled]="!ExpEditForm.valid">
                                            Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!--./end Profile-->

                <div class="tab-pane" id="Education" [ngClass]="isEducationShow ? 'show active' : ''">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card card-experiences-vt"> <!-- *ngIf="educations.length>0" -->
                                <h5>Education</h5>
                                <article class="pls-btn" (click)="addQuantity()"><i class="fa fa-plus"></i></article>
                                <article class="pls-btn" (click)="addEducationModal(EducationAddModal)"><i
                                        class="fa fa-plus"></i></article>
                                <div class="card-body" *ngFor="let value of educations; let k=index;let last = last">
                                    <span>
                                        <h6>{{value.qualification}} </h6>
                                        <p>{{k+1}}</p>
                                        <div class="m-t-5 pull-right m-r-5 xls-edtimn">
                                            <!-- <button type="button" class="btn btn-primary" (click)="editEducation(value.id, k)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button> -->
                                            <button type="button" class="btn btn-primary"
                                                (click)="ediEducationModal(value.id, k, EducationModal)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger m-l-5"
                                                (click)="deleteEducation(value.id)">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                    </span>

                                    <div class="col-md-11 col-sm-11">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>Qualification/Degree:</b> {{value.qualification}}</li>
                                                        <li><b>Start Date: </b>
                                                            <em *ngIf="value.start_month!=null">{{
                                                                getMonth(value.start_month) }},
                                                                {{value.start_year}}</em>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>Institution/School:</b> {{value.institution}}</li>
                                                        <li *ngIf="value.is_ongoing==0"><b>Completion Date: </b>
                                                            <em *ngIf="value.completion_month!=null">{{
                                                                getMonth(value.completion_month) }},
                                                                {{value.completion_year}}</em>
                                                        </li>
                                                        <li *ngIf="value.is_ongoing==1">
                                                            <b>Ongoing Qualification:</b>
                                                            <em *ngIf="value.is_ongoing==1">Yes</em>
                                                            <em *ngIf="value.is_ongoing==0">No</em>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li>Type of Course: </li>
                                                        <li>End Date: </li>
                                                    </ul>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12 Description">
                                        <label>Description</label>
                                        <p [innerHTML]="value.description"></p>
                                    </div>
                                    <hr *ngIf="!last">
                                </div>

                                <div class="card-body" *ngIf="educations.length==0">
                                    <div class="col-md-12 col-sm-12">
                                        <label>No Education Found</label><br />
                                        <label>You can add education by clicking plus button.</label>
                                    </div>
                                </div>

                                <!-- <div class="card-body" *ngIf="isEducationShow && isFirstTime">
                                    <hr>
                                    <form [formGroup]="EducationForm" enctype="multipart/form-data">
                                        <section formArrayName="quantities">
                                            <div *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
                                                <div class="row" *ngIf="i>0">
                                                    <div class="col-md-12 form-group ">
                                                        <h5>
                                                            Education {{i+1}}
                                                            <button (click)="removeQuantity(i)" class="btn pull-right btn-sm-vr">
                                                                <i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Institution/School<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="institution" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.institution.dirty && quantity.controls.institution.hasError('required')">
                                                            Institution field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Qualification/Degree<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="qualification" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.qualification.dirty && quantity.controls.qualification.hasError('required')">
                                                            Qualification field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input" formControlName="is_ongoing" [value]="1" (change)="checkstateAdd($event, i, 'education')" id="">
                                                            <label class="form-check-label" for="">Ongoing Qualification</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Completion Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_month">
                                                            <option [ngValue]="null">Select Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.completion_month.dirty && quantity.controls.completion_month.hasError('required')">
                                                            Completion month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_year">
                                                            <option [ngValue]="null" disabled>Select Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.completion_year.dirty && quantity.controls.completion_year.hasError('required')">
                                                            Completion year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig"
                                                            formControlName="description"></angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.description.dirty && quantity.controls.description.hasError('required')">
                                                            Description year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </section>
                                        <button class="btn btn-danger cansl-vtbt" type="button" (click)="cancelForm('education', 'firstTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveEducation()" [disabled]="!EducationForm.valid">
                                            Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div> -->

                                <ng-template #EducationAddModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Add Education</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isEducationShow && isFirstTime">
                                            <form [formGroup]="EduEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Institution<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="institution" />
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.institution.dirty && eEditcontrol.institution.hasError('required')">
                                                            Institution field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Qualification<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="qualification" />
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.qualification.dirty && eEditcontrol.qualification.hasError('required')">
                                                            Qualification field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_ongoing" [value]="1"
                                                                (change)="checkstate($event, 'education')" id="">
                                                            <label class="form-check-label" for="">Ongoing
                                                                Qualification</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Completion Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null">Select Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.start_month.dirty && eEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null">Select Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.start_year.dirty && eEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_month">
                                                            <option [ngValue]="null">Select Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.completion_month.dirty && eEditcontrol.completion_month.hasError('required')">
                                                            Completion month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_year">
                                                            <option [ngValue]="null">Select Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.completion_year.dirty && eEditcontrol.completion_year.hasError('required')">
                                                            Completion year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="EduEditForm.errors !== null && EduEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.description.dirty && eEditcontrol.description.hasError('required')">
                                                            Description year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('education', 'firstTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="saveEducation()" [disabled]="!EduEditForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #EducationModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Edit Education</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isEducationShow && isEditTime">
                                            <form [formGroup]="EduEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Institution<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="institution" />
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.institution.dirty && eEditcontrol.institution.hasError('required')">
                                                            Institution field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Qualification<span>*</span></label>
                                                        <!-- <input class="form-control" type="text"
                                                            formControlName="qualification" /> -->
                                                            <textarea class="form-control" rows="4" cols="33"  formControlName="qualification">
                                                                </textarea>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.qualification.dirty && eEditcontrol.qualification.hasError('required')">
                                                            Qualification field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <!-- <label class="fancy-checkbox">
                                                            <input type="checkbox" formControlName="is_ongoing">
                                                            <span><i></i>Ongoing Qualification</span>
                                                        </label> -->
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_ongoing" [value]="1"
                                                                (change)="checkstate($event, 'education')" id="">
                                                            <label class="form-check-label" for="">Ongoing
                                                                Qualification</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Completion Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null">Select Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.start_month.dirty && eEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null">Select Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.start_year.dirty && eEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_month">
                                                            <option [ngValue]="null">Select Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.completion_month.dirty && eEditcontrol.completion_month.hasError('required')">
                                                            Completion month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="completion_year">
                                                            <option [ngValue]="null">Select Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.completion_year.dirty && eEditcontrol.completion_year.hasError('required')">
                                                            Completion year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="EduEditForm.errors !== null && EduEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>Description<span>*</span></label>
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="eEditcontrol.description.dirty && eEditcontrol.description.hasError('required')">
                                                            Description year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <input type="hidden" formControlName="id">
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('education', 'editTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="updateEducation()" [disabled]="!EduEditForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <div class="card-body" *ngIf="isEducationShow && isEditTime">
                                    <hr>
                                    <form [formGroup]="EduEditForm" enctype="multipart/form-data">
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Institution<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="institution" />
                                                <span class="text-danger"
                                                    *ngIf="eEditcontrol.institution.dirty && eEditcontrol.institution.hasError('required')">
                                                    Institution field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Qualification<span>*</span></label>
                                                <input class="form-control" type="text"
                                                    formControlName="qualification" />
                                                <span class="text-danger"
                                                    *ngIf="eEditcontrol.qualification.dirty && eEditcontrol.qualification.hasError('required')">
                                                    Qualification field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!-- <label class="fancy-checkbox">
                                                    <input type="checkbox" formControlName="is_ongoing">
                                                    <span><i></i>Ongoing Qualification</span>
                                                </label> -->
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        formControlName="is_ongoing" [value]="1" id="">
                                                    <label class="form-check-label" for="">Ongoing Qualification</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Completion Date</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>Month<span>*</span></label>
                                                <select class="form-control" formControlName="completion_month">
                                                    <option [ngValue]="null" disabled>Select Month</option>
                                                    <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="eEditcontrol.completion_month.dirty && eEditcontrol.completion_month.hasError('required')">
                                                    Completion month field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>Year<span>*</span></label>
                                                <select class="form-control" formControlName="completion_year">
                                                    <option [ngValue]="null" disabled>Select Year</option>
                                                    <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="eEditcontrol.completion_year.dirty && eEditcontrol.completion_year.hasError('required')">
                                                    Completion year field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Description<span>*</span></label>
                                                <angular-editor [placeholder]="'Enter text here...'"
                                                    [config]="editorConfig" formControlName="description">
                                                </angular-editor>
                                                <span class="text-danger"
                                                    *ngIf="eEditcontrol.description.dirty && eEditcontrol.description.hasError('required')">
                                                    Description year field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <input type="hidden" formControlName="id">
                                        <button class="btn btn-danger cansl-vtbt" type="button"
                                            (click)="cancelForm('education', 'editTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                            (click)="updateEducation()" [disabled]="!EduEditForm.valid">
                                            Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--./end Education-->
                <div class="tab-pane" id="Certificate" [ngClass]="isCertificateShow ? 'show active' : ''">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card card-experiences-vt"> <!-- *ngIf="certificates.length>0" -->
                                <h5>Certification</h5>
                                <!-- <article class="pls-btn" (click)="addCertificate()"><i class="fa fa-plus"></i></article> -->
                                <article class="pls-btn" (click)="addCertificateModal(CertificateAddModal)"><i
                                        class="fa fa-plus"></i></article>
                                <div class="card-body" *ngFor="let value of certificates; let k=index;let last = last">
                                    <span>
                                        <h6>{{value.name}} </h6>
                                        <p>{{k+1}}</p>
                                        <div class="m-t-5 pull-right m-r-5 xls-edtimn">
                                            <!-- <button type="button" class="btn btn-primary" (click)="editCertificate(value.id, k)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button> -->
                                            <button type="button" class="btn btn-primary"
                                                (click)="editCertificateModal(value.id, k, CertificateModal)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button>

                                            <button type="button" class="btn btn-danger m-l-5"
                                                (click)="deleteCertificate(value.id)">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                    </span>
                                    <div class="col-md-11 col-sm-11">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>Authority:</b> {{value.authority}}</li>
                                                        <li><b>Start Date:</b>
                                                            <em *ngIf="value.start_month!=null">{{
                                                                getMonth(value.start_month) }},
                                                                {{value.start_year}}</em>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="list-group">
                                                    <ul>
                                                        <li><b>License Number:</b> {{value.licence_number}}</li>
                                                        <li>
                                                            <span *ngIf="value.is_ongoing==1">
                                                                <b>Currently Studing:</b> <em
                                                                    *ngIf="value.is_ongoing==1"> Yes</em>
                                                            </span>
                                                            <span *ngIf="value.is_ongoing==0">
                                                                <b>End Date:</b> <em *ngIf="value.end_month!=null">{{
                                                                    getMonth(value.end_month) }},
                                                                    {{value.end_year}}</em>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12">
                                        <label>URL</label>
                                        <p><a href="{{value.url}}" target="_blank">{{value.url}}</a></p>
                                    </div>

                                    <hr *ngIf="!last">
                                </div>
                                <div class="card-body" *ngIf="certificates.length==0">
                                    <div class="col-md-12 col-sm-12">
                                        <label>No Certificate Found</label><br />
                                        <label>You can add certificate by clicking plus button.</label>
                                    </div>
                                </div>
                                <!-- <div class="card-body" *ngIf="isCertificateShow && isFirstCert">
                                    <hr>
                                    <form [formGroup]="CertificateForm" enctype="multipart/form-data">
                                        <section formArrayName="certificates_field">
                                            <div *ngFor="let quantity of certificates_field().controls; let i=index" [formGroupName]="i">
                                                <div class="row" *ngIf="i>0">
                                                    <div class="col-md-12 form-group ">
                                                        <h5>
                                                            Certificate {{i+1}}
                                                            <button (click)="removeCertificate(i)" class="btn pull-right btn-sm-vr">
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </button>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Name<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="name" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.name.dirty && quantity.controls.name.hasError('required')">
                                                            Name field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Authority<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="authority" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.authority.dirty && quantity.controls.authority.hasError('required')">
                                                            Authority field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>License Number<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="licence_number" />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.licence_number.dirty && quantity.controls.licence_number.hasError('required')">
                                                            Licence number field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.start_month.dirty && quantity.controls.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.start_year.dirty && quantity.controls.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.end_month.dirty && quantity.controls.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.end_year.dirty && quantity.controls.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input" formControlName="is_ongoing" [value]="1" (change)="checkstateAdd($event, i, 'certificate')" id="">
                                                            <label class="form-check-label" for="">I Currently study here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>URL<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="url" placeholder="http://www....." />
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.url.dirty && quantity.controls.url.hasError('required')">
                                                            Url field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.url.dirty && quantity.controls.url.hasError('pattern')">
                                                            Enter proper url field.
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </section>
                                        <button class="btn btn-danger cansl-vtbt" type="button" (click)="cancelForm('certificate', 'firstTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveCertificate()"
                                            [disabled]="!CertificateForm.valid">
                                            save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div> -->

                                <ng-template #CertificateAddModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Add Certificate</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isCertificateShow && isFirstCert">
                                            <form [formGroup]="CertEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Name<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="name" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.name.dirty && cerEditcontrol.name.hasError('required')">
                                                            Name field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.name.dirty && cerEditcontrol.name.hasError('maxlength')">
                                                            Allowed maximum 40 characters.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Authority<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="authority" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.authority.dirty && cerEditcontrol.authority.hasError('required')">
                                                            Authority field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.authority.dirty && cerEditcontrol.authority.hasError('maxlength')">
                                                            Allowed maximum 40 characters.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>License Number<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="licence_number" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.licence_number.dirty && cerEditcontrol.licence_number.hasError('required')">
                                                            Licence number field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.licence_number.dirty && cerEditcontrol.licence_number.hasError('maxlength')">
                                                            Allowed maximum 20 characters.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month
                                                            </option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.start_month.dirty && cerEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.start_year.dirty && cerEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.end_month.dirty && cerEditcontrol.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.end_year.dirty && cerEditcontrol.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="CertEditForm.errors !== null && CertEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_ongoing" [value]="1"
                                                                (change)="checkstate($event, 'certificate')" id="">
                                                            <label class="form-check-label" for="">I Currently study
                                                                here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>URL<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="url"
                                                            placeholder="http://www....." />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('required')">
                                                            Url field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('pattern')">
                                                            Enter proper url field.
                                                        </span>
                                                    </div>
                                                </div>
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('certificate', 'firstTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="saveCertificate()" [disabled]="!CertEditForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #CertificateModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Edit Certificate</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isCertificateShow && isEditCert">
                                            <form [formGroup]="CertEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Name<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="name" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.name.dirty && cerEditcontrol.name.hasError('required')">
                                                            Name field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.name.dirty && cerEditcontrol.name.hasError('maxlength')">
                                                            Allowed maximum 40 characters.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>Authority<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="authority" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.authority.dirty && cerEditcontrol.authority.hasError('required')">
                                                            Authority field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.authority.dirty && cerEditcontrol.authority.hasError('maxlength')">
                                                            Allowed maximum 40 characters.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>License Number<span>*</span></label>
                                                        <input class="form-control" type="text"
                                                            formControlName="licence_number" />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.licence_number.dirty && cerEditcontrol.licence_number.hasError('required')">
                                                            Licence number field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.licence_number.dirty && cerEditcontrol.licence_number.hasError('maxlength')">
                                                            Allowed maximum 20 characters.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label>Start Date</label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label>End Date</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="start_month">
                                                            <option [ngValue]="null" disabled>Select Start Month
                                                            </option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.start_month.dirty && cerEditcontrol.start_month.hasError('required')">
                                                            Start month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="start_year">
                                                            <option [ngValue]="null" disabled>Select Start Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.start_year.dirty && cerEditcontrol.start_year.hasError('required')">
                                                            Start year field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Month<span>*</span></label>
                                                        <select class="form-control" formControlName="end_month">
                                                            <option [ngValue]="null">Select End Month</option>
                                                            <option *ngFor="let month of monthList" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.end_month.dirty && cerEditcontrol.end_month.hasError('required')">
                                                            End month field Required.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label>Year<span>*</span></label>
                                                        <select class="form-control" formControlName="end_year">
                                                            <option [ngValue]="null">Select End Year</option>
                                                            <option *ngFor="let year of yearList" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.end_year.dirty && cerEditcontrol.end_year.hasError('required')">
                                                            End year field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <span class="text-danger ng-star-inserted"
                                                            *ngIf="CertEditForm.errors !== null && CertEditForm.errors.DateCheker">End
                                                            date should be greater than start date</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <!-- <label class="fancy-checkbox">
                                                            <input type="checkbox" formControlName="is_ongoing" value="1">
                                                            <span><i></i>I Currently study here</span>
                                                        </label> -->
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                formControlName="is_ongoing" [value]="1"
                                                                (change)="checkstate($event, 'certificate')" id="">
                                                            <label class="form-check-label" for="">I Currently study
                                                                here</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label>URL<span>*</span></label>
                                                        <input class="form-control" type="text" formControlName="url"
                                                            placeholder="http://www....." />
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('required')">
                                                            Url field Required.
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('pattern')">
                                                            Enter proper url field.
                                                        </span>
                                                    </div>
                                                </div>
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('certificate', 'editTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="updateCertificate()" [disabled]="!CertEditForm.valid">
                                                    Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <div class="card-body" *ngIf="isCertificateShow && isEditCert">
                                    <hr>
                                    <form [formGroup]="CertEditForm" enctype="multipart/form-data">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>Name<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="name" />
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.name.dirty && cerEditcontrol.name.hasError('required')">
                                                    Name field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>Authority<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="authority" />
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.authority.dirty && cerEditcontrol.authority.hasError('required')">
                                                    Authority field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>License Number<span>*</span></label>
                                                <input class="form-control" type="text"
                                                    formControlName="licence_number" />
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.licence_number.dirty && cerEditcontrol.licence_number.hasError('required')">
                                                    Licence number field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>End Date</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 form-group">
                                                <label>Month<span>*</span></label>
                                                <select class="form-control" formControlName="start_month">
                                                    <option [ngValue]="null" disabled>Select Start Month</option>
                                                    <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.start_month.dirty && cerEditcontrol.start_month.hasError('required')">
                                                    Start month field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Year<span>*</span></label>
                                                <select class="form-control" formControlName="start_year">
                                                    <option [ngValue]="null" disabled>Select Start Year</option>
                                                    <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.start_year.dirty && cerEditcontrol.start_year.hasError('required')">
                                                    Start year field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Month<span>*</span></label>
                                                <select class="form-control" formControlName="end_month">
                                                    <option [ngValue]="null" disabled>Select End Month</option>
                                                    <option *ngFor="let month of monthList" [ngValue]="month">{{month}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.end_month.dirty && cerEditcontrol.end_month.hasError('required')">
                                                    End month field Required.
                                                </span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Year<span>*</span></label>
                                                <select class="form-control" formControlName="end_year">
                                                    <option [ngValue]="null" disabled>Select End Year</option>
                                                    <option *ngFor="let year of yearList" [ngValue]="year">{{year}}
                                                    </option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.end_year.dirty && cerEditcontrol.end_year.hasError('required')">
                                                    End year field Required.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!-- <label class="fancy-checkbox">
                                                    <input type="checkbox" formControlName="is_ongoing" value="1">
                                                    <span><i></i>I Currently study here</span>
                                                </label> -->
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        formControlName="is_ongoing" [value]="1" id="">
                                                    <label class="form-check-label" for="">I Currently study
                                                        here</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>URL<span>*</span></label>
                                                <input class="form-control" type="text" formControlName="url"
                                                    placeholder="http://www....." />
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('required')">
                                                    Url field Required.
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="cerEditcontrol.url.dirty && cerEditcontrol.url.hasError('pattern')">
                                                    Enter proper url field.
                                                </span>
                                            </div>
                                        </div>
                                        <button class="btn btn-danger cansl-vtbt" type="button"
                                            (click)="cancelForm('certificate', 'editTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                            (click)="updateCertificate()" [disabled]="!CertEditForm.valid">
                                            Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--./end Certificate-->

                <div class="tab-pane" id="Achievement" [ngClass]="isAchievmentShow ? 'show active' : ''">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card card-summary-vt"> <!-- *ngIf="achievements.length>0" -->
                                <h5>Qualifications</h5>
                                <article class="pls-btn" (click)="addAchievement()"><i class="fa fa-plus"></i></article>
                                <article class="pls-btn" (click)="addAchievementModal(AchievenementAddModal)"><i
                                        class="fa fa-plus"></i></article>
                                <div class="card-body" *ngFor="let value of achievments; let k=index;let last = last">
                                    <span>
                                        <!-- <h6>Qualification </h6> -->
                                        <!-- <p>{{k+1}}</p> -->
                                        <div class="m-t-5 pull-right m-r-5 xls-edtimn">
                                            <!-- <button type="button" class="btn btn-primary" (click)="editAchievement(value.id, k)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button> -->
                                            <button type="button" class="btn btn-primary"
                                                (click)="editAchievementModal(value.id, k, AchievenementModal)">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </button>

                                            <button type="button" class="btn btn-danger m-l-5"
                                                (click)="deleteAchievement(value.id, 'Achievement')">
                                                <i class="fa fa-close"></i>
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                    </span>
                                    <div class="col-md-11 col-sm-11 Description">
                                        <!-- <label>Description</label> -->
                                        <p style="padding:0px;" [innerHTML]="value.description"></p>
                                    </div>
                                    
                                    <hr *ngIf="!last">
                                </div>

                                <div class="card-body" *ngIf="achievments.length==0">
                                    <div class="col-md-12 col-sm-12">
                                        <label>No Qualification Found</label><br />
                                        <!-- <label>You can add achievment by clicking plus button.</label> -->
                                    </div>
                                </div>

                                <!-- <div class="card-body" *ngIf="isAchievmentShow && isFirstAchiv">
                                    <hr>
                                    <form [formGroup]="AchievementForm" enctype="multipart/form-data">
                                        <section formArrayName="achievement_field">
                                            <div *ngFor="let quantity of achievement_field().controls; let i=index" [formGroupName]="i">
                                                <div class="row" *ngIf="i>0">
                                                    <div class="col-md-12 form-group ">
                                                        <h5>
                                                            Achievement {{i+1}}
                                                            <button (click)="removeAchievement(i)" class="btn pull-right btn-sm-vr">
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </button>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group txtare_clr" *ngFor="let item of items">
                                                        <label>Description {{item}}<em>*</em></label>
                                                        <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig"
                                                            formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="quantity.controls.description.dirty && quantity.controls.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </section>
                                        <button class="btn btn-danger m-l-5 cansl-vtbt" type="button" data-toggle="collapse" (click)="cancelForm('achievement', 'firstTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="saveAchievement()" [disabled]="!AchievementForm.valid">
                                            save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div> -->

                                <ng-template #AchievenementAddModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Add Qualifications</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isAchievmentShow && isFirstAchiv">
                                            <form [formGroup]="AchivEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="achivEditcontrol.description.dirty && achivEditcontrol.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                </div>
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('achievement', 'firstTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="saveAchievement()" [disabled]="!AchivEditForm.valid">
                                                    Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #AchievenementModal let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Edit Qualifications</h4>
                                    </div>
                                    <div class="modal-body save-box">
                                        <div *ngIf="isAchievmentShow && isEditAchiv">
                                            <form [formGroup]="AchivEditForm" enctype="multipart/form-data">
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <angular-editor [placeholder]="'Enter text here...'"
                                                            [config]="editorConfig" formControlName="description">
                                                        </angular-editor>
                                                        <span class="text-danger"
                                                            *ngIf="achivEditcontrol.description.dirty && achivEditcontrol.description.hasError('required')">
                                                            Description field Required.
                                                        </span>
                                                    </div>
                                                    <input type="hidden" formControlName="achievement_id">
                                                </div>
                                                <button class="btn btn-danger cansl-vtbt" type="button"
                                                    (click)="cancelForm('achievement', 'editTime')"
                                                    aria-controls="collapseExample">
                                                    Cancel
                                                </button>
                                                <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                                    (click)="updateAchievement()" [disabled]="!AchivEditForm.valid">
                                                    Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>

                                <div class="card-body" *ngIf="isAchievmentShow && isEditAchiv">
                                    <hr>
                                    <form [formGroup]="AchivEditForm" enctype="multipart/form-data">
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <label>Description<span>*</span></label>
                                                <angular-editor [placeholder]="'Enter text here...'"
                                                    [config]="editorConfig" formControlName="description">
                                                </angular-editor>
                                                <span class="text-danger"
                                                    *ngIf="achivEditcontrol.description.dirty && achivEditcontrol.description.hasError('required')">
                                                    Description field Required.
                                                </span>
                                            </div>
                                            <input type="hidden" formControlName="achievement_id">
                                        </div>
                                        <button class="btn btn-danger cansl-vtbt" type="button"
                                            (click)="cancelForm('achievement', 'editTime')"
                                            aria-controls="collapseExample">
                                            Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary m-l-5 sve-vtbt"
                                            (click)="updateAchievement()" [disabled]="!AchivEditForm.valid">
                                            Update <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!--./end Qualification-->
            </div>
        </div>

    </div>
</div>