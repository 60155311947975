import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var $: any;

@Component({
  selector: 'app-tool4',
  templateUrl: './tool4.component.html',
  styleUrls: ['./tool4.component.css'],
  providers: [DatePipe]
})
export class Tool4Component implements OnInit {

  questionForm: FormGroup;
  id:any;
  questionList: any = [];

  userFileName:any;
  isGenerateShow = true;
  isSubmitShow = false;
  isPdfShow = false;
  date:any =new Date();
  toolData:any;
  question:any = [];
  percentage=0;
  css_percentage=50;
  chartOption:any;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });


      this.id = this.route.snapshot.params['id'];
      var val = Math.floor(1000 + Math.random() * 9000);
      this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm');
      this.userFileName = this.authService.user.name+'_'+this.date;

      this.authService.getAllTool().subscribe((res:any)=>{
        if(res.status) {
          this.toolData = res.tools;
          if(this.toolData && this.toolData[1]){
            if(this.toolData[1].user_tools && this.toolData[3].user_tools.length>=5){
              this.toastr.error("You already reached your maximum tool", "Error");
              this.router.navigateByUrl('/tools');
            }
          }
        }
      })

      this.authService.getAllQuestionsByType(3).subscribe((res:any)=>{
        if(res.status) {
          this.question = res.question;
          this.queControl.tools_id.setValue(this.id);
          this.queControl.question_1_qustion.setValue(this.question[0].id);
          this.queControl.question_2_qustion.setValue(this.question[1].id);
          this.queControl.question_3_qustion.setValue(this.question[2].id);
          this.queControl.question_4_qustion.setValue(this.question[3].id);
          this.queControl.question_5_qustion.setValue(this.question[4].id);
          this.queControl.question_6_qustion.setValue(this.question[5].id);
          this.queControl.question_7_qustion.setValue(this.question[6].id);
          this.queControl.question_8_qustion.setValue(this.question[7].id);
          this.queControl.question_9_qustion.setValue(this.question[8].id);
          this.queControl.question_10_qustion.setValue(this.question[9].id);
          this.queControl.question_11_qustion.setValue(this.question[10].id);
          this.queControl.question_12_qustion.setValue(this.question[11].id);
          this.queControl.question_13_qustion.setValue(this.question[12].id);
          this.queControl.question_14_qustion.setValue(this.question[13].id);
          this.queControl.question_15_qustion.setValue(this.question[14].id);
          this.queControl.question_16_qustion.setValue(this.question[15].id);
          this.queControl.question_17_qustion.setValue(this.question[16].id);
          this.queControl.question_18_qustion.setValue(this.question[17].id);
          this.queControl.question_19_qustion.setValue(this.question[18].id);
          this.queControl.question_20_qustion.setValue(this.question[19].id);
        } else {
          this.toastr.error("Question list not found", "Error");
        }
      })

      this.questionForm = this.formBuilder.group({
          analysis_name: ['', Validators.required],
          question_1_qustion: [''],
          question_2_qustion: [''],
          question_3_qustion: [''],
          question_4_qustion: [''],
          question_5_qustion: [''],
          question_6_qustion: [''],
          question_7_qustion: [''],
          question_8_qustion: [''],
          question_9_qustion: [''],
          question_10_qustion: [''],
          question_11_qustion: [''],
          question_12_qustion: [''],
          question_13_qustion: [''],
          question_14_qustion: [''],
          question_15_qustion: [''],
          question_16_qustion: [''],
          question_17_qustion: [''],
          question_18_qustion: [''],
          question_19_qustion: [''],
          question_20_qustion: [''],
          
          question_1_answer: ['', Validators.required],
          question_2_answer: ['', Validators.required],
          question_3_answer: ['', Validators.required],
          question_4_answer: ['', Validators.required],
          question_5_answer: ['', Validators.required],
          question_6_answer: ['', Validators.required],
          question_7_answer: ['', Validators.required],
          question_8_answer: ['', Validators.required],
          question_9_answer: ['', Validators.required],
          question_10_answer: ['', Validators.required],
          question_11_answer: ['', Validators.required],
          question_12_answer: ['', Validators.required],
          question_13_answer: ['', Validators.required],
          question_14_answer: ['', Validators.required],
          question_15_answer: ['', Validators.required],
          question_16_answer: ['', Validators.required],
          question_17_answer: ['', Validators.required],
          question_18_answer: ['', Validators.required],
          question_19_answer: ['', Validators.required],
          question_20_answer: ['', Validators.required],

          tools_id: [],
          total_pr: [],
      })
  }

  get queControl() {
    return this.questionForm.controls;
  }

  generateReport(){
    this.loading = true;
    if(!this.questionForm.valid){
      this.toastr.error("Please select all options", "Error");
      this.loading = false;
      return;
    }else{
      this.loading = false;
      let Total = 
      Number(this.queControl.question_1_answer.value) +
      Number(this.queControl.question_2_answer.value) +
      Number(this.queControl.question_3_answer.value) +
      Number(this.queControl.question_4_answer.value) +
      Number(this.queControl.question_5_answer.value) +
      Number(this.queControl.question_6_answer.value) +
      Number(this.queControl.question_7_answer.value) +
      Number(this.queControl.question_8_answer.value) +
      Number(this.queControl.question_9_answer.value) +
      Number(this.queControl.question_10_answer.value) +
      Number(this.queControl.question_11_answer.value) +
      Number(this.queControl.question_12_answer.value) +
      Number(this.queControl.question_13_answer.value) +
      Number(this.queControl.question_14_answer.value) +
      Number(this.queControl.question_15_answer.value) +
      Number(this.queControl.question_16_answer.value) +
      Number(this.queControl.question_17_answer.value) +
      Number(this.queControl.question_18_answer.value) +
      Number(this.queControl.question_19_answer.value) +
      Number(this.queControl.question_20_answer.value) ;

      this.percentage = (Total*100)/20;
      this.queControl.total_pr.setValue(this.percentage);
      //this.queControl.opportunity_profibility_score.setValue(Total);
      //this.opp_pro_total = Total;
      this.isGenerateShow = false
      this.isSubmitShow=true;

      this.chartOption = {
        series: [{
          type: 'gauge',
          axisLine: {
              lineStyle: {
                  width: 30,
                  color: [
                    [0.2, '#d81e99'],
                    [0.4, '#e050af'],
                    [0.6, '#e569bb'],
                    [0.8, '#e050af'],
                    [1, '#d81e99']
                  ]
              }
          },
          pointer: {
              itemStyle: {
                  color: 'auto'
              }
          },
          axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                  color: '#fff',
                  width: 2
              }
          },
          splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                  color: '#fff',
                  width: 4
              }
          },
          axisLabel: {
              color: 'auto',
              distance: 40,
              fontSize: 20
          },
          detail: {
              valueAnimation: true,
              formatter: '{value}',
              color: 'auto'
          },
          data: [{
              value: this.percentage
          }],
          min:-100,
          max:100
      }]
      };
    }
  }

  submit(){
    if(!this.questionForm.valid){
      this.toastr.error("Please select all options", "Error");
      return;
    }else{
      this.authService.createUserRating4(this.questionForm.value).subscribe((res:any)=>{
        if(res.status) {
          this.toastr.success('User rating added successfully', "Success");
          this.isPdfShow=true;
          this.isSubmitShow=false;
          this.isGenerateShow=false;
          //this.router.navigateByUrl('/tools');
        } else {
          this.toastr.error("User rating add error", "Error");
          //this.JoinAndClose();
        }
        //this.router.navigateByUrl('tools');
      })
    }
  }

  public openPDF():void {
    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName+'.pdf');
      //pdf.save('test.pdf'); // Generated PDF
    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    doc.setTextColor(150);
    doc.text(120, doc.internal.pageSize.height/3, 'VT-Touch');
    return doc;
  }

  countScore(){
    let Total = 
      Number(this.queControl.question_1_answer.value) +
      Number(this.queControl.question_2_answer.value) +
      Number(this.queControl.question_3_answer.value) +
      Number(this.queControl.question_4_answer.value) +
      Number(this.queControl.question_5_answer.value) +
      Number(this.queControl.question_6_answer.value) +
      Number(this.queControl.question_7_answer.value) +
      Number(this.queControl.question_8_answer.value) +
      Number(this.queControl.question_9_answer.value) +
      Number(this.queControl.question_10_answer.value) +
      Number(this.queControl.question_11_answer.value) +
      Number(this.queControl.question_12_answer.value) +
      Number(this.queControl.question_13_answer.value) +
      Number(this.queControl.question_14_answer.value) +
      Number(this.queControl.question_15_answer.value) +
      Number(this.queControl.question_16_answer.value) +
      Number(this.queControl.question_17_answer.value) +
      Number(this.queControl.question_18_answer.value) +
      Number(this.queControl.question_19_answer.value) +
      Number(this.queControl.question_20_answer.value) ;

      this.css_percentage = (Total*100)/20;

  }

}
