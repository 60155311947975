<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">

        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <!-- <h2> Tools
                    </h2> -->
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Tools</li>
                    </ul>
                </div>
            </div>
        </div>


        <div>
            <div style="padding-bottom: 15px;" class="categories">
                <form class="" name="form1" id="form1" action="/action_page.php">
                    Categories: <select name="subject" id="subject">
                    <option value="" selected="selected">All</option>
                    <option value="" selected="selected">Team</option>
                    <option value="" selected="selected">Projects</option>
                  </select>
                </form>
            </div>
            <div>
                <table style="width:100%" id="example" class="table table-striped table-bordered" role="grid">
                    <thead>
                        <tr role="row">
                            <th>Tools Name</th>
                            <th colspan="1">Action</th>
                            <th colspan="1">View Saved Reports</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr role="row" *ngFor="let value of toolData | paginate : {
                                            itemsPerPage: tableSize,
                                            currentPage: page,
                                            totalItems: count
                                        };let i = index" [class.active]="i == currentIndex">
                            <td>
                                {{ value.tool_name }}
                            </td>
                            <td style="display: inline-flex;">
                                <div *ngIf="value.user_tools.length<5">
                                    <div *ngIf="i > 0 && isFreeTrial">
                                        <button type="button" class="btn pbtn FreePlanButton" (click)="OpenPlanPopup(PlanModal)">Create</button>
                                    </div>
                                    <div *ngIf="i > 0 && !isFreeTrial">
                                        <button type="button" class="btn pbtn" (click)="gotomaster(value.id, i+1)">Create</button>
                                    </div>
                                    <div *ngIf="i==0">
                                        <button type="button" class="btn pbtn" (click)="gotomaster(value.id, i+1)">Create</button>
                                    </div>
                                </div>
                                <div *ngIf="value.user_tools.length>=5">
                                    <button type="button" class="btn btn-warning" tooltip="You already reached your maximum tool" placement="bottom" disabled>Create</button>
                                </div>
                            </td>
                            <td>
                                <div *ngIf="value.user_tools.length>0">
                                    <!-- <button type="button" class="btn pbtn">View saved</button> -->
                                    <div class="dropdown">
                                        <button type="button" class="btn pbtn dropdown-toggle" data-toggle="dropdown">
                                    View Reports
                                </button>
                                        <div class="dropdown-menu">
                                            <div *ngFor="let tool of value.user_tools">
                                                <!-- <span class="dropdown-item" *ngIf="i==1">
                                        <a class="report" [routerLink]="['/project-tool-view', tool.id]"  style="display: inline-block;">
                                          {{tool.tool_user_name}}
                                        </a>
                                        <button type="button" class="btn pbtn btn-sm" (click)="DeleteReport(tool.id)"><i class="fa fa-trash"></i></button>
                                      </span>
                                      <span class="dropdown-item" *ngIf="i==0">
                                        <a class="report" href="javascript:" [routerLink]="['/viewtool', tool.id]" style="display: inline-block;">
                                          {{tool.tool_user_name}}
                                        </a>
                                        <button type="button" class="btn pbtn btn-sm" (click)="viewReport(tool.id, i)">{{tool.tool_user_name}}</button>
                                        <button type="button" class="btn pbtn btn-sm" (click)="DeleteReport(tool.id)"><i class="fa fa-trash"></i></button>
                                      </span> -->
                                                <span class="dropdown-item">
                                        <button type="button" class="btn btn-sm " (click)="viewReport(tool.id, i+1)">{{tool.tool_user_name}}</button>
                                        <button type="button" class="btn pbtn btn-sm m-b-2" (click)="DeleteReport(tool.id, i+1)"><i class="fa fa-trash"></i></button>
                                      </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="value.user_tools.length==0">
                                    -
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div class="d-flex justify-content-center">
                  <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                     (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
               </div> -->
            </div>

            <div>

            </div>
        </div>
        <!-- <a [routerLink]="['/tools/tool3']">Introvert Extrovert Calculator</a><br>
        <a [routerLink]="['/tools/tool4']">Force Field Analysis – with new graph</a> -->

        <!-- <div style="padding-bottom: 20px;">
            <a routerLink="/mastertools" class="btn pbtn"><span>Mastertools</span></a>
        </div> -->

        <ng-template #PlanModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                   <span aria-hidden="true">&times;</span>
                 </button>
             </div>
             <div class="modal-body">
                <div class="Iconwarning-box mb-4">
                   <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
                   <div class="Iconwarning-box-content">
                      <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                         enjoying the benefits of all features by VTTouch. 
                      </p>
                   </div>
                </div>
                <div class="row justify-content-center">
                   <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                      <div class="single-price-plan mb-100">
                         <div class="price-plan-title">
                            <h4>{{singlePlan.plan_name}}
                            </h4>
                         </div>
                         <div class="price-plan-value">
                            <h2>£{{singlePlan.price}}</h2>
                            <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                         </div>
                           <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                           <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                      </div>
                   </div>
                   
                   
                </div>
             </div>
        </ng-template>
    </div>
</div>


 <!-- Modal for plan upgrade -->
 <div class="Instructions-Modal modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" *ngIf="roleId != 1">
    <div class="modal-dialog  modal-lg" role="document">
       <div class="modal-content">
          <div class="modal-header">
             <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
             <div class="Iconwarning-box mb-4">
                <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
                <div class="Iconwarning-box-content">
                   <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                      enjoying the benefits of all features by VTTouch. 
                   </p>
                </div>
             </div>
             <div class="row justify-content-center">
                <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                   <div class="single-price-plan mb-100">
                      <div class="price-plan-title">
                         <h4>{{singlePlan.plan_name}}
                         </h4>
                      </div>
                      <div class="price-plan-value">
                         <h2>£{{singlePlan.price}}</h2>
                         <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                      </div>
                        <a href="#" class="Buttons-pink close" *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" data-dismiss="modal" aria-label="Close">Start Trial</a>
                        <a href="#" class="Buttons-pink close" *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" data-dismiss="modal" aria-label="Close">Continue</a>
                   </div>
                </div>
                
                
             </div>
          </div>
       </div>
    </div>
 </div>