import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

  events;
  public loading: boolean;

  constructor(private eventService: EventService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {

    this.eventService.getAllEvent().subscribe((data: any)=>{
      if(data.status){
        this.events = data.events;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  deleteEvent(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.eventService.delete(id).subscribe((data: any)=>{
            this.loading = false;
            if(data.status){
              this.toastr.success("Experience Deleted Successfully", "Success");
              this.events = this.events.filter(item => item.id !== id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

  backClicked() {
    this._location.back();
  }

  replaceURLWithHTMLLinks(text) {
    if(text) {
        let exp = /(\b(https?|ftp|file|www?|http?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
        let pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        return text.replace(exp,"<a href='$1' target='_blank'>$1</a>").replace(pseudoUrlPattern, "$1<a target='_blank' href='http://$2'>$2</a>"); 
    }
  }

}
