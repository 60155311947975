<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a routerLink="/admin/partner-list">Partners List</a></li>
                        <li class="breadcrumb-item"><span>Partners Add</span></li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header text-center">
                        <h2 *ngIf="isAddMode">Add Partner</h2>
                        <h2 *ngIf="!isAddMode">Edit Partner</h2>
                    </div>
                    <div class="body">
                        <form [formGroup]="CreateForm" enctype="multipart/form-data">
                            <div class="row clearfix">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title <span>*</span></label>
                                        <input class="form-control" type="text" formControlName="title" />
                                        <span class="text-danger" *ngIf="control.title.dirty && control.title.hasError('required')">
                                            Title field is requied.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Visit Link <span>*</span></label>
                                        <input class="form-control" type="text" formControlName="visit_link" />
                                        <span class="text-danger" *ngIf="control.visit_link.dirty && control.visit_link.hasError('required')">
                                            Visit Link field is requied.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description <span>*</span></label>
                                        <textarea name="" class="form-control post-style" formControlName="description"
                                            placeholder="Write Your description">
                                        </textarea>
                                        <span class="text-danger" *ngIf="control.description.dirty && control.description.hasError('required')">
                                            Description field is requied.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Icon <span>*</span></label>
                                        <input type="file" formControlName="icon" (change)="onFileChange($event, 'icon')" accept=".jpg, .jpeg, .png">
                                        <span class="text-danger" *ngIf="control.icon.dirty && control.icon.hasError('required')">
                                            File field Required.
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Hover Icon <span>*</span></label>
                                        <input type="file" formControlName="hover_icon" (change)="onFileChange($event, 'hover_icon')" 
                                        accept=".jpg, .jpeg, .png">
                                        <span class="text-danger" *ngIf="control.hover_icon.dirty && control.hover_icon.hasError('required')">
                                            File field Required.
                                        </span>
                                    </div>
                                </div>
                               
                            </div>
                            <button type="submit" class="btn btn-primary"  (click)="submit()" [disabled]="!CreateForm.valid"> <!--  -->
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>


    </div>
</div>