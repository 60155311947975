import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  viewForm: FormGroup;
  public loading: boolean;
  id: number;
  user: any;
  imageURL:string;

  constructor(private formBuilder: FormBuilder, 
    private authService: AuthService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService) { 
    
  }

  ngOnInit(): void {

    
    this.authService.getUserById(this.authService.user.id).subscribe((data: any)=>{
      if(data.status){
        this.user = data.user;
        if(this.user.profile_pic){
          //let path = GlobalConstants.ImagePATH+'/'+'/uploads/users/photo/';
          let path =  GlobalConstants.user_profile_path;
          this.imageURL = path+this.user.profile_pic;
        }
      }else{
        //alert(data.message);
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });

    this.viewForm = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        name: ['', Validators.required],
        id: [''],
        profile_image: []
    });
  }

  get control() {
    return this.viewForm.controls;
  }

}
