import { Component, OnInit } from '@angular/core';
import { PartnerService } from 'src/app/services/partner.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  partnerList: any = [];
  selectedRow:any;
  constructor(
    private partnerService: PartnerService,
    private toastr: ToastrService,
  ) {

    this.partnerService.getAll().subscribe((res: any) => {
      if (res.status) {
        this.partnerList = res.data;

      } else {
        this.partnerList = []
        this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.partnerList = []
        this.toastr.error(err, "Error");
      });

  }


ngOnInit(): void {
}
setSelectedRow(row: any) {
  this.selectedRow = row;
  $('#partnersModal').addClass('show');
}

closeModel(){
  $('#partnersModal').removeClass('show');
}
}
