<app-header></app-header>
<app-sidebar></app-sidebar>


<div id="main-content" >
  <div class="container-fluid" infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollUpDistance]="2"
        [infiniteScrollThrottle]="300"
        [fromRoot]="true"
        (scrolled)="onScroll($event)">

    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-12">
            <h2> Notifications <img src="./assets/assets/images/bell.png"  alt="bell-img"> </h2>
            <!-- <ul class="breadcrumb vt-a">
                <li class="breadcrumb-item"><a><i class="icon-home"></i></a></li>
                <li class="breadcrumb-item active">{{roomDetail.room_name}}</li>
            </ul> -->
        </div>
      </div>
    </div>

    <section *ngFor="let value of notList;let index = index"class="search-results">
      <div class="cht-commet">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="main-chatprt">
                    <div class="hed-chat">
                        <a [routerLink]="['/user-details', value.sender_id]">
                            <img src="{{ value.sender?.profile_pic }}"
                            alt="{{value.sender_name}}"
                            class="rounded-circle user-photo">
                        </a>
                        <div class="Notficationcard">
                            <a [routerLink]="['/user-details', value.sender_id]">
                                <h3>{{ value.sender?.name }} <!--<span>reacted to your post</span>--></h3>
                            </a>
                            <h5 [innerHTML]='value.notification_description'></h5>
                            <p>{{ value.created_at }}</p>
                            <!-- <img src=".assets/assets/images/Notification-img.png" alt="Notification-img" class=" Notification-photo"> -->
                            <!-- <p class="chat-time-glb">
                                <span>{{ value.created_at }}</span>
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12 mt-4">
                <div class="chat-content">
                    <p [innerHTML]="value.notification_description"></p>
                </div>
            </div> -->
        </div>
      </div>
    </section>

    <div class="col-md-12 load-more mt-4 text-center m-b-10" *ngIf="!isPaginationShow">
        No more notification found
    </div>

    

    

    </div><!--./main-content-->
</div><!--./container-fluid-->
