<div class="vertical-align-wrap">
    <div class="vertical-align-middle auth-main">
        <div class="auth-box container">
            <div class="row row align-items-center">
                <div class="col-md-6">
                    <div class="top">
                        <!-- <img src="./assets/assets/images/vttouch.png" alt="Lucid"> -->
                    </div>
                    <div class="card">
                        <div class="top-part text-center">
                            <div class="tp-img">
                                <a href="https://fambizcommunity.com/"><img src="./assets/assets/images/logo.jpg" alt="logo"></a>

                            </div>
                            <!-- <div class="reg-btn">
                                <button type="button" class="btn sgn-up-nw" [routerLink]="['/register']">
                                    Register</button>
                                <button type="button" class="btn pbtn-lite"> Sign In</button>
                            </div> -->
                        </div>
                        <div class="header">
                            <p class="lead">Enter your email and we send you a password reset link</p>
                        </div>
                        <div class="body">
                            <!-- <h2>Registration</h2> -->
                            <form [formGroup]="forgotForm">
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <!-- <label>Email <span>*</span> </label> -->
                                            <input class="form-control" type="input" formControlName="email"
                                                placeholder="Email" />
                                            <span class="text-danger"
                                                *ngIf="control.email.dirty && control.email.hasError('required')">
                                                Email Required.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 mt-4 sgn-lst-btn">
                                        <button type="button" class="btn pbtn m-r-15" (click)="submit()" [disabled]="!forgotForm.valid">Send Request
                                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                        <button type="button" class="btn sgn-up-nw m-r-15" [routerLink]="['/login']">
                                            Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="wlcom-signp">
                        <h2>
                            <span style="color:#fff">Elevate</span> your business	
                        </h2>
                        <p>We unite the family business community to provide a voice, a sense of belonging and an environment for learning and development.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
