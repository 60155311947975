<app-header></app-header>
<app-sidebar></app-sidebar>

<!-- <app-second-header></app-second-header>
<app-second-sidebar></app-second-sidebar> -->

<div id="main-content">
    <div class="container-fluid" >
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12" >
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item active">Deal Room</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="dealrooms?.length>0;">
            <div class="col-md-6 " *ngIf="dealrooms[0]!=null" >
                <div class="first-blog-box">
                    <a [routerLink]="['/group', dealrooms[0].id, dealrooms[0].slug ]"  class="clr0fff">
                    <div class="ct-lyer clearfix img-1 " [ngClass]="{'one':!dealrooms[0].is_added}">
                        <h2>
                            <a [routerLink]="['/group', dealrooms[0].id, dealrooms[0].slug ]" *ngIf="dealrooms[0].is_added" class="clr0fff">{{
                                dealrooms[0].room_name }}</a>
                            <a *ngIf="!dealrooms[0].is_added" class="clr0fff">{{ dealrooms[0].room_name }}</a>
                        </h2>
                        <span> {{dealrooms[0].users_count}} Members <i class="fa fa-circle"></i> {{dealrooms[0].posts_count}} New Messages </span>
                        
                        <div class="slide-content details-window up">
                            <a class="btn pbtn" href="javascript:" (click)="SendJoinRequest(dealrooms[0].id)"
                                *ngIf="!dealrooms[0].is_requested && !dealrooms[0].is_added">Join Request</a>
                            <a class="btn pbtn" href="javascript:" *ngIf="dealrooms[0].is_requested">Already Request</a>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6 ">
                <div class="second-blog-box ">
                    <a [routerLink]="['/following-post' ]" class="text-white">
                        <img src="/assets/assets/images/iscn-star.png"> {{follow_post_count}} New Following Posts
                    </a> 
                </div>
                    <div class="third-blog-box-1 img-1 one" *ngIf="dealrooms[1]!=null" [ngClass]="{'one':!dealrooms[1].is_added && !dealrooms[1].is_requested}">
                        <a [routerLink]="['/group', dealrooms[1].id, dealrooms[1].slug ]" class="clr0fff">
                     
                        <h2>
                            <a *ngIf="dealrooms[1].is_added" class="clr0fff">{{ dealrooms[1].room_name }}</a>
                            <a *ngIf="!dealrooms[1].is_added" class="clr0fff">{{ dealrooms[1].room_name }}</a>
                        </h2>
                        <span> {{dealrooms[1].users_count}} Members <i class="fa fa-circle"></i> {{dealrooms[1].posts_count}} New Messages 
                            <!-- <a class="btn-xs" href="javascript:" (click)="SendJoinRequest(dealrooms[1].id)" *ngIf="!dealrooms[1].is_requested && !dealrooms[1].is_added">Join Request</a> -->
                            
                        </span>
                        <div class="slide-content details-window up">
                            <a class="btn pbtn" href="javascript:" (click)="SendJoinRequest(dealrooms[1].id)" *ngIf="!dealrooms[1].is_requested && !dealrooms[1].is_added">Join Request</a>
                            <a class="btn pbtn" href="javascript:" *ngIf="dealrooms[1].is_requested">Already Request</a>
                        </div>
                        </a>
                    </div>
                    <div class="third-blog-box-2 margin-left img-1 " *ngIf="dealrooms[2]!=null" [ngClass]="{'one':!dealrooms[2].is_added}">
                        <a [routerLink]="['/group', dealrooms[2].id, dealrooms[2].slug ]" class="clr0fff">
                        <h2>
                        <a [routerLink]="['/group', dealrooms[2].id, dealrooms[2].slug ]"
                            *ngIf="dealrooms[2].is_added" class="clr0fff">{{ dealrooms[2].room_name }}</a>
                        <a *ngIf="!dealrooms[2].is_added" class="clr0fff">{{ dealrooms[2].room_name }}</a>
                    </h2>
                        <span> {{dealrooms[2].users_count}} Members <i class="fa fa-circle"></i> {{dealrooms[2].posts_count}} New Messages 
                            <!-- <a class="btn-xs" href="javascript:" (click)="SendJoinRequest(dealrooms[2].id)"
                                *ngIf="!dealrooms[2].is_requested && !dealrooms[2].is_added">Join Request</a> -->
                            
                        </span>
                        <div class="slide-content details-window up">
                            <a class="btn pbtn" href="javascript:" (click)="SendJoinRequest(dealrooms[2].id)"
                                *ngIf="!dealrooms[2].is_requested && !dealrooms[2].is_added">Join Request</a>
                            <a class="btn pbtn" href="javascript:" *ngIf="dealrooms[2].is_requested">Already Request</a>
                        </div>
                        </a>
                    </div>
                
            </div>
        </div>

        <div class="row margin-top"  *ngIf="dealrooms?.length>3;">
          <div class="col-md-3 pr-cht0" *ngIf="dealrooms[3]!=null">
            <div class="msg-bx img-1" [ngClass]="{'one':!dealrooms[3].is_added}">
                <a [routerLink]="['/group', dealrooms[3].id, dealrooms[3].slug ]" class="clr0fff">
                <h2>
                    <a [routerLink]="['/group', dealrooms[3].id, dealrooms[3].slug ]" 
                    *ngIf="dealrooms[3].is_added" class="clr0fff">{{ dealrooms[3].room_name }}</a>
                    <a *ngIf="!dealrooms[3].is_added" class="clr0fff">{{ dealrooms[3].room_name }}</a>
                </h2>
                  <span> {{dealrooms[3].users_count}} Members  <i class="fa fa-circle"></i> {{dealrooms[3].posts_count}} New Messages 
                    <!-- <a class="btn-xs" href="javascript:" (click)="SendJoinRequest(dealrooms[3].id)"
                        *ngIf="!dealrooms[3].is_requested && !dealrooms[3].is_added">Join Request</a> -->
                    
                </span>
                <div class="slide-content details-window up">
                    <a class="btn pbtn" href="javascript:" (click)="SendJoinRequest(dealrooms[3].id)"
                        *ngIf="!dealrooms[3].is_requested && !dealrooms[3].is_added">Join Request</a>
                    <a class="btn pbtn" href="javascript:" *ngIf="dealrooms[3].is_requested">Already Request</a>
                </div>
                </a>
            </div>
          </div>
          <div class="col-md-9">
            <div class="last-service img-1 " *ngIf="dealrooms[4]!=null" [ngClass]="{'one':!dealrooms[4].is_added}">
                <a [routerLink]="['/group', dealrooms[4].id, dealrooms[4].slug ]" class="clr0fff">
                <h2>
                    <span  *ngIf="dealrooms[4].is_added">
                        <a [routerLink]="['/group', dealrooms[4].id, dealrooms[4].slug ]" class="clr0fff">{{ dealrooms[4].room_name }}</a>
                    </span>
                    <span *ngIf="!dealrooms[4].is_added">{{ dealrooms[4].room_name }}</span>
                </h2>
                <div class="mx-service">
                    <span> {{dealrooms[4].users_count}} Members <i class="fa fa-circle"></i> {{dealrooms[4].posts_count}} New Messages 
                        <!-- <a class="btn-xs" href="javascript:" (click)="SendJoinRequest(dealrooms[4].id)"
                        *ngIf="!dealrooms[4].is_added && !dealrooms[4].is_requested">Join Request</a>-->
                        <!-- <a class="btn-xs" href="javascript:" *ngIf="dealrooms[4].is_requested">Already Request</a> --> 

                    </span>
                </div>
                <div class="slide-content details-window up">
                    <!-- <a href="" class="btn pbtn">Join Request</a> -->
                    <a class="btn pbtn" href="javascript:" (click)="SendJoinRequest(dealrooms[4].id)"
                        *ngIf="!dealrooms[4].is_added && !dealrooms[4].is_requested">Join Request</a>
                    <a class="btn pbtn" href="javascript:" *ngIf="dealrooms[4].is_requested">Already Request</a>
                </div>
                </a>
            </div>
          </div>
        </div>

        <div class="row margin-top same-box" *ngIf="dealrooms?.length>=5;">
            <div class="col-md-3" *ngFor="let value of dealrooms | slice:5">
                <div class="msg-bx img-1 " [ngClass]="{'one':!value.is_added}">
                    <a [routerLink]="['/group', value.id, value.slug ]" class="clr0fff">
                    <h2 *ngIf="value.is_added; else Simple">
                        <a [routerLink]="['/group', value.id, value.slug ]" class="clr0fff">{{ value.room_name }}</a>
                    </h2>
                    <ng-template #Simple>
                        <h2>{{ value.room_name }}</h2>
                    </ng-template>
                    <span> {{value.users_count}} Members <i class="fa fa-circle"></i> {{value.posts_count}} New Messages 
                        <!-- <a class="btn-xs" href="javascript:" (click)="SendJoinRequest(value.id)"
                            *ngIf="!value.is_requested && !value.is_added">Joinsss Request</a>-->
                         
                    </span>
                    <div class="slide-content details-window up">
                        <a href="javascript:" (click)="SendJoinRequest(value.id)"
                            *ngIf="!value.is_requested && !value.is_added" class="btn pbtn">Join Request</a>
                        <a class="btn pbtn" href="javascript:" *ngIf="value.is_requested">Already Request</a>                           
                    </div>
                    </a>
                </div>
            </div>
        </div>

        <!-- <div class="row" *ngIf="dealrooms?.length==0;">
            <div class="col-md-12">
                No Data found
            </div>
        </div> -->

        <!-- <div class="row margin-top">
            <div class="col-md-6">
                <a [routerLink]="['/my-favourite']" class="btn btn-primary">My Favourite</a>
            </div>
        </div> -->

    </div>
</div>
