import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningService } from 'src/app/services/learning.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  learningList:any = [];
  slug:any;
  category_name:any ='';
  cate_slug:any;

  constructor(
    private learnService: LearningService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService, 
    ) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params['slug'];
    this.cate_slug = this.slug;

    this.route.params.forEach(params => {
          let slug = params["slug"];
          this.cate_slug = slug;
          this.learnService.getAllByCategorySlug(slug).subscribe((res: any)=>{
            if(res.status){
              this.learningList = res.data;
              if(this.learningList.length>0){
                this.category_name = this.learningList[0].category_name;
              }
            }else{
              this.learningList = []
              this.toastr.error(res.message, "Error");
            }
          },
           err=> {
            this.learningList = []
            this.toastr.error(err, "Error");
          });
          //call your function, like getUserInfo()
    })
    
  }

}
