import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VideoAndBlogService } from 'src/app/services/videoandblog.service';

@Component({
  selector: 'app-video-blog',
  templateUrl: './video-blog.component.html',
  styleUrls: ['./video-blog.component.css']
})
export class VideoBlogComponent implements OnInit {
  searchText: string;
  latestBlog:any;
  allVideoAndBlog:any;
  allPost:any;
  videoAndBlogList: any = [];
  pageLength = 3;
  totalPage:any;
  activepage=0;
 blogurl:boolean=true;
 displayType = 'blog'; 
 timeout = 1000;
  timer:any;
  activePage: number = 0; 

  
  constructor(
    private videoAndBlogService: VideoAndBlogService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    if(router.url.replace('/','') == 'videos'){
      this.displayType = 'video'; 
    }
    this.videoAndBlogService.getAll().subscribe((res: any) => {
      if (res.status) {
        if(res.data.length>0){
          res.data = res.data.filter((o:any)=>o.type.toLowerCase() == this.displayType);
          this.latestBlog = res.data[0];
          this.allVideoAndBlog = res.data;
          this.allPost = this.allVideoAndBlog.slice(1);
          this.videoAndBlogList = this.allPost.slice(0,this.pageLength);
          let Page = Math.ceil(this.allPost.length / this.pageLength);
          this.totalPage= Array(Page).fill(0);
          
        }
        
      } else {
        this.videoAndBlogList = []
        this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.videoAndBlogList = []
        this.toastr.error(err, "Error");
      });

  }

  ngOnInit(): void {
  if(this.router.url==='/videos'){
    this.blogurl=false;
  }
  }

  getAllPostBySearch() {
    if (this.searchText == null || this.searchText == '') {
    } else {
     
    }

  }
  convertISOFormatDate(isoString: any) {
    if (isoString.slice(-1).toLowerCase() != 'z') {
      isoString = isoString+'Z';
    }
    let date = new Date(isoString);
    let day = date.getUTCDate().toString().padStart(2, '0');
    let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    let year = date.getUTCFullYear();
    let formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  sanitizUrl(url:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  description(text:any,length=30){
    const words = text.split(' ');
    if (words.length > length) {
      return words.slice(0, length).join(' ') + '...';
    } else {
      return text;
    }
  }

  readMore(id:any,type:any){
    if(type == 'Video'){
      this.router.navigate(['/videos/video-details/' + id]);
    }else{
      this.router.navigate(['/blogs/blog-details/' + id]);
    }
  }

  // changePage(page:number){
  //   this.videoAndBlogList = this.allVideoAndBlog.slice(page*this.pageLength,(page+1)*this.pageLength);
  //   this.activepage = page;
  //   console.log('page', page)
  //   this.cdr.detectChanges();
  // }

  changePage(page: number) {
    if (page >= 0 && page < this.totalPage.length) {
      this.videoAndBlogList = this.allVideoAndBlog.slice(page * this.pageLength, (page + 1) * this.pageLength);
      this.activePage = page;
      this.cdr.detectChanges();
  
    } 
  }

  filter_post(text:any){
    const that = this;
    clearTimeout(this.timer);
    this.timer = setTimeout(function() {
      let data = that.allVideoAndBlog.filter((o:any)=>o.title.toLowerCase().includes(text));
      if(data.length>0){
        that.latestBlog = data[0];
        that.allPost = data.slice(1);
        that.videoAndBlogList = that.allPost.slice(0,that.pageLength);
        let Page = Math.ceil(that.allPost.length / that.pageLength) || 1;
        console.log(Page);
        that.totalPage= Array(Page).fill(0);
        console.log(that.totalPage);
        that.activepage = 0;
        that.cdr.detectChanges();
      }else{
        that.toastr.error('No results found.', "Error");
      }
    }, this.timeout);
  }

  
  getDisplayedPages() {
    const pagesToShow = [];
    const totalPages = Math.ceil(this.allPost.length / this.pageLength);
      console.log('totalPages = ', totalPages )
    if (totalPages <= 5) {
      // Show all pages if total pages are less than or equal to 5
      return this.totalPage;
    }

    if (this.activePage > 1) {
      pagesToShow.push('...');
    }

    if (this.activePage > 0) {
      pagesToShow.push(this.activePage - 1);
    }

    pagesToShow.push(this.activePage);

    if (this.activePage < totalPages - 1) {
      pagesToShow.push(this.activePage + 1);
    }

    if (this.activePage < totalPages - 2) {
      pagesToShow.push('...');
    }

    return pagesToShow;
  }
}

