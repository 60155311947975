import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DealroomService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  httpOptionsImage = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      //'Accept': 'application/json'
    })
  }

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  save(req){
    return this.http.post(`${this.url}/dealroom/store`, req, this.httpOptions);
  }

  getAll(){
    return this.http.get(`${this.url}/dealroom/get_all`, this.httpOptions);
  }

  delete(id){
    return this.http.get(`${this.url}/dealroom/delete/`+id, this.httpOptions);
  }

  getSingle(id){
    return this.http.get(`${this.url}/dealroom/get_single/`+id, this.httpOptions);
  }

  update(req){
    return this.http.post(`${this.url}/dealroom/update`, req, this.httpOptions);
  }

  addUser(req){
    return this.http.post(`${this.url}/dealroom/add_users`, req, this.httpOptions);
  }

  getUsers(id){
    return this.http.get(`${this.url}/dealroom/get_all_users/`+id, this.httpOptions);
  }

  sendRequest(req){
    return this.http.post(`${this.url}/dealroom/send_request`, req, this.httpOptions);
  }

  getAllRequest(id){
    return this.http.get(`${this.url}/dealroom/get_all_users_request/`+id, this.httpOptions);
  }

  addSingleUser(req){
    return this.http.post(`${this.url}/dealroom/add_single_user`, req, this.httpOptions);
  }

  addPost(req){
    return this.http.post(`${this.url}/dealroom/AddPost`, req);
  }

  getPostByRoomId(id, page){
    return this.http.get(`${this.url}/dealroom/get_post_by_room_id/`+id+'/'+page, this.httpOptions);
  }
  getAllPostByRoomId(id){
    return this.http.get(`${this.url}/dealroom/get_all_post_by_room_id/`+id, this.httpOptions);
  }

  addPostComment(req){
    return this.http.post(`${this.url}/dealroom/add_comment`, req);
  }

  addPostLike(req){
    return this.http.post(`${this.url}/dealroom/add_like`, req, this.httpOptions);
  }

  UpdatePostComment(req){
    return this.http.post(`${this.url}/dealroom/update_comment`, req);
  }

  DeleteComment(id){
    return this.http.post(`${this.url}/dealroom/delete_comment/`+id, this.httpOptions);
  }

  DeletePost(id){
    return this.http.post(`${this.url}/dealroom/delete_post/`+id, this.httpOptions);
  }

  UpdatePost(req){
    return this.http.post(`${this.url}/dealroom/update_post`, req);
  }
  
  addPostFollow(req){
    return this.http.post(`${this.url}/dealroom/add_follow`, req, this.httpOptions);
  }

  getCommentByPost(id, page){
    return this.http.get(`${this.url}/dealroom/get_comment_by_post_id/`+id+'/'+page, this.httpOptions);
  }
  
  getLatest3Post(){
    return this.http.get(`${this.url}/dealroom/get_latest_3_post_by_user`, this.httpOptions);
  }

  getLatest3Comment(){
    return this.http.get(`${this.url}/dealroom/get_latest_3_comment_of_user_post`, this.httpOptions);
  }

  getPostByPostId(id){
    return this.http.get(`${this.url}/dealroom/get_post_by_post_id/`+id, this.httpOptions);
  }

  getFollowingPostOfUser(page){
    return this.http.get(`${this.url}/dealroom/get_following_post_of_user/`+page, this.httpOptions);
  }

  

}
