<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Network</li>
                        <li class="breadcrumb-item">Events</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="row clearfix">
         <div class="col-md-12">
             <h3 class="text-center">Upcoming Events</h3>
             <div class="body">
                 <table class="table table-striped">
                     <thead>
                         <tr>
                             <th scope="col">#</th>
                             <th scope="col">Title</th>
                             <th scope="col">Image/Video</th>
                             <th scope="col">Event Date</th>
                             <th scope="col">Action</th>
                         </tr>
                     </thead>
                     <tbody *ngIf="upComing?.length; else no_data_templ">
                         <tr *ngFor="let value of upComing; index as i">
                             <th scope="row">{{ i + 1 }}</th>
                             <td>
                                 {{ value.title }}
                             </td>
                             <td>
                                 <span *ngIf="value.image_video && value.file_name">
                                     <span *ngIf="value.image_video==1">
                                         <img src="{{value.file_name}}" width="50" height="50">
                                     </span>
                                     <span *ngIf="value.image_video==2">
                                         <video width="50" height="50" controls>
                                             <source src="{{value.file_name}}" type="video/mp4">
                                             Your browser does not support the video tag.
                                           </video> 
                                     </span>
                                 </span>
                                 <span *ngIf="!value.image_video && !value.file_name">-</span>
                             </td>
                             <td>
                                 {{ value.event_date |date:'dd-MM-yyyy' }}
                             </td>
                             <td>
                                 <span *ngIf="value.event_close" class="btn pbtn-lite">Closed</span>
                                 <span *ngIf="!value.event_close">
                                     <span *ngIf="value.users?.length == 1" >
                                         <button class="btn pbtn-lite" type="button" (click)="cancelRegistraion(value.id)">
                                             Cancel Registration
                                         </button>
                                     </span>
                                     <span *ngIf="value.users?.length == 0">
                                         <button class="btn pbtn" type="button" (click)="register(value.id)">
                                             Register
                                         </button>
                                     </span>
                                 </span>
                             </td>
                         </tr>
                         <tr *ngIf="upComing.length==0">
                             <td colspan="3">No Data Found</td>
                         </tr>
                     </tbody>
                     <ng-template #no_data_templ>
                         <tr>
                             <td colspan="3" class="text-center">No Data Found</td>
                         </tr>
                     </ng-template>
                 </table>
             </div>
         </div>
         </div>
         
         <div class="row clearfix">
         <div class="col-md-12">
             <h3 class="text-center">Registered Events</h3>
             <div class="body">
                 <table class="table table-striped">
                     <thead>
                         <tr>
                             <th scope="col">#</th>
                             <th scope="col">Title</th>
                             <th scope="col">Image/Video</th>
                             <th scope="col">Event Date</th>
                             <th scope="col">Action</th>
                         </tr>
                     </thead>
                     <tbody *ngIf="registered?.length; else no_data_templ">
                         <tr *ngFor="let value of registered; index as i">
                             <th scope="row">{{ i + 1 }}</th>
                             <td>
                                 {{ value.title }}
                             </td>
                             <td>
                                 <span *ngIf="value.image_video && value.file_name">
                                     <span *ngIf="value.image_video==1">
                                         <img src="{{value.file_name}}" width="50" height="50">
                                     </span>
                                     <span *ngIf="value.image_video==2">
                                         <video width="50" height="50" controls>
                                             <source src="{{value.file_name}}" type="video/mp4">
                                             Your browser does not support the video tag.
                                           </video> 
                                     </span>
                                 </span>
                                 <span *ngIf="!value.image_video && !value.file_name">-</span>
                             </td>
                             <td>
                                 {{ value.event_date |date:'dd-MM-yyyy' }}
                             </td>
                             <td>
                                 <span *ngIf="value.event_close" class="btn pbtn-lite">Closed</span>
                                 <span *ngIf="!value.event_close">
                                     <span *ngIf="value.users.length == 1" >
                                         <button class="btn pbtn-lite" type="button" (click)="cancelRegistraion(value.id)">
                                             Cancel Registration
                                         </button>
                                     </span>
                                     <span *ngIf="value.users.length == 0">
                                         <button class="btn pbtn" type="button" (click)="register(value.id)">
                                             Register
                                         </button>
                                     </span>
                                 </span>
                             </td>
                         </tr>
                         <tr *ngIf="registered.length==0">
                             <td colspan="3">No Data Found</td>
                         </tr>
                     </tbody>
                     <ng-template #no_data_templ>
                         <tr>
                             <td colspan="3" class="text-center">No Data Found</td>
                         </tr>
                     </ng-template>
                 </table>
             </div>
         </div>
         </div>
         
         <div class="row clearfix">
         <div class="col-md-12">
             <h3 class="text-center">Registered Closed Events</h3>
             <div class="body">
                 <table class="table table-striped">
                     <thead>
                         <tr>
                             <th scope="col">#</th>
                             <th scope="col">Title</th>
                             <th scope="col">Image/Video</th>
                             <th scope="col">Event Date</th>
                             <th scope="col">Action</th>
                         </tr>
                     </thead>
                     <tbody *ngIf="registeredClosed?.length; else no_data_templ">
                         <tr *ngFor="let value of registeredClosed; index as i">
                             <th scope="row">{{ i + 1 }}</th>
                             <td>
                                 {{ value.title }}
                             </td>
                             <td>
                                 <span *ngIf="value.image_video && value.file_name">
                                     <span *ngIf="value.image_video==1">
                                         <img src="{{value.file_name}}" width="50" height="50">
                                     </span>
                                     <span *ngIf="value.image_video==2">
                                         <video width="50" height="50" controls>
                                             <source src="{{value.file_name}}" type="video/mp4">
                                             Your browser does not support the video tag.
                                           </video> 
                                     </span>
                                 </span>
                                 <span *ngIf="!value.image_video && !value.file_name">-</span>
                             </td>
                             <td>
                                 {{ value.event_date |date:'dd-MM-yyyy' }}
                             </td>
                             <td>
                                 <span *ngIf="value.event_close" class="btn pbtn-lite">Closed</span>
                                 <span *ngIf="!value.event_close">
                                     <span *ngIf="value.users.length == 1" >
                                         <button class="btn pbtn-lite" type="button" (click)="cancelRegistraion(value.id)">
                                             Cancel Registration
                                         </button>
                                     </span>
                                     <span *ngIf="value.users.length == 0">
                                         <button class="btn pbtn" type="button" (click)="register(value.id)">
                                             Register
                                         </button>
                                     </span>
                                 </span>
                             </td>
                         </tr>
                         <tr *ngIf="registeredClosed.length==0">
                             <td colspan="3">No Data Found</td>
                         </tr>
                     </tbody>
                     <ng-template #no_data_templ>
                         <tr>
                             <td colspan="3" class="text-center">No Data Found</td>
                         </tr>
                     </ng-template>
                 </table>
             </div>
         </div>
         </div>
         
     <div class="row clearfix">
         <div class="col-md-12">
             <h3 class="text-center">Closed Events</h3>
             <div class="body">
                 <table class="table table-striped">
                     <thead>
                         <tr>
                             <th scope="col">#</th>
                             <th scope="col">Title</th>
                             <th scope="col">Image/Video</th>
                             <th scope="col">Event Date</th>
                             <th scope="col">Action</th>
                         </tr>
                     </thead>
                     <tbody *ngIf="closed?.length; else no_data_templ">
                         <tr *ngFor="let value of closed | paginate: { itemsPerPage: 10, currentPage: page,
                             totalItems: count } ; index as i">
                             <th scope="row">{{ value.rowNo }}</th>
                             <td>
                                 {{ value.title }}
                             </td>
                             <td>
                                 <span *ngIf="value.image_video && value.file_name">
                                     <span *ngIf="value.image_video==1">
                                         <img src="{{value.file_name}}" width="50" height="50">
                                     </span>
                                     <span *ngIf="value.image_video==2">
                                         <video width="50" height="50" controls>
                                             <source src="{{value.file_name}}" type="video/mp4">
                                             Your browser does not support the video tag.
                                           </video> 
                                     </span>
                                 </span>
                                 <span *ngIf="!value.image_video && !value.file_name">-</span>
                             </td>
                             <td>
                                 {{ value.event_date |date:'dd-MM-yyyy' }}
                             </td>
                             <td>
                                 <span *ngIf="value.event_close" class="btn pbtn-lite">Closed</span>
                                 <span *ngIf="!value.event_close">
                                     <span *ngIf="value.users.length == 1" >
                                         <button class="btn pbtn-lite" type="button" (click)="cancelRegistraion(value.id)">
                                             Cancel Registration
                                         </button>
                                     </span>
                                     <span *ngIf="value.users.length == 0">
                                         <button class="btn pbtn" type="button" (click)="register(value.id)">
                                             Register
                                         </button>
                                     </span>
                                 </span>
                             </td>
                         </tr>
                         <tr *ngIf="closed.length==0">
                             <td colspan="3">No Data Found</td>
                         </tr>
                     </tbody>
                     <ng-template #no_data_templ>
                         <tr>
                             <td colspan="3" class="text-center">No Data Found</td>
                         </tr>
                     </ng-template>
                 </table>
             </div>
         </div>
         </div>
         <div class="d-flex justify-content-center pagination">
            <pagination-controls 
               responsive="true" 
               previousLabel="Prev" 
               nextLabel="Next" 
               (pageChange)="handlePageChange($event)"></pagination-controls>
         </div> -->

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="body">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-sm-6">
                            <div class="stat-content">
                                <div class="input-group icons">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-transparent border-0" id="basic-addon1"><i
                                                class="icon-magnifier"></i></span>
                                    </div>
                                    <input type="search" class="form-control" placeholder="Search by event name"
                                        aria-label="Search by event name" (keydown)="searchEvent($event)">
                                </div>
                            </div>
                        </div> <!-- /# column -->
                        <div class="col-lg-3 col-sm-6">
                            <div class="dropdown" data-control="checkbox-dropdown">
                                <label class="dropdown-label"> {{filterText[event_type]}}</label>

                                <div class="dropdown-list">
                                    <label class="dropdown-option">
                                        <img src="./assets/assets/images/allevents.png" alt="allevents" />
                                        All Events
                                        <input type="radio" name="dropdown-group" value="0"
                                            (change)="radioChange($event)" [checked]="event_type == 0" />
                                    </label>

                                    <label class="dropdown-option">
                                        <img src="./assets/assets/images/todays.png" alt="todays" />
                                        Today's Events
                                        <input type="radio" name="dropdown-group" (change)="radioChange($event)"
                                            value="1" [checked]="event_type == 1" />

                                    </label>

                                    <label class="dropdown-option">
                                        <img src="./assets/assets/images/Upcomings.png" alt="Upcomings" />
                                        Upcomings Events
                                        <input type="radio" name="dropdown-group" (change)="radioChange($event)"
                                            value="2" [checked]="event_type == 2" />

                                    </label>
                                </div>
                            </div>
                        </div> <!-- /# column -->
                        <div class="col-lg-3 col-sm-6">
                            <div class="calendar-body stat-content">
                                <button type="button" (click)="toggleShow()" [class.active]="classApplied"
                                    class="btn ViewDate">{{fltDataLblStr}} <span><i
                                            class="icon-calendar"></i></span></button>
                                <full-calendar *ngIf="isShown" [options]="calendarOptions"></full-calendar>
                            </div>

                        </div> <!-- /# column -->
                        <div class="col-lg-3 col-sm-6">
                            <div class="stat-content">
                                <button type="button" class="btn pbtn" (click)="clearDate()">Clear Date <i
                                        class="fa fa-remove"></i> </button>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-sm-6">
                         <div class="stat-content">
                               <button  type="button" class="btn pbtn"><i class="fa fa-plus"></i> Register New Events</button>
                        </div>          
                 </div>  -->
                        <!-- /# column -->
                    </div><!-- /# row -->
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Time</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Event Name</th>
                                <th scope="col" width="150px">Meeting Link</th>
                                <th scope="col">Event Confirmation</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="userEvents?.length">
                            <tr *ngFor="let value of userEvents; index as i">
                                <td>{{ value.event_time }}</td>
                                <td>{{ value.event_date |date:'EEEE, MMMM d, y' }}</td>
                                <td>
                                    <!-- <span class="empty"><img src="./assets/assets/images/empty.png" alt="empty"></span> -->
                                    --
                                </td>
                                <td>{{ value.title }}</td>
                                <td class="linkmeeting">
                                    <div [innerHTML]="replaceURLWithHTMLLinks(value.meeting_link)"></div>
                                </td>
                                <td>
                                    <span *ngIf="value.event_close">
                                        <button class="btn btn-closed" type="button"> Event Closed</button>
                                    </span>
                                    <span *ngIf="!value.event_close">
                                        <!-- <span *ngIf="value.users.length == 1">
                                    <button class="btn Registered-New" type="button" (click)="cancelRegistraion(value.id)">
                                        Registered 
                                    </button>
                                </span> -->
                                        <!--   Cancel Registered -->
                                        <span *ngIf="value.users.length == 1">
                                            <button class="btn ToRegisterbtn" type="button"
                                                (click)="cancelRegistraion(value.id)">
                                                Cancel Registration
                                            </button>
                                        </span>
                                        <span *ngIf="value.users.length == 0 && isFreeTrial == false">
                                            <button class="btn Registered-New " type="button"
                                                (click)="register(value.id)">
                                                To Register, Click Here
                                            </button>
                                        </span>
                                        <span *ngIf="value.users.length == 0 && isFreeTrial == true">
                                            <button class="btn ToRegisterbtn" type="button"
                                                (click)="OpenPlanPopup(PlanModal)">
                                                To Register, Click Here
                                            </button>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <!-- not found events start -->
                        <tr *ngIf="!userEvents?.length">
                            <td colspan="12">
                                <div class="notfoundevents">
                                    <img src="./assets/assets/images/event-notfound.png" alt="event not found">
                                    <h6>You didn't join any event yet.</h6>
                                    <p>Go to upcoming events and register for upcoming meetings. Registered events will
                                        appear here.</p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template #PlanModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">You have to upgrade your plan</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="Iconwarning-box mb-4">
                    <span class="info-Iconwarning "> <img src="./assets/assets/images/Iconwarning.svg"
                            alt="PinkLogo"></span>
                    <div class="Iconwarning-box-content">
                        <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership
                            ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,
                            y'}}. Add your payment details / renew to keep
                            enjoying the benefits of all features by VTTouch.
                        </p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                        <div class="single-price-plan mb-100">
                            <div class="price-plan-title">
                                <h4>{{singlePlan.plan_name}}
                                </h4>
                            </div>
                            <div class="price-plan-value">
                                <h2>£{{singlePlan.price}}</h2>
                                <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                            </div>
                            <a href="javascript:;" class="Buttons-pink "
                                *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id"
                                [routerLink]="['/PurchasePlan/'+singlePlan.id]"
                                (click)="modal.dismiss('Cross click')">Start Trial</a>
                            <a href="#" class="Buttons-pink "
                                *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id"
                                [routerLink]="['/PurchasePlan/'+singlePlan.id]"
                                (click)="modal.dismiss('Cross click')">Continue</a>
                        </div>
                    </div>


                </div>
            </div>
        </ng-template>
    </div>
</div>