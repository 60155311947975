<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item">
              <a [routerLink]="['/Admin-Dashboard']">
                <i class="icon-home"></i>
              </a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/admin/jobs">Jobs</a>
            </li>
            <li class="breadcrumb-item" *ngIf="isAddMode">Job Add</li>
            <li class="breadcrumb-item" *ngIf="!isAddMode">Job Edit</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-md-12">
        <div class="card">
          <div class="header text-center">
            <h2 *ngIf="isAddMode">Add Job</h2>
            <h2 *ngIf="!isAddMode">Edit Job</h2>
          </div>
          <div class="body">
            <form [formGroup]="CreateJobForm" enctype="multipart/form-data">
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company <span>*</span></label>
                    <select formControlName="company_id" class="form-control">
                      <option [ngValue]="null" disabled>Select Company</option>
                      <option *ngFor="let company of companies" [ngValue]="company.id">
                        {{company.company_name}}
                      </option>
                    </select>
                    <span class="text-danger" *ngIf="control.company_id.dirty && control.company_id.hasError('required')"> Company field Required.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Job Name <span>*</span></label>
                    <input class="form-control" type="text" formControlName="job_name" />
                    <span class="text-danger" *ngIf="control.job_name.dirty && control.job_name.hasError('required')"> Job Name field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Job Location <span>*</span></label>
                    <input class="form-control" type="text" formControlName="job_location" />
                    <span class="text-danger" *ngIf="control.job_location.dirty && control.job_location.hasError('required')"> Job Location field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Salary <span>*</span></label>
                    <input class="form-control" type="number" formControlName="salary" />
                    <span class="text-danger" *ngIf="control.salary.dirty && control.salary.hasError('required')"> Salary field is requied.</span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Keywords [Seperated by comma] <span>*</span></label>
                    <input class="form-control" type="text" formControlName="keywords" />
                    <span class="text-danger" *ngIf="control.keywords.dirty && control.keywords.hasError('required')"> Keywords field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Published Date <span>*</span></label>
                    <input class="form-control" type="date" formControlName="published_date" />
                    <span class="text-danger" *ngIf="control.published_date.dirty && control.published_date.hasError('required')"> Published Date field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>End Date <span>*</span></label>
                    <input class="form-control" type="date" formControlName="end_date" />
                    <span class="text-danger" *ngIf="control.end_date.dirty && control.end_date.hasError('required')"> End Date field is requied.</span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Summary of Role <span>*</span></label>
                    <textarea class="form-control post-style" rows="5" formControlName="role_summary" placeholder="Writer summary of the role"></textarea>
                    <span class="text-danger" *ngIf="control.role_summary.dirty && control.role_summary.hasError('required')"> Summary of Role field is requied. </span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Personal Specifications <span>*</span></label>
                    <textarea class="form-control post-style" rows="5" formControlName="candidate_personal_specifications" placeholder="Writer personal specifications for ideal candidate"></textarea>
                    <span class="text-danger" *ngIf="control.candidate_personal_specifications.dirty && control.candidate_personal_specifications.hasError('required')"> Personal Specifications field is requied. </span>
                  </div>
                </div>
                
                <div class="col-md-6">
                  <input class="form-control" type="hidden" formControlName="id" />
                  <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="!CreateJobForm.valid">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>