<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Blog Room</a></li>
                      </ul>
            
                </div>
            </div>
        </div>


        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Blog Room List</h2>
                        <a routerLink="/admin/dealroom/add" class="btn pbtn color_cls">Create Blog Room</a>
                    </div>
                    <div class="body">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Blog Room Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Create Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="dealrooms?.length; else no_data_templ">
                                <tr *ngFor="let value of dealrooms; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>
                                        {{ value.room_name }}
                                    </td>
                                    <td>
                                        {{ value.description | truncate:[15] }}
                                    </td>
                                    <td>
                                        {{ value.created_at | date:'dd-MM-yyyy' }}
                                    </td>
                                    <td>
                                        <a routerLink="/admin/dealroom/edit/{{value.id}}" class="btn btn-primary btn-sm" ngbTooltip="Edit Room"><i class="fa fa-pencil"></i></a>
                                        <a routerLink="/admin/dealroom/users/{{value.id}}" class="btn btn-info btn-sm m-l-5" placement="top" ngbTooltip="Add/Edit User"><i class="fa fa-plus"></i></a>
                                        <a routerLink="/admin/dealroom/request-users/{{value.id}}" class="btn btn-success btn-sm m-l-5" placement="top"
                                            ngbTooltip="Show Request User"><i class="fa fa-eye"></i></a>
                                        <span *ngIf="i<5">
                                            <button class="btn btn-warning btn-sm m-l-5 text-white" ngbTooltip="This Room is not deletable">Not Deletable</button>
                                        </span>
                                        <span *ngIf="i>=5">
                                            <button class="btn btn-danger btn-sm m-l-5" type="button" (click)="delete(value.id)" ngbTooltip="Delete Room">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngIf="dealrooms.length==0">
                                    <td colspan="4">No Data Found</td>
                                </tr>
                            </tbody>
                            <ng-template #no_data_templ>
                                <tr>
                                    <td colspan="4" class="text-center">No Data Found</td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>

                </div>
            </div>

        </div>


    </div>
</div>