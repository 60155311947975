import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.css']
})
export class PartnerListComponent implements OnInit {
  partnerList;
  public loading: boolean;
  constructor(private partnerService: PartnerService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.getAllPartner();
  }
  getAllPartner() {
    this.partnerService.getAll().subscribe((res: any) => {
      if (res.status) {
        this.partnerList = res.data;
      } else {
        this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.toastr.error(err, "Error");
      });
  }

  editPartner(id) {
    this.router.navigate(['/admin/partner-edit/' + id]);
  }

  deletePartner(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const formData = new FormData();
        formData.append('partner_id', id);
        this.partnerService.delete(formData).subscribe((res: any) => {
          this.loading = false;
          if (res.status) {
            this.toastr.success('Data deleted successfully', "Success");
            this.getAllPartner();
          } else {
            this.toastr.error(res.message, "Error");
          }
        }, err => {
          this.toastr.error(err, "Error");
        })
      }
    })

  }

  backClicked() {
    this._location.back();
  }
}
