<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
      <div class="container-fluid">
        <div class="top-head-vt mt-4">
        <div class="row">
          <div class="col-lg-7 col-md-12 col-sm-12">
        
            <ul class="breadcrumb vt-a">
              <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
              <li class="breadcrumb-item">Dashboard</li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <div class="daskborad-left">
          <div class="container-fluid">
            <div class="block-header top-head-vt">
              <!-- <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
              
                  <ul class="breadcrumb vt-a">
                    <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                    <li class="breadcrumb-item">Dashboard</li>
                  </ul>
                </div>
              </div> -->
              <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card card-vt mn-height">
                  <div class="header">
                    <h2>Start a Conversation
                      <a [routerLink]="['/group/1/start-conversation']"><small>View All <i class="fa fa-angle-right"></i></small></a>
                    </h2>
                  </div>

                  <div class="body">
                    <ul class="list-unstyled feeds_widget">
                      <li *ngFor="let val of posts">
                        <div class="feeds-body" [routerLink]="['/group/1/start-conversation']">
                          <h4 class="title"> {{val.post_description | truncate:[30, '...'] | titlecase }} <small class="float-right text-muted"><i class="fa fa-ellipsis-v"></i></small></h4>
                          <small><i class="fa fa-clock-o"></i> {{val.created_at | date: 'd MMM yyyy hh:mm a'}}</small>
                        </div>
                      </li>
                      <li *ngIf="posts.length==0">
                        <div class="feeds-body">
                          <h4 class="title"> No post found</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div><!--./col-lg-6 col-md-12 col-sm-12-->
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card card-vt mn-height">
                  <div class="header">
                    <h2>Recent Comments 
                      <a [routerLink]="['/group/1/start-conversation']"><small>View All <i class="fa fa-angle-right"></i></small></a></h2>
                  </div>
                  <div class="body">
                    <ul class="list-unstyled feeds_widget">
                      <li *ngFor="let val of comments">
                        <div class="feeds-body" [routerLink]="['/group/1/start-conversation']">
                          <h4 class="title">{{val.description | truncate:[30, '...'] | titlecase}} <small class="float-right text-muted"><i
                                class="fa fa-ellipsis-v"></i></small></h4>
                          <small><i class="fa fa-clock-o"></i> {{val.created_at | date: 'd MMM yyyy hh:mm a'}}</small>
                        </div>
                      </li>
                      <li *ngIf="comments.length==0">
                        <div class="feeds-body">
                          <h4 class="title"> No comment found</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
                 <!-- <div class="card card-vt-right">
                  <div class="text-right" *ngIf="roleId==3">
                    <div class="dashboard-register" *ngIf="event">
                      <p class="animate-charcter"> <b>Next {{event.title}} Event is on:</b>
                      </p>
                      <span *ngIf="event.users?.length == 1">
                        <button class="btn btn-danger" type="button" (click)="cancelRegistraion(event.id)">
                          {{event.event_date}}
                        </button>
                      </span>
                      <span *ngIf="event.users?.length == 0 && enableEventRegistration == true"><button class="btn btn-primary" type="button"
                            (click)="register(event.id)">Register</button></span>
                        <span *ngIf="event.users?.length == 0 && enableEventRegistration == false"><button class="btn btn-primary" type="button"
                              (click)="OpenPlanPopup(PlanModal)">Register</button></span>
                     
                    </div>
                    <div class="dashboard-register" *ngIf="event == null">
                      <p>You don't have any future event
                    </div>
          
                  </div>
                  <div class="header">
                    <h2>Recent Events
                      <a [routerLink]="['/vt-events']"><small>View All <i class="fa fa-angle-right"></i></small></a></h2>
                  </div>
                  <div class="body">
                    <ul class="list-unstyled feeds_widget">
                      <li *ngFor="let val of rEvents">
                        <div class="feeds-body" [routerLink]="['/vt-events']">
                          <h4 class="title">{{val.title | titlecase}} <small class="float-right text-muted"><i
                                class="fa fa-ellipsis-v"></i></small></h4>
                          <small><i class="fa fa-clock-o"></i> {{val.event_date | date: 'd MMM yyyy'}}</small>
                        </div>
                      </li>
                      <li *ngIf="rEvents.length==0">
                        <div class="feeds-body">
                          <h4 class="title"> No registered event found</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <!-- tools -->
                  <!-- <div class="header">
                    <h2>Tools
                    <a [routerLink]="['/tools']"><small>View All <i class="fa fa-angle-right"></i></small></a></h2>
                  </div>
                  <div class="body table-responsive">
                    <table class="table  m-b-0">
                      <thead>
                        <tr>
                          <th>Tools Name</th>
                          <th colspan="1">Action</th>
                          <th colspan="1"> View Saved Reports</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row" *ngFor="let value of toolData;let i = index" [class.active]="i == currentIndex">
                          <td>{{ value.tool_name }}</td>
                          <td>
                            <div *ngIf="i > 0 && isFreeTrial">
                                <button type="button" class="btn pbtn FreePlanButton" (click)="OpenPlanPopup(PlanModal)">Create</button>
                            </div>
                            <div *ngIf="i > 0 && !isFreeTrial">
                                <button type="button" class="btn pbtn" (click)="gotomaster(value.id, i+1)">Create</button>
                            </div>
                            <div *ngIf="i==0">
                                <button type="button" class="btn pbtn" (click)="gotomaster(value.id, i+1)">Create</button>
                            </div>
                              <div *ngIf="value.user_tools.length<5">
                                  <button type="button" class="btn pbtn" (click)="gotomaster(value.id, i)">Create</button>
                              </div>
                              <div *ngIf="value.user_tools.length>=5">
                                  <button type="button" class="btn btn-warning" tooltip="You already reached your maximum tool" placement="bottom" disabled>Create</button>
                              </div>
                          </td>
                          <td>
                            <div *ngIf="value.user_tools.length>0">
                              <div class="dropdown">
                                  <button type="button" class="btn pbtn dropdown-toggle" data-toggle="dropdown">
                                    View Reports
                                  </button>
                                  <div class="dropdown-menu">
                                      <div *ngFor="let tool of value.user_tools">
                                        <span class="dropdown-item">
                                          <button type="button" class="btn btn-sm " (click)="viewReport(tool.id, i)">{{tool.tool_user_name}}</button>
                                          <button type="button" class="btn pbtn btn-sm m-b-2" (click)="DeleteReport(tool.id)"><i class="fa fa-trash"></i></button>
                                        </span>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          <div *ngIf="value.user_tools.length==0">
                              -
                          </div>
                          </td>
                          <td><a class="btn btn-success btn-sm">Viewed</a></td>
                          <td>No</td>
                          <td><i class="fa fa-mail-forward margin-right"></i> <i class="fa fa-ellipsis-v"></i></td>
                        </tr>
                      </tbody>
                    </table>
                </div> -->
              <!--./col-lg-6 col-md-12 col-sm-12-->
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card card-vt-right nominationBoard">
                  <a class="items-center nominationBlock" href="https://yorkshireandhumbersidefamilybusinessawards.co.uk/enter-the-2024-awards/" target="_blank">
                    <div class="flex mb-4 items-center body">
                      <img src="./../../../assets/assets/images/icon-ribbon.png" alt="icon-ribbon">
                        <h2>Enter the 2024 awards</h2>
                        <a class="nominate_btn" href="https://yorkshireandhumbersidefamilybusinessawards.co.uk/enter-the-2024-awards/" target="_blank">Click Here</a>
                    </div> 
                   </a>
              </div>          
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card card-vt-right nominationBoard">
                  <a class="items-center nominationBlock peer-groupsBlock" href="https://fambizcommunity.com/peer-groups/" target="_blank">
                    <div class="flex mb-4 items-center body">
                      <img src="./../../../assets/assets/images/joingroups.png" alt="joingroups-icon">
                        <h2>Join Our Peer Groups! </h2>
                        <a class="nominate_btn" href="https://fambizcommunity.com/peer-groups/" target="_blank">join Groups</a>
                    </div> 
                   </a>
              </div>
              </div>
              </div><!--./row-->
            </div>
            
            <!--./block-header top-head-vt-->
            <ng-template #PlanModal let-modal>
              <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                     <span aria-hidden="true">&times;</span>
                   </button>
               </div>
               <div class="modal-body">
                  <div class="Iconwarning-box mb-4">
                     <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
                     <div class="Iconwarning-box-content">
                        <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep
                           enjoying the benefits of all features by VTTouch.
                        </p>
                     </div>
                  </div>
                  <div class="row justify-content-center">
                     <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                        <div class="single-price-plan mb-100">
                           <div class="price-plan-title">
                              <h4>{{singlePlan.plan_name}}
                              </h4>
                           </div>
                           <div class="price-plan-value">
                              <h2>£{{singlePlan.price}}</h2>
                              <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                           </div>
                             <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                             <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                        </div>
                     </div>


                  </div>
               </div>
            </ng-template>
            </div><!--./container-fluid-->
      </div><!--./daskborad-left-->

      <div class="daskborad-right">
        <!--./text-right-->
        <div class="body card card-vt mn-height calendar-body col-md-12">
          <!-- <div id="calendar"></div> -->
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div><!--./body-->
        <div class="card card-vt-right ProgrammeBoard">
          <a class="items-center nominationBlock ProgrammeBlock" href="https://fambizcommunity.com/a-directors-introduction-course/" target="_blank">
            <div class="flex mb-4 items-center body">
              <img src="./../../../assets/assets/images/programmes.png" alt="programmes-icon">
                <h2>Programmes </h2>
                <a class="nominate_btn" href="https://fambizcommunity.com/a-directors-introduction-course/" target="_blank">Click Here</a>
            </div> 
           </a>
      </div>
          <!-- <div class="card card-vt-right OffringBoard">
              <a class="items-center vacanciesBlock" href="https://fambizcommunity.com/a-directors-introduction-course/" target="_blank">
                <div class="flex mb-4 items-center body">
                    <h2> Exclusive Offer </h2>
                    <p>50% off per plan</p>
                   <p>10% off up to 200</p>
                   <p>Off-site vacancies</p>
                   <p>222 vacancies</p>
                   <p>Get 10% instant discount</p>
                </div> 
               </a>
          </div> -->
     
      </div><!--./daskborad-right-->

</div><!---./main-content-->
