import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  public loading: boolean;
  public remember: boolean = false;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.authService.isLogedin) {
      this.router.navigateByUrl('dashboard');
    }


    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember_me: [this.remember],
      acceptTerms: [''],
    });
    let login_email = localStorage.getItem('login_email');
    let login_password = localStorage.getItem('login_password');

    if (login_email != null && login_password != null) {
      this.remember = true;
      this.loginForm = this.formBuilder.group({
        email: [login_email, [Validators.required, Validators.email]],
        password: [login_password, Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
      });
      this.loginForm.controls['remember_me'].value(1)
    }
  }

  submit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.login(this.loginForm.value).subscribe((res: any) => {
      if (res.status) {
        this.loading = false;
        this.authService.user = res.user;
        this.authService.userToken = res.user.token;
        this.toastr.success('You are logged in successfully', "Success");

        if (res.user.user_role == 1) {
          this.router.navigateByUrl('Admin-Dashboard');
        } else {
          this.authService.getUserActivePlan().subscribe((res: any) => {
            if (res.status) {

              if (this.remember) {
                localStorage.setItem('login_email', this.loginForm.controls['email'].value);
                localStorage.setItem('login_password', this.loginForm.controls['password'].value);
                console.log(this.remember, 'true remember me')
                console.log(this.loginForm.controls['email'].value, 'true remember me')
                console.log(this.loginForm.controls['password'].value, 'true remember me')
              } else {
                console.log(this.remember, 'false remember me')
                console.log(this.loginForm.controls['email'].value, 'true remember me')
                console.log(this.loginForm.controls['password'].value, 'true remember me')
                localStorage.removeItem('login_email');
                localStorage.removeItem('login_password');
              }
              this.router.navigateByUrl('dashboard');

            } else {
              // this.router.navigateByUrl('OurPlan');

              //alert(res.message);
            }

          }, err => {
            this.router.navigateByUrl('dashboard');
            //this.router.navigateByUrl('OurPlan');
          })
        }
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
        //alert(res.message);
      }
    }, err => {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }

  get control() {
    return this.loginForm.controls;
  }

  forgotPassword() {
    this.router.navigateByUrl('forgot-password');
  }

  checkValue = (event) => {
    let isChecked = event.target.checked;
    console.log(isChecked, 'isChecked')
    console.log(localStorage.getItem('login_email'), 'login_email 123')
    console.log(localStorage.getItem('login_password'), 'login_password 456')
    if (isChecked) {
      this.remember = true
      let login_email = localStorage.getItem('login_email');
      let login_password = localStorage.getItem('login_password');

      if (login_email != null) {
        this.control.email.setValue(login_email);
      }
      if (login_password != null) {
        this.control.password.setValue(login_password);
      }


      if (this.control.email.value) {
        localStorage.setItem('login_email', this.control.email.value);
      }
      if (this.control.password.value) {
        localStorage.setItem('login_password', this.control.password.value);
      }

    } else {
      // localStorage.removeItem('login_email');
      // localStorage.removeItem('login_password');
      this.remember = false
    }
  }

}
