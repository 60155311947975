import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';

import { SecondHeaderComponent } from '../layout/second-header/second-header.component';
import { SecondSidebarComponent } from './second-sidebar/second-sidebar.component';
import { TruncatePipe } from './filter/truncate.pipe';

@NgModule({
  declarations: [SecondHeaderComponent, SecondSidebarComponent, TruncatePipe],
  imports: [
    CommonModule,
    SharedRoutingModule,
  ],
  exports: [
     SecondHeaderComponent,
     SecondSidebarComponent,
     TruncatePipe
   ],
   schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
