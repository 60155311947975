<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <!-- <div class="block-header">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <h2><a href="javascript:void(0);" class="btn btn-xs btn-link" (click)="backClicked()">
                            <i class="fa fa-arrow-left"></i>
                        </a> Room User
                    </h2>
                </div>
            </div>
        </div> -->
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Dealroom</li>
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Room User</a></li>
                      </ul>
            
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Room User List</h2>
                        
                    </div>
                    <div class="body">
                        
                            <form [formGroup]="userForm" enctype="multipart/form-data">
                                <!-- <div class="col-md-3" *ngFor="let user of users;index as i"> -->
                                    <div class="form-check form-check-inline   col-md-3" *ngFor="let user of users;index as i">
                                        <input type="checkbox" class="form-check-input" id="check_{{i}}" 
                                        [value]="user.id" (change)="onCheckboxChange($event)" [checked]="user.is_checked">
                                        <label class="form-check-label" for="check_{{i}}">
                                         {{user.name}}
                                        </label>
                                    </div>
                                    <input type="hidden" fornControlName="deal_room_id">
                                    <div class="clearfix"></div>
                                    <button type="submit" class="btn btn-primary m-t-5" (click)="submit()" [disabled]="!userForm.valid">
                                        Save
                                    </button>
                                <!-- </div> -->
                            </form>
                    </div>

                </div>
            </div>

        </div>


    </div>
</div>