import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VideoAndBlogService } from 'src/app/services/videoandblog.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  videoAndBlogDetails:any;
  id:string;
  constructor(
    private videoAndBlogService:VideoAndBlogService,
    private router: Router, private route: ActivatedRoute,
    private toastr:ToastrService,
    private sanitizer: DomSanitizer
  ) { 

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.videoAndBlogService
        .getSingleVideoBlogs({ 'id': this.id }).subscribe((res: any) => {
          if (res.status) {
            this.videoAndBlogDetails = res.data;
          } else {
            this.toastr.error("data not found.", "Error");
          }
        })
  }

  sanitizUrl(url:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

}
