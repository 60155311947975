import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EventService } from 'src/app/services/event.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CalendarOptions, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-user-event-list',
  templateUrl: './user-event-list.component.html',
  styleUrls: ['./user-event-list.component.css']
})
export class UserEventListComponent implements OnInit {
  isShown: boolean = false;
  classApplied = false;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'timeGridDay,dayGridMonth,timeGridWeek ',
      right: ' prev,title,next ',
    },
    dayMaxEvents: true,
    // allow "more" link when too many events
    //dayMaxEventRows: true
  };
  events;
  userEvents: any = [];
  upComing: any = [];
  registered: any = [];
  registeredClosed: any = [];
  closed: any = [];
  filterText = ['All Events', 'Today\'s Events', 'Upcomings Events'];
  search_text = "";
  event_type = 0;
  filter_selected_date = "";
  fltDataLblStr = 'View Date(s)';

  public loading: boolean;

  page = 1;
  count: Number = 0;

  isFreeTrial: boolean = false;
  closeResult: string;
  CurrentPlan: any;
  planData: any;
  CurrentPlanID = 0;
  nextBillingDate = new Date();
  modalReference: NgbModalRef;

  constructor(private eventService: EventService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private authService: AuthService, private modalService: NgbModal) {

  }


  toggleShow() {
    this.isShown = !this.isShown;
    this.classApplied = !this.classApplied;
  }
  ngOnInit(): void {
    // dropdown

    (function ($) {
      var CheckboxDropdown = function (el) {
        var _this = this;
        this.isOpen = false;
        this.areAllChecked = false;
        this.$el = $(el);
        this.$label = this.$el.find('.dropdown-label');
        this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
        this.$inputs = this.$el.find('[type="radio"]');

        this.onCheckBox();

        this.$label.on('click', function (e) {
          e.preventDefault();
          _this.toggleOpen();
        });

        this.$checkAll.on('click', function (e) {
          e.preventDefault();
          _this.onCheckAll();
        });

        this.$inputs.on('change', function (e) {
          _this.onCheckBox();
          _this.toggleOpen();
        });
      };

      CheckboxDropdown.prototype.onCheckBox = function () {
        // this.updateStatus();
      };

      CheckboxDropdown.prototype.updateStatus = function () {
        var checked = this.$el.find(':checked');


        if (checked.length <= 0) {
          this.$label.html('All Events');
        }
        else if (checked.length === 1) {
          this.$label.html(checked.parent('label').text());
        }
        else {
          this.$label.html(checked.length + ' Selected');
        }
      };

      CheckboxDropdown.prototype.onCheckAll = function (checkAll) {
        if (!this.areAllChecked || checkAll) {
          this.areAllChecked = true;
          this.$checkAll.html('Uncheck All');
          this.$inputs.prop('checked', true);
        }
        else {
          this.areAllChecked = false;
          this.$checkAll.html('Check All');
          this.$inputs.prop('checked', false);
        }

        this.updateStatus();
      };

      CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
        var _this = this;

        if (!this.isOpen || forceOpen) {
          this.isOpen = true;
          this.$el.addClass('on');
          $(document).on('click', function (e) {
            if (!$(e.target).closest('[data-control]').length) {
              _this.toggleOpen();
            }
          });
        }
        else {
          this.isOpen = false;
          this.$el.removeClass('on');
          $(document).off('click');
        }
      };

      var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
      for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
        new CheckboxDropdown(checkboxesDropdowns[i]);
      }
    })(jQuery);
    // calender
    this.eventService.getAllEventList().subscribe((res: any) => {
      if (res.status) {
        this.events = res.data;
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          headerToolbar: {
            // left: 'timeGridDay,dayGridMonth,timeGridWeek',
            right: 'prev,title,next',
          },
          dayMaxEvents: true,
          dateClick: this.onDateClick.bind(this),
          eventClick: this.handleEventClick.bind(this),
          events: this.events,
        };
        this.resizeDisplay();
      } else {
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          headerToolbar: {
            // left: 'timeGridDay,dayGridMonth,timeGridWeek',
            right: 'prev,title,next',
          },
          dayMaxEvents: true,
          //dateClick: this.onDateClick.bind(this),
          events: []
        };
        this.resizeDisplay();
        this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.events = [];
        this.toastr.error(err, "Error");
      });

    // this.eventService.getAllUserEvent().subscribe((data: any)=>{
    //   if(data.status){
    //     //this.events = data.events;
    //     this.count = data.count;
    //     this.closed = data.events.filter(x => x.event_close == true);
    //     this.registeredClosed = data.events.filter(x => x.event_close == true && x.registered==true);
    //     this.registered = data.events.filter(x => x.event_close == false && x.registered==true);
    //     this.upComing = data.events.filter(x => x.event_close == false && x.registered==false);
    //   }else{
    //     this.toastr.error(data.message, "Error");
    //     this.router.navigateByUrl('dashboard');
    //   }
    // });
    this.getAllUserEvent();
    this.getCurrentPlan();
    this.getAllPlanList();
  }

  handlePageChange(event) {
    //console.log(event)
    this.page = event;
    this.getAllUserEvent();
  }

  getAllUserEvent() {
    this.eventService.getAllUserEvent(this.search_text, this.event_type, this.filter_selected_date).subscribe((data: any) => {
      if (data.status) {
        this.userEvents = data.events;
        this.count = data.count;
        this.closed = data.events.filter(x => x.event_close == true);
        this.registeredClosed = data.events.filter(x => x.event_close == true && x.registered == true);
        this.registered = data.events.filter(x => x.event_close == false && x.registered == true);
        this.upComing = data.events.filter(x => x.event_close == false && x.registered == false);
      } else {
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    },
      err => {
        this.userEvents = [];
        this.toastr.error(err, "Error");
      });
  }
  resizeDisplay() {
    // setTimeout( function() {
    //   window.dispatchEvent(new Event('resize'))
    // }, 1000)
  }

  backClicked() {
    this._location.back();
  }

  getCurrentPlan() {
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        if (res.current_active_plan.plan_id == 1) {
          this.isFreeTrial = true
        }
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }

      } else {
        // this.router.navigateByUrl('OurPlan');
      }
    }, err => {
      //this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
      }
    })
  }

  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

  register(id) {
    Swal.fire({
      title: 'Are you sure you want to register this event?',
      //text: 'You will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/event-icon.png" alt="event-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.eventService.register(id).subscribe((data: any) => {
          if (data.status) {
            this.toastr.success("Event Registered Successfully", "success");
            this.upComing = this.upComing.filter(item => item.id !== id);
            this.registered.unshift(data.event);
            this.getAllUserEvent();
          } else {
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('dashboard');
          }
        });
      }
    })
  }

  cancelRegistraion(id) {
    Swal.fire({
      title: 'Are you sure you want to cancel this event?',
      //text: 'You will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/event-cancel-icon.png" alt="event-cancel-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.eventService.cancelRegistraion(id).subscribe((data: any) => {
          if (data.status) {
            this.toastr.success("Event Cancelled Successfully", "success");
            //this.registered.unshift(data.event);
            this.registered = this.registered.filter(item => item.id !== id);
            this.upComing.push(data.event);
            //let itemIndex = this.events.findIndex(item => item.id == id);
            //this.events[itemIndex].users = [];
            this.getAllUserEvent();
          } else {
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('dashboard');
          }
        });
      }
    })
  }
  onDateClick(arg) {
    this.filter_selected_date = arg.dateStr;
    this.fltDataLblStr = this.filter_selected_date;
    this.toggleShow();
    this.getAllUserEvent();
  }

  handleEventClick(arg) {
    this.router.navigateByUrl('vt-events');
  }

  gotomaster(id, i) {
    if (i == 1) {
      this.router.navigateByUrl('/project-tool/' + id);
    } else {
      this.router.navigateByUrl('/mastertools/' + id);
    }
  }

  viewReport(id, i) {
    if (i == 1) {
      this.router.navigateByUrl('project-tool-view/' + id);
    } else {
      this.router.navigateByUrl('viewtool/' + id);
    }
  }

  radioChange(e) {
    this.event_type = e.target.value;
    this.getAllUserEvent();
  }

  searchEvent(e) {
    this.search_text = e.target.value;
    this.getAllUserEvent();
  }

  clearDate() {
    this.filter_selected_date = "";
    this.fltDataLblStr = "View Date(s)";
    this.getAllUserEvent();
  }

  replaceURLWithHTMLLinks(text) {
    if (text) {
      let exp = /(\b(https?|ftp|file|www?|http?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
      let pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      return text.replace(exp, "<a href='$1' target='_blank'>$1</a>").replace(pseudoUrlPattern, "$1<a target='_blank' href='http://$2'>$2</a>");
    }
  }
}
