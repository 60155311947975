<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
   <div class="container-fluid">

      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
               <!-- <h2>Project Leader Characteristics
               </h2> -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/tools">Tools</a></li>
                  <li class="breadcrumb-item active"> Force Field Analysis </li>
               </ul>
            </div>
         </div>
      </div>

      <div id="htmlData">
         <div class="row">

         <div class="col-md-12">
               <!-- <form [formGroup]="questionForm" enctype="multipart/form-data"> -->
                  <div id="myHeader">
                     <div class="row mb-3">

                     <div class="col-md-5 tool-extra-style">
                        <h5 class="tool-extra tool-head-space"> Generate Force Field Analysis Report</h5>
                     </div>

                     <div class="col-md-7 RightButtons">
                        <div style="float: right;" class="m-r-5 Export">
                           <button type="button" class="btn pbtn " (click)="openPDF()">Export As
                              PDF</button>
                        </div>

                     </div>
                  </div>
                  </div>
                  <div class="row">
                     <div class="col-md-12">
                        <div class="AnalysisText">
                           <h5>Name of Analysis</h5>
                           <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                           </button> -->
                           <input type="text" value="{{AnysisName}}" class=" Upgradebtn form-control" disabled>

                        </div>
                     </div>
                     <div class="col-md-6" style="padding-right: 0px !important;">
                        <table id="example" class="table table-striped table-bordered table-responsive  ReportTable">
                           <thead class="head-table text-center">
                                 <tr>
                                    <th colspan="2">
                                       Force "FOR" Change
                                       <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                    </th>
                                 </tr>
                           </thead>
                           <tbody>
                              <tr class="TopHeading">
                                 <td class="Heading">
                                    Factors
                                 </td>  
                                 <td class="Heading">
                                     Rating
                                  </td> 
                             </tr>
                              <tr *ngFor="let number of forceData; let i = index;">
                                 <td class="sorting_1">
                                    <input type="text" class="form-control" value="{{ number.question_title }}" disabled>  

                                    <!-- <span>{{ number.question_title }}</span> -->
                                 </td>
                                 <td class="Numberbox">
                                    <input type="number" name="" class="Numberbox" value="{{ number.total }}" min="1" max="5" disabled>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div class="col-md-6" style="padding-left: 0px !important;">
                        <table id="example" class="table table-striped table-bordered table-responsive  ReportTable">
                           <thead class="head-table text-center">
                                 <tr>
                                    <th colspan="2">Force "AGAINST" Change<i class="fa fa-plus-circle" aria-hidden="true"></i></th>
                                 </tr>
                           </thead>
                           <tbody>
                              <tr class="TopHeading">
                                 <td class="Heading">
                                    Factors
                                 </td>  
                                 <td class="Heading">
                                     Rating
                                  </td> 
                             </tr>
                              <tr *ngFor="let number of forceAgainstData; let i = index;">
                                 <td class="sorting_1">
                                    <input type="text" class="form-control" value="{{ number.question_title }}" disabled>  

                                    <!-- <span>{{ number.question_title }}</span> -->
                                 </td>
                                 <td class="Numberbox">
                                    <input type="number" name="" class="Numberbox" value="{{ number.total }}" min="1" max="5" disabled>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               <!-- </form> -->
            </div>
         </div>

      

       <div style="margin-top: 20px;" class="col-md-12">
              <div class="card">
                <div class="header">
                   <h2> Force Field Analysis </h2>
                </div>
               <div class="upgrade-section-header">
                  <h4>Force "FOR" Change</h4>
                  <h4>Force "AGAINST" Change</h4>
                </div>

                <div class="body">
                  <div class="upgrade-section">
                    <div class="upgrade-section__container">
                      <div class="upgrade-section__inner-wrap">
                        <div class="upgrade-section__grid">
                          <div class="upgrade-section__grid-left">
                            <span class="upgrade-section__grid-col">5</span>
                            <span class="upgrade-section__grid-col">4</span>
                            <span class="upgrade-section__grid-col">3</span>
                            <span class="upgrade-section__grid-col">2</span>
                            <span class="upgrade-section__grid-col">1</span>
                          </div>
                          <div class="upgrade-section__spacer">
                            <span class="upgrade-section__spacer-item">{{AnysisName}}</span>
                          </div>
                          <div class="upgrade-section__grid-right">
                            <span class="upgrade-section__grid-col">1</span>
                            <span class="upgrade-section__grid-col">2</span>
                            <span class="upgrade-section__grid-col">3</span>
                            <span class="upgrade-section__grid-col">4</span>
                            <span class="upgrade-section__grid-col">5</span>
                          </div>
                        </div>
              
                        <div class="upgrade-section__items">
                          <div class="upgrade-section__items-left">
                            <span *ngFor="let force of forceData" class="upgrade-section__items-col arrow-{{force.total}}" data-point="{{force.total}}">{{force.question_title}}</span>
                          </div>
                          <div class="upgrade-section__spacer"></div>
                          <div class="upgrade-section__items-right">
                            <span  *ngFor="let forceAgainst of forceAgainstData" class="upgrade-section__items-col arrow-{{forceAgainst.total}}" data-point="{{forceAgainst.total}}">{{forceAgainst.question_title}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="upgrade-section-footer">
                    <button class="button button-green">Total Score: {{forceDataCount}}</button>
                    <button class="button button-pink">Total Score: {{forceAgainstDataCount}}</button>
                  </div>
              </div>
              </div>

        </div>

      </div>
   </div>
</div>