
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { saveAs } from 'file-saver'; // Install file-saver library: npm install file-saver

@Injectable({
  providedIn: 'root'
})
export class FilesaverService {

  constructor(private http: HttpClient) { }

  downloadFile(url: string, fileName: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      // saveAs(blob, fileName);
    });
  }
}
