import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DealroomService } from 'src/app/services/dealroom.service';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.css']
})
export class PostViewComponent implements OnInit {

  postId:any;
  post:any = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private roomService: DealroomService) { }

  ngOnInit(): void {
    this.postId = this.route.snapshot.params['id'];
    this.roomService.getPostByPostId(this.postId).subscribe((res:any)=>{
      if(res.status) {
        this.post = res.data;
      } else {
        this.toastr.error("Room not found", "Error");
      }
  }, err=> {
    this.toastr.error(err, "Error");
  })
  }

}
