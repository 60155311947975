
<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="container">
    <div class="coming-soon">
         <div class="w-100 body justify-content-center">
            <h1 class="text-uppercase mb-3">Coming soon!</h1>
            <div class="w-100 mb-5 homeButton">
              <a [routerLink]="['/dashboard']" class="btn btn-primary text-uppercase">
                 <i class="icon-home"></i>
               <span class="align-middle">Back to homepage</span>
               </a>
            </div>
         </div>
      </div>
   </div>