import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import{ GlobalConstants } from '../../common/global-constants';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.css']
})

export class EventAddComponent implements OnInit {

  EventForm: FormGroup;
  public loading: boolean;
  event_date_modal;

  currentDate : Date =new Date();

  constructor(private formBuilder: FormBuilder, private eventService: EventService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.EventForm = this.formBuilder.group({
        title: ['', [Validators.required]],
        event_date: ['', [Validators.required]],
        event_time: ['', [Validators.required]],
        meeting_link: [''],
        file_name: [''],
    })
  }

  get control() {
    return this.EventForm.controls;
  }

  submit() {
    if(this.EventForm.invalid) {
      return;
    }
    this.loading = true;

    const formData = new FormData();
    formData.append('title', this.EventForm.get('title').value);
    formData.append('event_date', this.EventForm.get('event_date').value);
    formData.append('event_time', this.EventForm.get('event_time').value);
    formData.append('meeting_link', this.EventForm.get('meeting_link').value);
    formData.append('file_name', this.EventForm.get('file_name').value);

    this.eventService.saveEvent(formData).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Event add successfully', "Success");
        this.router.navigateByUrl('event-list');
        this.EventForm.reset();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  isFileAdd = false;
  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
          this.toastr.error("Allow only 20 mb file size", "Error");
          return;
      }
      this.control.file_name.setValue(file);
    }
  }

  backClicked() {
    this._location.back();
  }

}
