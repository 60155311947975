import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

    rate = 0;
    rateCount: BehaviorSubject<number>;
  
  

  constructor() { 
      this.rateCount = new BehaviorSubject(this.rate);
  }

  changeMessage(message) {
    var user = JSON.parse(localStorage.getItem('user'));
    user.rate = message;
    localStorage.setItem('user', JSON.stringify(user));
    this.rateCount.next(message);
  }

}
