<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item" *ngIf="blogurl">Blogs</li>
                        <li class="breadcrumb-item" *ngIf="!blogurl">Videos</li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <div id="navbar-search" class="search-form serach-section">
                        <input class="form-control"  placeholder="Search here..." #filter_input type="text" (keyup)="filter_post(filter_input.value)">
                        <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-list">
            <div class="row align-items-start">
                <div class="col-lg-8 m-15px-tb" *ngIf="latestBlog != undefined">
                    <article class="article">
                        <div class="row align-items-start">
                            <div class="col-lg-12 m-15px-tb">
                                <div class="article-img">
                                    <ng-container *ngIf="latestBlog.type == 'Blog'">
                                        <img src="{{latestBlog.image_url}}" alt="blog-image">
                                    </ng-container>
                                    <ng-container *ngIf="latestBlog.type == 'Video' && latestBlog.video_url != ''">
                                        <iframe frameborder="0" [src]="sanitizUrl(latestBlog.video_url)"></iframe>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-12 m-15px-tb">
                                <div class="article-title">
                                    <h2>{{latestBlog.title}}
                                    </h2>
                                    <h6>{{latestBlog.created_at}}</h6>
                                    <div class="article-content">
                                        <p [innerHTML]="description(latestBlog.description,60)"></p>
                                        <a class="readMore_btn" (click)="readMore(latestBlog.id,latestBlog.type)">Read
                                            More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="col-lg-4 m-15px-tb" *ngIf="latestBlog != undefined">
                    <article class="article">
                    <div class="header">
                        <h5>All Posts</h5>
                    </div>
                    <div class="blogList">
                        <ul class="list-unstyled m-b-0" *ngIf="allPost != undefined">
                            <li *ngIf="latestBlog != undefined"><a href="javascript:void(0);" (click)="readMore(latestBlog.id,latestBlog.type)">{{latestBlog.title}}</a></li>
                            <li *ngFor="let single of allPost;"><a href="javascript:void(0);" (click)="readMore(single.id,single.type)">{{single.title}}</a></li>
                           </ul>
                      </div>
                    </article>
            </div>
                 <!-- not found blog start -->
                        <!-- <div class="notfoundevents" *ngIf="!videoAndBlogList?.length">
                            <h6>No Video Found</h6>
                        </div> -->
        </div>
                <!-- Recent blog -->
                <!-- blog col -->

                <ng-template [ngIf]="videoAndBlogList != undefined && videoAndBlogList.length!=0">
                    <div class="row mt-4">
                    <div class="col-lg-4 m-15px-tb blog-aside" *ngFor="let single of videoAndBlogList">
                        <article class="article">
                            <div class="article-img">
                                <ng-container *ngIf="single.type == 'Blog'">
                                    <img src="{{single.image_url}}" alt="blog-image">
                                </ng-container>
                                <ng-container *ngIf="single.type == 'Video' && single.video_url != ''">
                                    <iframe width="100%" height="300" frameborder="0" [src]="sanitizUrl(single.video_url)"></iframe>
                                </ng-container>
                            </div>
                            <div class="article-title">
                                <h2>{{single.title}}</h2>
                                <h6>{{single.created_at}}</h6>
                                <div class="article-content">
                                    <p [innerHTML]="description(single.description)"></p>
                                    <a class="readMore_btn" (click)="readMore(single.id,single.type)">Read More</a>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

          <!-- Pagination -->
<div class="row mt-5">
    <div class="col-12">
      <ul class="pagination justify-content-center" *ngIf="totalPage.length > 0">
        <!-- Previous Button -->
        <li class="page-item" [ngClass]="{ 'disabled': activePage === 0 }">
          <a class="page-link" href="javascript:;" (click)="changePage(activePage - 1)" tabindex="-1">
            <i class="icon-arrow-left"></i>
          </a>
        </li>
  
        <!-- Dots and Pages Logic -->
        <li class="page-item" *ngFor="let page of getDisplayedPages()" [ngClass]="{ 'active': page === activePage, 'disabled': page === '...' }">
          <a class="page-link" href="javascript:;" *ngIf="page !== '...'" (click)="changePage(page)">
            {{ page + 1 }}
          </a>
          <span class="page-link" *ngIf="page === '...'">
            ...
          </span>
        </li>
  
        <!-- Next Button -->
        <li class="page-item" [ngClass]="{ 'disabled': activePage === (totalPage.length - 1) }">
          <a class="page-link" href="javascript:;" (click)="changePage(activePage + 1)">
            <i class="icon-arrow-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
  
  
                </ng-template>
        </div>
    </div>
</div>