<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">

        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <!-- <h2>Opportunity Analysis (Risk Vs Benefit)
                    </h2> -->
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/tools']">Tools</a></li>
                        <li class="breadcrumb-item">Opportunity Analysis (Risk Vs Benefit)</li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- <form [formGroup]="questionForm" enctype="multipart/form-data"> -->
        <section id="htmlData">
            <div class="proej-char-tbl">
                <div class="row">
                    <div id="myHeader" class=" col-md-12 mb-3 pro-head ">

                        <div class="row">
                           <div class="col-md-5 tool-extra-style">
                            <h3 class="tool-extra tool-head-space">
                                Benefit Analysis
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                <!-- <button type="button" class="btn btn-danger" (click)="submit()"> Generate</button> -->
                                <!-- <button type="button" class="btn pbtn" (click)="openPDF()">Export PDF</button> -->
                            </h3>
                           </div>
      
                           <div class="col-md-7 RightButtons">
                              <div style="float: right;" class="m-r-5 Export">
                                <button type="button" class="btn pbtn" (click)="openPDF()">Export PDF</button>
                          
                              </div>
      
                           </div>
                        </div>
                        </div>
                    <div class="col-md-12">
                       <div class="AnalysisText">
                          <h5>Name of Analysis </h5>
                          <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                          </button> -->
                          <input type="text" placeholder="Add your report name" class=" Upgradebtn form-control" >

                       </div>
                    </div>
                    </div>
                <div class="row">
                    <!-- <div class="pro-head col-md-12 tool-extra-style">
                        <h3>
                            Benefit Analysis
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <button type="button" class="btn btn-danger" (click)="submit()"> Generate</button>
                            <button type="button" class="btn pbtn" (click)="openPDF()">Export PDF</button>
                        </h3>
                    </div> -->
                    <div class="col-md-12 clearfix">
            
                        <!-- <input type="hidden" formControlName="tools_id"> -->
                        <table class="table table-striped table-bordered pro-tbl table-responsive">
                            <thead>
                                <tr>
                                    <th>Benefits of Pursuing the Opputunity</th>
                                    <th style="text-align:center">Probability of Occurence</th>
                                    <th style="text-align:center">Impact on Project</th>
                                    <th style="text-align:center">Total Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div class="mn-divpro">
                                            <span>HiGH</span>
                                            <span>MEDIUM</span>
                                            <span>LOW</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="mn-divpro">
                                            <span>HiGH</span>
                                            <span>MEDIUM</span>
                                            <span>LOW</span>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>

                                <tr *ngFor="let number of user_tool_data| slice:0:7;let i = index;">
                                    <td>
                                        <span *ngIf="number.tool_question!=null">{{ number.tool_question.question_title }}</span>
                                        <span *ngIf="number.tool_question==null">-</span>
                                    </td>
                                    <td>
                                        <div class="rd-pro">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_{{i+1}}" value="10"
                                                    [checked]="number.profability_score==10" [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_{{i+1}}" value="5"
                                                    [checked]="number.profability_score==5" [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_{{i+1}}" value="2"
                                                    [checked]="number.profability_score==2" [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="rd-pro">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_2_{{i+1}}" value="10"
                                                    [checked]="number.impact_score==10"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_2_{{i+1}}" value="5"
                                                    [checked]="number.impact_score==5"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_2_{{i+1}}" value="2"
                                                    [checked]="number.impact_score==2"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="text-align: center;">{{number.total}}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Scores of prabability and impact</b>
                                    </td>
                                    <td>
                                        <p class="pnk-p">{{data.opp_pro_score}}</p>
                                        <input class="" type="hidden" name="opportunity_profibility_score"
                                            value="0">
                                    </td>
                                    <td>
                                        <p class="pnk-p">{{data.opp_imp_score}}</p>
                                        <input class="" type="hidden" name="opportunity_impact_score"
                                            value="0">
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3" align="right">
                                        <b class="ttl-scre">Total Score Of Benifits</b>
                                    </td>
                                    <td>
                                        <div class="btn-green">
                                            {{total_opp}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="proej-char-tbl">
                <div class="row">
                    <div class="pro-head col-md-12 m-l-10">
                        <h3>
                            Risk Analysis
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </h3>
                    </div>
                    <div class="col-md-12 clearfix">
                        <table class="table table-striped table-bordered pro-tbl table-responsive top-border">
                            <thead>
                                <tr>
                                    <th>Risk-Cost, Schedule, or Performance problems in these areas</th>
                                    <th style="text-align:center">Probability of Occurence</th>
                                    <th style="text-align:center">Impact on Project</th>
                                    <th style="text-align:center">Total Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div class="mn-divpro">
                                            <span>HiGH</span>
                                            <span>MEDIUM</span>
                                            <span>LOW</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="mn-divpro">
                                            <span>HiGH</span>
                                            <span>MEDIUM</span>
                                            <span>LOW</span>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let number of user_tool_data| slice:7:13;let i = index;">
                                    <td>
                                        <span *ngIf="number.tool_question!=null">{{ number.tool_question.question_title }}</span>
                                        <span *ngIf="number.tool_question==null">-</span>
                                    </td>
                                    <td>
                                        <div class="rd-pro">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_3_{{i}}" value="10"
                                                    [checked]="number.profability_score==10"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_3_{{i}}" value="5"
                                                    [checked]="number.profability_score==5"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_3_{{i}}" value="2"
                                                    [checked]="number.profability_score==2"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="rd-pro">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_4_{{i}}" value="10"
                                                    [checked]="number.impact_score==10"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_4_{{i}}" value="5"
                                                    [checked]="number.impact_score==5"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio"
                                                    name="question_4_{{i}}" value="2"
                                                    [checked]="number.impact_score==2"
                                                    [disabled]="true">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="text-align: center;">{{number.total}}</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Scores of prabability and impact</b>
                                    </td>
                                    <td>
                                        <p class="pnk-p">{{data.risk_pro_score}}</p>
                                    </td>
                                    <td>
                                        <p class="pnk-p">{{data.risk_imp_score}}</p>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3" align="right">
                                        <b class="ttl-scre">Total Score Of Benifits</b>
                                    </td>
                                    <td>
                                        <div class="btn-yellow">
                                            {{total_risk}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        <!-- </form> -->
        <div id="watermark">
            <p>VTTouch</p>
        </div>
        <div class="risk-befits mb-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="versis-bnf table-bordered top-border">
                        <div class="pro-head col-md-12">
                            <h3 class="mb-5">
                                Risk Versus Benefit Decision Scale
                            </h3>

                            <div class="Scale__inner-wrap">
                                <div class="Scale__grid">
                                   <div class="Scale__grid-left">
                                      <p class="Benefit">Benefit Score</p>
                                      <span class="Scale__grid-col">
                                         <p class="count1">100</p>
                                         <p class="count2">0</p>
                                      </span>
                                      <span class="Scale__items-col arrow-1" [ngStyle]="{'top': 'calc(100% - '+total_opp+'%)'  }" data-point="40"><span class="opp_final_total"><p>{{total_opp}}</p></span> <img src="./assets/assets/images/benefit-arrow.png" alt="Lucid" class="img-fluid"></span>
                                   </div>
                                   <div class="Scale__spacer">
                                      <div class="Scale__spacer-item">
                                         <div class="box Green-section-name">
                                            <h6>High Benefit/Low Risk</h6>
                                            <p>Go for It!</p>
                                         </div>
                                         <div class="box Yellow-section-name">
                                            <h6>Medium Benefit/Medium Risk</h6>
                                            <p>Further Studies Required</p>
                                         </div>
                                         <div class="box Red-section-name">
                                            <h6>Low Benefit/High Risk</h6>
                                            <p>Avoid this Opportunity</p>
                                         </div>
                                      </div>
                                   </div>
                                   <div class="Scale__grid-right">
                                      <p class="Risk">Risk Score</p>
                                      <span class="Scale__grid-col">
                                         <p class="count1">0</p>
                                         <p class="count2">100</p>
                                      </span>
                                      <span class="Scale__items-col arrow-1"  [ngStyle]="{'top': 'calc('+total_risk+'%)'  }" data-point="1"><span class="risk_final_total"><p>{{total_risk}}</p></span>  <img src="./assets/assets/images/risk-arrow.png" alt="Lucid" class="img-fluid"></span>
                                   </div>
                                </div>
                               
                             </div>
                         
                            <div>Opportunity Score : {{total_opp}}</div>
                            <div>Risk Score : {{total_risk}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </section>

    </div>
</div>
