import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningService } from 'src/app/services/learning.service';
import { TrainingService } from 'src/app/services/training.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilesaverService } from 'src/app/services/filesaver.service';
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  CreateForm: FormGroup;
  trainingCourseList: any = [];
  allTrainingCourseList: any = [];
  trainingVideoList: any = [];
  allTrainingVideoList: any = [];
  slug: any;
  category_name: any = 'Programmes';
  cate_slug: any;
  //free trial
  isFreeTrial: boolean = false;
  nextBillingDate = new Date();
  CurrentPlan: any;
  planData: any;
  CurrentPlanID = 0;
  closeResult: string;
  modalReference: NgbModalRef;
  path = GlobalConstants.ImagePATH;
  safeSrc: SafeResourceUrl;
  selectedRow: any;
  searchText: string;
  value = { training_doc: 'A_Directors_introduction_course_53.pdf' }; // Example value, replace with your actual object

  constructor(
    private fileService: FilesaverService,
    private learnService: LearningService,
    private trainingService: TrainingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.slug = router.url.split('/').pop();;
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        if (res.current_active_plan.plan_id == 1 || res.current_active_plan.plan_id == 1) {
          this.isFreeTrial = true
        }
      } else {
        this.isFreeTrial = false
      }
      this.getCurrentPlan()
      this.getAllPlanList()
    });
    this.CreateForm = this.formBuilder.group({
      message: ['', [Validators.required]],
    })
  }


  downloadPdf(url:string, name:string) {
    const path = url;
    const fileName = name;
    this.fileService.downloadFile(path, fileName);
  }

  getSafeImage(imgSrc: any) {
    return this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(imgSrc);

  }
  ngOnInit(): void {
    this.cate_slug = this.slug;
    ///get all Course
    this.getAllTrainingCourseByType(1);
    this.getAllTrainingVideoByType(2)
    ///get all video

  }


  
  getAllTrainingCourseByType(id: any) {
    this.trainingService.getAllTrainingByType({ "type": id }).subscribe((res: any) => {
      if (res.status) {
        this.trainingCourseList = res.data;
        this.allTrainingCourseList = res.data;
        //console.log("courseList--", this.trainingCourseList)
      } else {
        this.trainingCourseList = []
        this.allTrainingCourseList = []
        // this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.trainingCourseList = []
        this.allTrainingCourseList = []
        // this.toastr.error(err, "Error");
      });
  }
  getAllTrainingVideoByType(id: any) {
    this.trainingService.getAllTrainingByType({ "type": id }).subscribe((res: any) => {
      if (res.status) {
        this.trainingVideoList = res.data;
        this.allTrainingVideoList = res.data;
        //console.log("videoList--", this.trainingVideoList)
      } else {
        this.trainingVideoList = []
        this.allTrainingVideoList = []
        // this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.trainingVideoList = []
        this.allTrainingVideoList = []
        // this.toastr.error(err, "Error");
      });
  }

  get control() {
    return this.CreateForm.controls;
  }
  submit() {
    if (this.CreateForm.invalid) {
      return;
    }
    let userData = JSON.parse(localStorage.getItem('user'));
    //let login_email = userDate.email
    //console.log(userData.email)
    const formData = new FormData();
    formData.append('message', this.CreateForm.get('message').value);
    formData.append('email', userData.email);
    this.trainingService.trainingQuestion(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Message sent successfully', "Success");
        //this.router.navigateByUrl('admin/training-list');
        this.visible = false
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  getCurrentPlan() {
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        console.log(res, 'current plan ')
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }
        //console.log(this.CurrentPlan)
        // this.router.navigateByUrl('dashboard');
      } else {
       // this.router.navigateByUrl('OurPlan');

        //alert(res.message);
      }

    }, err => {
      //this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
        //console.log(this.planData)
      }
    })
  }

  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

  //hiding info box
  visible: boolean = false
  onclick() {
    this.visible = !this.visible
  }

  setSelectedRow(row: any) {
    this.selectedRow = row;
    //console.log(this.selectedRow)
    document.getElementById("Training_Video").classList.add('show');
    document.getElementById("Training_Video").style.display = 'block';
    //$('#Training_Video').addClass('show');
  }
  closeModel() {
    document.getElementById("Training_Video").classList.remove('show');
    document.getElementById("Training_Video").style.display = 'none';
  }

  filter_records() {
    console.log(this.searchText)
    this.getAllPostBySearch();
  }
  getAllPostBySearch() {
    if (this.searchText == null || this.searchText == '') {
      this.trainingCourseList = [];
      this.allTrainingCourseList = [];
      this.trainingVideoList = [];
      this.allTrainingVideoList = [];
      this.getAllTrainingCourseByType(1)
      this.getAllTrainingVideoByType(2)
    } else {
      const postData = this.allTrainingCourseList.filter(item => item.title.toLowerCase().includes(this.searchText.toLowerCase()));
      this.trainingCourseList = postData;
      let isFound = false;
      if(postData != undefined && postData != null && postData.length > 0 ){
        isFound = true;
      }
      const postData1 = this.allTrainingVideoList.filter(item => item.title.toLowerCase().includes(this.searchText.toLowerCase()));
      //console.log(postData)
      this.trainingVideoList = postData1;
      if(postData == undefined || postData == null || postData.length == 0 ){
        isFound = isFound?true:false;
      }
      if(!isFound){
        this.toastr.error('No data found.');
      }
      //this.boxDisplay = this.posts.map(s => false);

    }

  }
}
