<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
            <li class="breadcrumb-item">Partners</li>
          </ul>
        </div>
      </div>
    </div>
    <section class="topbackground" style="background-image: url(./assets/assets/images/auth_bg.png); ">
      <div class="row banner-con text-center">
        <div class="col-12 col-md-12">
          <h2>Our <span>Partners</span></h2>
        </div>
        <!-- Banner Text -->
      </div>
    </section>
    <section id="OurPartners" class="OurPartners" *ngIf="partnerList.length!=0">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-4 mb-3 d-flex align-items-stretch" *ngFor="let singleList of partnerList; let i = index">
            <div class="card">
              <div class="card-body d-flex flex-column OurPartners-item">
                <div class="flip-box__front" style="background-image: url({{singleList.icon}}); ">
                  <div class="flip-box__layer__overlay">
                  </div>
                </div>
                <div class="flip-box__back" style="background-image: url({{singleList.hover_icon}}); ">
                  <div class="flip-box__layer__overlay">
                    <h3>{{singleList.title}}</h3>
                    <a (click)="setSelectedRow(singleList)">Read More</a>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>


<!-- Modal partnersModal1-->
<div class="modal fade partnersModal" id="partnersModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="selectedRow">
      <div class="modal-header">
        <h5 class="modal-title">
          <img src="{{selectedRow.icon}}" alt="icon">
        </h5>
        <button type="button" (click)="closeModel()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{selectedRow.description}}
        </p>
      </div>
      <div class="modal-footer">
        <a href="{{selectedRow.visit_link}}" target="_blank" class="btn Visit_btn">VISIT</a>
      </div>
    </div>
  </div>
</div>
<!-- end Modal partnersModal1 -->