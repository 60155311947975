<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <!-- <div class="block-header">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <h2><a href="javascript:void(0);" class="btn btn-xs btn-link" (click)="backClicked()">
                            <i class="fa fa-arrow-left"></i>
                        </a> 
                        <span *ngIf="isAddMode">Add Blog Room</span>
                        <span *ngIf="!isAddMode">Edit Blog Room</span>
                    </h2>
                </div>
            </div>
        </div> -->
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Dealroom</li>
                        <li class="breadcrumb-item" *ngIf="isAddMode"><a routerLink="/dashboard" >Add Blog Room</a></li>
                        <li class="breadcrumb-item" *ngIf="!isAddMode"><a routerLink="/dashboard">Edit Blog Room</a></li>
                      </ul>
            
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-6">
                <div class="card">
                    <div class="header text-center">
                        <h2 *ngIf="isAddMode">Add Blog Room</h2>
                        <h2 *ngIf="!isAddMode">Edit Blog Room</h2>
                    </div>
                    <div class="body">
                        <form [formGroup]="roomForm" enctype="multipart/form-data">
                            <div class="form-group">
                                <label>Blog Room Name <span>*</span></label>
                                <input class="form-control" type="text" formControlName="room_name" />
                                <span class="text-danger"
                                    *ngIf="control.room_name.dirty && control.room_name.hasError('required')">
                                    Blog Room name field is requied.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Description<span>*</span></label>
                                <textarea formControlName="description" class="form-control"></textarea>
                                <span class="text-danger"
                                    *ngIf="control.description.dirty && control.description.hasError('required')">
                                    Description field is requied.
                                </span>
                            </div>
                            <input class="form-control" type="hidden" formControlName="id" />
                            <button type="submit" class="btn btn-primary" (click)="submit()"
                                [disabled]="!roomForm.valid">
                                Save
                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                            </button>
                            <a routerLink="/admin/dealroom" class="btn btn-danger m-l-5">Cancel</a>
                        </form>
                    </div>
                </div>
            </div>

        </div>


    </div>
</div>