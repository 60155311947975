<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <h2><a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth"><i
                                class="fa fa-arrow-left"></i></a> View Profile</h2>
                    <!--<ul class="breadcrumb">
                         <li class="breadcrumb-item"><a href="index.html"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item active">View Profile</li>
                    </ul>-->
                </div>

            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>View Profile</h2>
                    </div>
                    <div class="body">

                        <form [formGroup]="viewForm" enctype="multipart/form-data">
                            <div class="form-group col-lg-6 col-md-12">
                                <label>Email</label>
                                <p>{{user.email}}</p>
                            </div>
                            <div class="form-group col-lg-6 col-md-12">
                                <label>Name</label>
                                <p>{{user.name}}</p>
                            </div>
                            <div class="form-group col-lg-6 col-md-12">
                                <label>Profile Image</label>
                                <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                                    <img [src]="imageURL" [alt]="user.name" width="100" height="100">
                                </div>
                            </div>

                            <input class="form-control" type="hidden" formControlName="id" [(ngModel)]="user.id" />
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
