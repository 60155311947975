import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.css']
})
export class TrainingListComponent implements OnInit {

  trainingList;
  public loading: boolean;
  constructor(private trainingService: TrainingService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.getAllTraining();
  }
  getAllTraining() {
    this.trainingService.getAll().subscribe((res: any) => {
      if (res.status) {

        this.trainingList = res.data;
        //console.log("training-----", this.trainingList)
      } else {
        this.toastr.error(res.message, "Error");
      }
    },
      err => {
        this.toastr.error(err, "Error");
      });
  }

  editTraining(id) {
    this.router.navigate(['/admin/programme-edit/' + id]);
  }

  deleteTraining(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const formData = new FormData();
        formData.append('training_id', id);
        this.trainingService.delete(formData).subscribe((res: any) => {
          this.loading = false;
          if (res.status) {
            this.toastr.success('Data deleted successfully', "Success");
            this.getAllTraining();
          } else {
            this.toastr.error(res.message, "Error");
          }
        }, err => {
          this.toastr.error(err, "Error");
        })
      }
    })

  }

  backClicked() {
    this._location.back();
  }
}
