import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
declare var $: any;
import { EventService } from 'src/app/services/event.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CalendarOptions, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  userDisplayName = '';
  roleName = null;
  roleId = null;
  event:any = [];
  events:any = [];
  posts:any = [];
  comments:any = [];
  rEvents:any = [];
  toolData:any = [];
  enableEventRegistration:boolean = true;
  isFreeTrial:boolean = false;
  closeResult: string;
  CurrentPlan:any;
  planData:any;
  CurrentPlanID=0;
  nextBillingDate=new Date();

  modalReference: NgbModalRef;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'timeGridDay,dayGridMonth,timeGridWeek ',
      right: ' prev,title,next ',
    },
    dayMaxEvents: true,
    // allow "more" link when too many events
    //dayMaxEventRows: true
  };

  constructor(private authService: AuthService,
    private router: Router,
    private eventService: EventService,
    private toastr: ToastrService,
    private roomService: DealroomService,
    private modalService: NgbModal) {
      
     }

  ngOnInit(): void {

    if(this.authService.isLogedin) {
      if(this.authService.user){
        this.userDisplayName = this.authService.user.name;
        this.roleName = this.authService.user.role_name;
        this.roleId = this.authService.user.user_role;
      }
    }

    this.eventService.getLatestEvent().subscribe((data: any)=>{
      if(data.status){
        this.event = data.event;
      }else{
        // this.toastr.error(data.message, "Error");
      }
    });

    this.eventService.getAllEventList().subscribe((res: any)=>{
      if(res.status){
        this.events = res.data;
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          headerToolbar: {
            left: 'timeGridDay,dayGridMonth,timeGridWeek',
            right: 'prev,title,next',
          },
          dayMaxEvents: true,
          dateClick: this.onDateClick.bind(this),
          eventClick: this.handleEventClick.bind(this),
          events: this.events,
        };
        this.resizeDisplay();
      }else{
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          headerToolbar: {
            left: 'timeGridDay,dayGridMonth,timeGridWeek',
            right: 'title',
          },
          dayMaxEvents: true,
          //dateClick: this.onDateClick.bind(this),
          events: []
        };
        this.resizeDisplay();
        // this.toastr.error(res.message, "Error");
      }
    },
    err=> {
      this.events = [];
      // this.toastr.error(err, "Error");
    });

    this.roomService.getLatest3Post().subscribe((res: any)=>{
      if(res.status){
        this.posts = res.data;
      }else{
        // this.toastr.error(res.message, "Error");
      }
    },
    err=> {
      //this.toastr.error(err, "Error");
    });

    this.roomService.getLatest3Comment().subscribe((res: any)=>{
      if(res.status){
        this.comments = res.data;
      }else{
        // this.toastr.error(res.message, "Error");
      }
    },
    err=> {
      //this.toastr.error(err, "Error");
    });
    this.eventService.getLatest3Event().subscribe((res: any)=>{
      if(res.status){
        this.rEvents = res.data;
      }else{
        // this.toastr.error(res.message, "Error");
      }
    },
    err=> {
      //this.toastr.error(err, "Error");
    });

    this.authService.getAllTool().subscribe((res:any)=>{
      if(res.status) {
        this.toolData = res.tools;
      }
    },
    err=> {
      //this.toastr.error(err, "Error");
    })

    setTimeout( function() {
        if($('.calendar-body').find('.event-closed').length>0){
          var eventDatas = $('.calendar-body').find('.event-closed');
          eventDatas.each( function( key, value ) {
            $(this).closest('td').addClass('td-event-closed');
          });
        }
        if($('.calendar-body').find('.event-upcoming').length>0){
          var eventDatasUp = $('.calendar-body').find('.event-upcoming');
          eventDatasUp.each( function( key, value ) {
            $(this).closest('td').addClass('td-event-upcoming');
          });
        }
        if($('.calendar-body').find('.event-registerd').length>0){
          var eventDatasReg = $('.calendar-body').find('.event-registerd');
          eventDatasReg.each( function( key, value ) {
            $(this).closest('td').addClass('td-event-registerd');
          });
        }
    }, 1500)

    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        if(res.current_active_plan.plan_id == 1){
            this.isFreeTrial = true
        }
        console.log(res,'response --------');
      } else {

      }
    })

    this.getCurrentPlan()
    this.getAllPlanList()

  }

  getCurrentPlan(){
    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        console.log(res,'current plan ')
        if(res.current_active_plan.plan_id == 1){
          this.isFreeTrial = true
        }
        if (res.current_active_plan.plan_id == 1) {
          this.enableEventRegistration = false;
        }
        this.CurrentPlanID=res.current_active_plan.plan_id
          this.CurrentPlan = res.current_active_plan
          this.nextBillingDate = new Date();
            if(this.CurrentPlan.plan.duration_type == 'Days'){
              this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
            }else if(this.CurrentPlan.plan.duration_type == 'Years'){
              this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
            }
          console.log(this.CurrentPlan)
          // this.router.navigateByUrl('dashboard');
      } else {
        // if (this.roleId != 1) {
        //   this.router.navigateByUrl('OurPlan');
        // }
      }

    }, err=> {
      // if (this.roleId != 1) {
      //   this.router.navigateByUrl('OurPlan');
      // }
    })
  }

  getAllPlanList(){
    this.authService.getAllPlanList({}).subscribe((res:any)=>{
      if(res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }

  resizeDisplay(){
    // setTimeout( function() {
    //   window.dispatchEvent(new Event('resize'))
    // }, 1000)
  }

  OpenPlanPopup(modalId){
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

  register(id){
    Swal.fire({
      title: 'Are you sure want to Register?',
      //text: 'You will not be able to recover this!',
 // icon: 'warning',
 iconHtml: ' <img src="./assets/assets/images/event-icon.png" alt="event-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.eventService.register(id).subscribe((data: any)=>{
        if(data.status){
            this.event.users = data.event.users;
            this.toastr.success("Event Register successfully", "success");
        }else{
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('dashboard');
        }
        });
      }
    })
  }

  cancelRegistraion(id){
    Swal.fire({
      title: 'Are you sure want to Cancel Register?',
      //text: 'You will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/event-icon.png" alt="event-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.eventService.cancelRegistraion(id).subscribe((data: any)=>{
          if(data.status){
            this.toastr.success("Event Register successfully", "success");
            this.event.users = [];
            /* let itemIndex = this.events.findIndex(item => item.id == id);
            this.events[itemIndex].users = []; */
          }else{
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('dashboard');
          }
        });
      }
    })
  }

  onDateClick(arg) {
    console.log(arg);
  }

  handleEventClick(arg){
    this.router.navigateByUrl('vt-events');
  }

  gotomaster(id, i){
    // if(i==1){
    //   this.router.navigateByUrl('/project-tool/'+id);
    // }else{
    //   this.router.navigateByUrl('/mastertools/'+id);
    // }

    if(i==1){
      this.router.navigateByUrl('/mastertools/'+id);
    }else if(i==2){
      this.router.navigateByUrl('/project-tool/'+id);
    }else if(i==3){
      this.router.navigateByUrl('/tools/tool'+i+'/'+id);
    }else if(i==4){
      this.router.navigateByUrl('/tools/tool'+i+'/'+id);
    }else{
      this.toastr.error("something wrong", "Error");
    }
  }

  viewReport(id, i){
    if(i==1){
        this.router.navigateByUrl('project-tool-view/'+id);
      }else{
        this.router.navigateByUrl('viewtool/'+id);
      }
  }

  DeleteReport(id){
    Swal.fire({
      title: 'Are you sure want to delete this report?',
      text: 'Once you delete it, you will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/delete-icon.png" alt="delete-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, save it!'
      }).then((result) => {
          if (result.value) {
              this.authService.deleteUserTool(id).subscribe((res:any)=>{
                  if(res.status) {
                    this.toolData[0].user_tools = this.toolData[0].user_tools.filter(item => item.id !== id);
                    this.toolData[1].user_tools = this.toolData[1].user_tools.filter(item => item.id !== id);
                    this.toastr.success('User report deleted successfully', "Success");
                  } else {
                    this.toastr.error("User report delete error", "Error");
                  }    //this.router.navigateByUrl('tools');
              })
          }
    })
  }

}
