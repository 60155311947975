import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  messageList = [];
  notList = [];
  msgCount = 0;
  notCount = 0;

  isPaginationShow = false;
  page = 1;
  isScrollStop = false;

  pageMsg = 1;
  isScrollStopMsg = false;

  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService, 
     private messageService: MessageService) { }

  ngOnInit(): void {

    /* $('.button-left').click(function() {
        $('.sidebar').toggleClass('fliph');
        $('.sidebar').toggleClass('menuclicked');
        $('.sidebar').addClass('click2');
        
        $("body").hasClass("layout-fullwidth") ? $("body").removeClass("layout-fullwidth") : $("body").addClass("layout-fullwidth"), $(this).find(".fa").toggleClass("fa-arrow-left fa-arrow-right")

        if(!$(this).hasClass('fliph')){
            $('.list-sidebar li a').removeClass('active');
            $('.sub-menu li').removeClass('active');
            $('.sub-menu').removeClass('show');
        }
    }); */

   /*  $(".sbbar").hover(function() {
        if($(this).hasClass('fliph') && $(this).hasClass('menuclicked')){
            $(this).removeClass('fliph');
            $('.list-sidebar li a').removeClass('active');
            $('.sub-menu li').removeClass('active');
            $('.sub-menu').removeClass('show'); 
            
            $("body").hasClass("layout-fullwidth") ? $("body").removeClass("layout-fullwidth") : $("body").addClass("layout-fullwidth")
        }
    }, function() {
        if(!$(this).hasClass('fliph') && $(this).hasClass('menuclicked')){
            $(this).addClass('fliph');
            $('.list-sidebar li a').removeClass('active');
            $('.sub-menu li').removeClass('active');
            $('.sub-menu').removeClass('show'); 
            $("body").hasClass("layout-fullwidth") ? $("body").removeClass("layout-fullwidth") : $("body").addClass("layout-fullwidth")
        }
    }); */

    // this.messageService.getAll().subscribe((res: any)=>{
    //   if(res.status){
    //     this.messageList = res.data;
    //   }else{
    //     //this.toastr.error(data.message, "Error");
    //   }
    // });

    this.getUnreadMessage(1);
    this.getUnreadNotification(1);

    // if (this.messageService.subsVar==undefined) {  
    //   alert(1)  
    //   this.messageService.subsVar = this.messageService.    
    //   invokeFirstComponentFunction.subscribe(() => {    
    //     this.getUnreadMessage();    
    //   });    
    // } 
    // this.messageService.    
    //   invokeFirstComponentFunction.subscribe(() => {    
    //     this.getUnreadMessage();    
    // });
  }

  onScrollMsg(e) {
    if(this.isScrollStopMsg){
      return;
    }else{
      this.getUnreadMessage(this.pageMsg);
    }
  }

  getUnreadMessage(page=1){
    this.messageService.getAll(page).subscribe((res: any)=>{
      if(res.status){
        if(this.messageList.length>0){
          this.messageList = [ ...this.messageList, ...res.data];
        }else{
          this.messageList = res.data;
        }
        this.msgCount = res.count;
        //this.messageList = res.data;
        this.pageMsg = page+1;
      }else{
        //this.isPaginationShow = false;
        this.isScrollStopMsg = true;
        //this.toastr.error(data.message, "Error");
      }
    }, err=> {
      //this.isPaginationShow = false;
      this.isScrollStopMsg = true;
      //this.toastr.error(err, "Error");
    });
  }

  onScroll(e) {
    if(this.isScrollStop){
      return;
    }else{
      this.getUnreadNotification(this.page);
    }
  }

  getUnreadNotification(page=1){
    this.messageService.getUnreadNotification(page).subscribe((res: any)=>{
      if(res.status){
        //this.notList = res.data;
        if(this.notList.length>0){
          this.notList = [ ...this.notList, ...res.data];
        }else{
          this.notList = res.data;
        }
        this.notCount = res.count;
        this.page = page+1;
      }else{
        //this.toastr.error(data.message, "Error");
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isScrollStop = true;
      //this.toastr.error(err, "Error");
    });
  }

  logout() {
    this.authService.user = '';
    this.authService.userToken = '';
    this.authService.logout();
    this.toastr.success('Logout successfully', "Success");
    this.router.navigateByUrl('login');
  }

}
