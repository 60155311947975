import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrowService } from 'src/app/services/grow.service';

@Component({
  selector: 'app-job-applications',
  templateUrl: './job-applications.component.html',
  styleUrls: ['./job-applications.component.css']
})
export class JobApplicationsComponent implements OnInit {

  id:string;
  jobsApplications:any = [];
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private growService: GrowService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getJobApplications();
  }

  getJobApplications() {
    this.id = this.route.snapshot.params['id'];
    const formData = new FormData();
    formData.append('job_id', this.id);
    this.growService.getJobApplications(formData).subscribe((response: any)=>{
      if (response.status) {
        this.jobsApplications = response.apply_job;
      } else {
        this.jobsApplications = [];
        this.toastr.error(response.message, "Error");
      }
    });
  }

}
