export class GlobalConstants {

    //public static apiURL: string = "http://fambizconnect.com/api";

    public static apiURL: string = "https://api.fambizconnect.com/api";

    //public static apiURL: string = "http://127.0.0.1:8000/api";
    // public static apiURL: string = "http://127.0.0.1:8000/api";

    public static siteTitle: string = "Fambizconnect";

    public static ImagePATH: string = "https://api.fambizconnect.com/";

    //public static user_profile_path: 

    private static ProjectPath: string = "https://api.fambizconnect.com/";

    public static user_profile_path: string = GlobalConstants.ProjectPath + '/uploads/users/photo/';


}