import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../confirmed.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  public loading: boolean;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  //roleList:any = [{ 'id': 2, 'name': 'Coach'}, { 'id': 3, 'name': 'User'}];

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: 
    Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.authService.isLogedin) {
      this.router.navigateByUrl('dashboard');
    }
    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern(this.emailPattern)])],
      //tearms: [false, Validators.requiredTrue],
      //name: ['', Validators.required],
      //user_role: [null, Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required, Validators.minLength(8)]],
  },{ 
      validator: ConfirmedValidator('password', 'confirm_password')
    });
  }

  submit() {
    if(this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.registration(this.registerForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Registration successfully', "Success");
        //this.router.navigateByUrl('OurPlan');
      } else {
        this.loading = false;
        
        this.toastr.error(res.message, "Error");
        //alert(res.message);
      }
    }, err=> {
      this.loading = false;
      if(err instanceof Array){
        this.toastr.error(err.join(), "Error");
      }else{
        if(err.error!=undefined){
          this.toastr.error(err.error.message, "Error");
        }
        this.toastr.error(err, "Error");
        console.log(err)
      }
    })
  }

  get control() {
    return this.registerForm.controls;
  }
}
