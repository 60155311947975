import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GrowService } from 'src/app/services/grow.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-job-add',
  templateUrl: './job-add.component.html',
  styleUrls: ['./job-add.component.css']
})
export class JobAddComponent implements OnInit {

  CreateJobForm: FormGroup;
  industries:any = [];
  companies:any = [];
  changeLogo:boolean = false;
  isAddMode:boolean;
  id:string;

  constructor(
    private formBuilder: FormBuilder,
    private growService: GrowService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.CreateJobForm = this.formBuilder.group({
      id: [],
      company_id: ['', [Validators.required]],
      job_name: ['', [Validators.required]],
      job_location: ['', [Validators.required]],
      salary: ['', [Validators.required]],
      keywords: ['', [Validators.required]],
      published_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      role_summary: ['', [Validators.required]],
      candidate_personal_specifications: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    //Get list of industries
    this.growService.getAllIndustry().subscribe((response: any) => {
      this.industries = response.industries;
    }, error => {
      this.toastr.error(error, "Error");
    });

    //Get list of companies
    this.growService.getCompanies().subscribe((response: any) => {
      this.companies = response.data;
    }, error => {
      this.toastr.error(error, "Error");
    });

    if (!this.isAddMode) {
      const formData = new FormData();
      formData.append('job_id', this.id);
      this.growService.fetchJob(formData).subscribe((response: any) => {
        if (response.status) {
          this.CreateJobForm = this.formBuilder.group({
            id: [response.data.id],
            company_id: [response.data.company_id, [Validators.required]],
            job_name: [response.data.job_name, [Validators.required]],
            job_location: [response.data.job_location, [Validators.required]],
            salary: [response.data.salary, [Validators.required]],
            keywords: [response.data.keywords, [Validators.required]],
            published_date: [response.data.published_date, [Validators.required]],
            end_date: [response.data.end_date, [Validators.required]],
            role_summary: [response.data.role_summary, [Validators.required]],
            candidate_personal_specifications: [response.data.candidate_personal_specifications, [Validators.required]],
          });
        } else {
          this.toastr.error("Job not found", "Error");
        }
      }, error => {
        this.toastr.error(error, "Error");
      });
    }

  }

  get control() {
    return this.CreateJobForm.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
        this.toastr.error("Allow only 20 mb file size", "Error");
        return;
      }
      this.changeLogo = true;
      this.control.logo.patchValue(file);
    }
  }

  submit() {
    if (this.CreateJobForm.invalid) {
      return;
    }
    if (this.isAddMode) {
      console.log('Create')
      this.createJob();
    } else {
      console.log('update')
      this.updateJob();
    }
  }

  createJob() {
    const formData = new FormData();
    formData.append('company_id', this.CreateJobForm.get('company_id').value);
    formData.append('job_name', this.CreateJobForm.get('job_name').value);
    formData.append('job_location', this.CreateJobForm.get('job_location').value);
    formData.append('salary', this.CreateJobForm.get('salary').value);
    formData.append('keywords', this.CreateJobForm.get('keywords').value);
    formData.append('published_date', this.CreateJobForm.get('published_date').value);
    formData.append('end_date', this.CreateJobForm.get('end_date').value);
    formData.append('role_summary', this.CreateJobForm.get('role_summary').value);
    formData.append('candidate_personal_specifications', this.CreateJobForm.get('candidate_personal_specifications').value);
    
    this.growService.createJob(formData).subscribe((response:any) => {
      if (response.status) {
        this.toastr.success('Job added successfully', "Success");
        this.router.navigateByUrl('admin/jobs');
        this.CreateJobForm.reset();
      } else {
        this.toastr.error(response.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

  updateJob() {
    const formData = new FormData();
    formData.append('job_id', this.CreateJobForm.get('id').value);
    formData.append('company_id', this.CreateJobForm.get('company_id').value);
    formData.append('job_name', this.CreateJobForm.get('job_name').value);
    formData.append('job_location', this.CreateJobForm.get('job_location').value);
    formData.append('salary', this.CreateJobForm.get('salary').value);
    formData.append('keywords', this.CreateJobForm.get('keywords').value);
    formData.append('published_date', this.CreateJobForm.get('published_date').value);
    formData.append('end_date', this.CreateJobForm.get('end_date').value);
    formData.append('role_summary', this.CreateJobForm.get('role_summary').value);
    formData.append('candidate_personal_specifications', this.CreateJobForm.get('candidate_personal_specifications').value);
    
    this.growService.updateJob(formData).subscribe((response:any) => {
      if (response.status) {
        this.toastr.success('Job updated successfully', "Success");
        this.router.navigateByUrl('admin/jobs');
        this.CreateJobForm.reset();
      } else {
        this.toastr.error(response.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

}
