<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
   <div class="container-fluid">

      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
               <!-- <h2>Project Leader Characteristics
               </h2> -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/tools">Tools</a></li>
                  <li class="breadcrumb-item active">Introvert Extrovert Calculator</li>
               </ul>
            </div>
         </div>
      </div>

      <div id="htmlData">
         <div class="row">
         <div class="col-md-12">
               <form [formGroup]="questionForm" enctype="multipart/form-data">
                  <div id="myHeader" class=" mb-3">

                  <div class="row">
                     <div class="col-md-5 tool-extra-style">
                        <h5 class="tool-extra tool-head-space">Generate Introvert Extrovert Report</h5>
                     </div>

                     <div class="col-md-7 RightButtons">
                        <div style="float: right;">
                           <button class="SaveReport" type="button" class="btn pbtn" (click)="generateReport()"
                              *ngIf="isGenerateShow" [disabled]="!isGenerateShow">Generate <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> </button>
                        </div>
                        <div style="float: right;" class="m-r-5">
                           <button class="SaveReport" type="button" class="btn pbtn" (click)="submit()"
                              *ngIf="isSubmitShow">Save Report</button>
                        </div>
                        <div style="float: right;" class="m-r-5 Export">
                           <button type="button" class="btn pbtn" (click)="openPDF()" *ngIf="isPdfShow">Export As
                              PDF</button>
                        </div>

                     </div>
                  </div>
                  </div>
                  <div class="row">
                     <div class="col-md-12">
                        <div class="AnalysisText">
                           <h5>Name of Analysis</h5>
                           <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                           </button> -->
                           <input type="text" placeholder="Add your report name" class=" Upgradebtn form-control" formControlName="analysis_name" >

                        </div>
                     </div>
                     </div>
                  <table id="example" class="table table-striped table-bordered ReportTable">
                     <thead class="head-table text-center">
                        <tr>
                           <th class="sorting_1">S.NO.</th>

                           <th class="" style="text-align: left;">Questions</th>
                           <th style="text-align: center; color: #bd007d;">True</th>
                           <th style="text-align: center; color: #bd007d;">False</th>

                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let number of question; let i = index;">
                           <td class="sorting_1">{{i+1}}</td>
                           <td class="Questions">{{number.question_title}}</td>
                           <td>
                              <div class="form-check">
                                 <input type="radio" formControlName="question_{{i+1}}_answer" value="1"
                                    name="question_{{i+1}}_answer" id="{{i+1}}" (change)="countScore()" class="form-check-input">
                                 <label for="{{i+1}}"  class="form-check-label"></label>
                              </div>
                           </td>

                           <td>
                              <div class="form-check">
                                 <input type="radio" formControlName="question_{{i+1}}_answer" value="0"
                                    name="question_{{i+1}}_answer" (change)="countScore()" id="s_{{i+1}}" class="form-check-input">
                                 <label for="s_{{i+1}}" class="form-check-label"></label>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </form>
            </div>
         </div>

      
         <div class="Introvert-Extrovert mb-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="versis-bnf table-bordered">
                        <div class="pro-head col-md-12">
                            <h3 class="mb-5">
                             Introvert Extrovert Report Calculator
                            </h3>

                            <div class="Report__inner-wrap">
                                <div class="Report__grid">
                                  <div class="Report__grid__spacer">
                                   
                                    <div class="left_boxs">
                                       <span class="Report__grid-col Left-arrow">
                                             <img src="./assets/assets/images/left-arrow.svg"> 
                                             <!-- <div class="arrow"></div>  -->
                                             <h5>Introvert</h5> 
                                       </span>
                                    </div>
                                    <div class="center_boxs">
                                       <output for="days-css" [ngStyle]="{'left': 'calc('+css_percentage+'%)'  }"></output>
                                       <span class="Report__grid-col LimeGeen">100%</span>
                                       <span class="Report__grid-col SkyBlue">75%</span>
                                       <span class="Report__grid-col blue">50%</span>
                                       <span class="Report__grid-col green">25%</span>
                                       <span class="Report__grid-col Dgrey">0%</span>
                                       <span class="Report__grid-col yellow">25%</span>
                                       <span class="Report__grid-col orange">50%</span>
                                       <span class="Report__grid-col Dgreen">75%</span>
                                       <span class="Report__grid-col Lgreen">100%</span>
                                    </div>
                                    <div class="right_boxs">
                                       <span class="Report__grid-col Right-arrow">
                                          <img src="./assets/assets/images/right-arrow.svg">
                                        <!-- <div class="arrow"></div> -->
                                            <h5>Extrovert</h5>
                                      </span>
                                    </div>
                                    
                                    
                                    
                                    
                                  </div>
                                 
                                </div>
                      
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <!-- <div class="col-md-12 mt-5">
         <div class="row">
            <div class="card">
               <div class="header">
                  <h2>Introvert Extrovert Report Calculator</h2>
               </div>
               <div class="body">
                  <div class="col-md-12">
                     <div echarts [options]="chartOption" class="demo-chart"></div>
                  </div>
               </div>

            </div>
         </div>
      </div> -->

      </div>
   </div>
</div>