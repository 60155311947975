import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var $: any;

import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-project-tool-view',
  templateUrl: './project-tool-view.component.html',
  styleUrls: ['./project-tool-view.component.css']
})
export class ProjectToolViewComponent implements OnInit {

  id = this.route.snapshot.params['id'];
  data:any = [];
  user_tool_data:any = [];
  total_opp:any=0;
  total_risk:any=0
  questionList: any = [
                'Meets organization’s strategic objectives',
                'Customer prefers our organization',
                'Expands organization’s experience base',
                'Improves organization’s market share',
                'Uses organization’s underutilized resources',
                'Customer will serve as a reference site',
                'Potential for additional business',
                'Customer relationship',
                'Customer readiness to undertake this project',
                'Changing requirements',
                'Customer financial issues',
                'Availability of resources',
                'Resource skill level or certification status',

              ];
  userFileName:any = null;

  chartOption:any;
  chartOption2:any;



  constructor(private _location: Location,
            private formBuilder: FormBuilder,
            private toastr: ToastrService,
            private authService: AuthService,
            private router: Router,
            private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.authService.getSingleTool(this.id).subscribe((res:any)=>{
      if(res.status) {
        this.data = res.usertool;
        console.log(this.data,'this.data')

        if(res.usertool.user_tool_data.length!=0){
          this.user_tool_data = res.usertool.user_tool_data;
          this.total_opp = this.data.opp_pro_score+this.data.opp_imp_score;
          this.total_risk = this.data.risk_pro_score+this.data.risk_imp_score;
          this.userFileName =  res.usertool.tool_user_name;

          this.chartOption = {
            series: [{
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 100,
              splitNumber: 10,
              axisLine: {
                  lineStyle: {
                      width: 6,
                      color: [
                          //[0.25, '#FF6E76'],
                          [0.33, '#cc0000'],
                          [0.66, '#ffbf00'],
                          [1, '#00d52f']
                      ]
                  }
              },
              pointer: {
                  icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                  length: '12%',
                  width: 20,
                  offsetCenter: [0, '-60%'],
                  itemStyle: {
                      color: 'auto'
                  }
              },
              axisTick: {
                  length: 12,
                  lineStyle: {
                      color: 'auto',
                      width: 2
                  }
              },
              splitLine: {
                  length: 20,
                  lineStyle: {
                      color: 'auto',
                      width: 5
                  }
              },
              axisLabel: {
                  color: '#464646',
                  fontSize: 14,
                  distance: -65,
                  formatter: function (value) {
                      if (value === 20) {
                          return 'low';
                      }
                      else if (value === 50) {
                          return 'Medium';
                      }
                      else if (value === 80) {
                          return 'High';
                      }
                      // else if (value === 100) {
                      //     return '差';
                      // }
                  }
              },
              title: {
                  offsetCenter: [0, '-20%'],
                  fontSize: 20
              },
              detail: {
                  fontSize: 30,
                  offsetCenter: [0, '0%'],
                  valueAnimation: true,
                  formatter: function (value) {
                      return value;
                  },
                  color: 'auto'
              },
              data: [{
                  value: this.total_opp,
                  name: 'Benefit Score'
              }]
          }]
          };

          this.chartOption2 = {
            series: [{
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 100,
              splitNumber: 10,
              axisLine: {
                  lineStyle: {
                      width: 6,
                      color: [
                          //[0.25, '#FF6E76'],
                          [0.33, '#00d52f'],
                          [0.66, '#ffbf00'],
                          [1, '#cc0000']
                      ]
                  }
              },
              pointer: {
                  icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                  length: '12%',
                  width: 20,
                  offsetCenter: [0, '-60%'],
                  itemStyle: {
                      color: 'auto'
                  }
              },
              axisTick: {
                  length: 12,
                  lineStyle: {
                      color: 'auto',
                      width: 2
                  }
              },
              splitLine: {
                  length: 20,
                  lineStyle: {
                      color: 'auto',
                      width: 5
                  }
              },
              axisLabel: {
                  color: '#464646',
                  fontSize: 14,
                  distance: -65,
                  formatter: function (value) {
                      if (value === 20) {
                          return 'low';
                      }
                      else if (value === 50) {
                          return 'Medium';
                      }
                      else if (value === 80) {
                          return 'High';
                      }
                      // else if (value === 100) {
                      //     return '差';
                      // }
                  }
              },
              title: {
                  offsetCenter: [0, '-20%'],
                  fontSize: 20
              },
              detail: {
                  fontSize: 30,
                  offsetCenter: [0, '0%'],
                  valueAnimation: true,
                  formatter: function (value) {
                      return value;
                  },
                  color: 'auto'
              },
              data: [{
                  value: this.total_risk,
                  name: 'Risk Score'
              }]
          }]
          };
        }else{
          this.toastr.error("tool question score not found", "Error");
        }
      } else {
        this.toastr.error("Single toold data not found", "Error");
      }

    })

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

  }


  public openPDF():void {

    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName); // Generated PDF

    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
      doc.setTextColor(150);
      doc.text(120, doc.internal.pageSize.height/3, 'VT-Touch');
      return doc;
  }


}
