<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/programme-list']">Programmes List</a></li>
                        <li class="breadcrumb-item">
                            <span *ngIf="isAddMode">Add Programmes Course/Video</span>
                            <span *ngIf="!isAddMode">Edit Programmes Course/Video</span></li>

                    </ul>

                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <!-- <div class="header text-center">
                        <h2 *ngIf="isAddMode">Add Training Course/Video</h2>
                        <h2 *ngIf="!isAddMode">Edit Training Course/Video</h2>
                    </div> -->
                    <div class="body">
                        <form [formGroup]="CreateForm" enctype="multipart/form-data">
                            <div class="row clearfix">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title <span>*</span></label>
                                        <input class="form-control" type="text" formControlName="title" />
                                        <span class="text-danger"
                                            *ngIf="control.title.dirty && control.title.hasError('required')">
                                            Title field is requied.
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Training Type <span>*</span></label>
                                        <select formControlName="training_type" class="form-control"
                                            [(ngModel)]="selectedvalue">
                                            <option value="0" selected hidden>Select</option>
                                            <option value="1">Course</option>
                                            <option value="2">Video</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="control.training_type.dirty && control.training_type.hasError('required')">
                                            Training type field Required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="selectedvalue==1">
                                    <div class="form-group">
                                        <label>Course Link<span>*</span></label>
                                        <input class="form-control" type="text" formControlName="course_link" />
                                        
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="selectedvalue==2">
                                    <div class="form-group">
                                        <label>Video Link<span>*</span></label>
                                        <input class="form-control" type="text" formControlName="video_link" />
                                        
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="selectedvalue==1">
                                    <div class="form-group">
                                        <label>Course Document<span>*</span></label>
                                        <input type="file" formControlName="training_doc"
                                            (change)="onFileChange($event, 'training_doc')" accept=".jpg, .pdf, .jpeg, .png">
                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description <span>*</span></label>
                                        <textarea name="" class="form-control post-style" formControlName="description"
                                            placeholder="Enter description"></textarea>
                                        <span class="text-danger"
                                            *ngIf="control.description.dirty && control.description.hasError('required')">
                                            Description field is requied.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-primary" (click)="submit()"
                                [disabled]="!CreateForm.valid"> <!--  -->
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>


    </div>
</div>