<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
              <!-- <h2> Members List
              </h2> -->
              <ul class="breadcrumb vt-a">
                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                <li class="breadcrumb-item">Network</li>
                <li class="breadcrumb-item">Coaches</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="shadow-sm p-3 mb-5 rounded">
        <div class="row clearfix justify-content-end">
            <div class="col-lg-12 col-md-12 col-sm-12">

                <div id="navbar-search" class="search-form serach-section">
                  <input class="form-control" placeholder="Search here..." type="text" [(ngModel)]="searchText" (keyup)="filter_records()">
                  <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                </div>
                <div id="navbar-search" class="search-form serach-section">
                    <select class="form-control" placeholder="Select industry" [(ngModel)]="industry_id" (change)="filter_records()">
                        <option value="" selected>Select All Industry</option>
                        <!-- <option [ngValue]="Select" value="" selected>Select All Industry</option> -->
                        <option *ngFor="let industry of industryList" [ngValue]="industry.id">
                            {{industry.title}}
                        </option>
                    </select>
                </div>

            </div>
        </div>
        </div><!--./justify-content-end-->


        <!-- temrorary new card -->
        <div class="row clearfix" infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollUpDistance]="2"
        [infiniteScrollThrottle]="300"
        [fromRoot]="true"
        (scrolled)="onScroll($event)"
        (scrolledUp)="onScrollUp($event)">
            <!-- <div class="mem col-lg-3 col-md-6 col-sm-12 mm-mng" *ngFor="let user of users | selectBox:industry_id:industry_id | filter:searchText:['name', 'email', 'summary_description']"> -->
            <div class="mem col-lg-3 col-md-6 col-sm-12 mm-mng" *ngFor="let user of users">
                <div class="card card-mi-height cursor-pointer crd-ponter" [routerLink]="['/user-details', user.id]">
                    <div class="body text-center body-min00">
                        <div class="layer-pnk dsds">
                            <div class="chart easy-pie-chart-1 chrt-new" data-percent="100">
                                <span><img  [src]="[user.profile_pic]" alt="user" class="rounded-circle" /></span>
                            </div>
                        </div>
                        <div class="down-bg">
                            <div class="mian-down">
                                <div class="inds-hd">
                                    <h3>{{user.name}}</h3>
                                    <p *ngIf="user.industry_id">{{user.industry.title}}</p>
                                </div>
                                <div class="content-prt">
                                    <p class="lrm-content" [innerHTML]="user.summary_description | slice:0:70"></p>
                                    <div class="icn-part">
                                        <p class="ic-ml">
                                            <i class="icon-envelope"></i>
                                            <a href="#">{{(user.email.length > 20)? (user.email | slice:0:10) + '...' + (user.email | slice:-12):(user.email) }}</a>
                                        </p>
                                        <p class="ic-img-cn ds-blk-hvr ">
                                            <a *ngIf="user?.linkedin_id!=null" href="{{ user?.linkedin_id }}" target="_blank">
                                                <img src="./assets/assets/images/lnkin-hvr.png">
                                            </a>
                                            <a *ngIf="user?.linkedin_id==null" href="javascript:"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                                            <!-- <a href=""><img src="./assets/assets/images/fb-hvr.png"></a> -->
                                            <a href="javascript:" *ngIf="user?.fb_id==null"><img src="./assets/assets/images/twit-hvr.png"></a>
                                            <a *ngIf="user?.fb_id!=null" href="{{ user?.linkedin_id }}" target="_blank">
                                                <img src="./assets/assets/images/twit-hvr.png">
                                            </a>
                                        </p>
                                        <p class="ic-img-cn  ds-none">
                                            <a href="javascript:">
                                                <img src="./assets/assets/images/linkin.png">
                                            </a>
                                            <!-- <a href=""><img src="./assets/assets/images/fbicn.png"></a> -->
                                           <a href="javascript:" target="_blank"><img src="./assets/assets/images/wtitter.png"></a>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="dis_btn_nn">
                            <button class=" btn-sm btn-outline-primary">Edit User Role</button>
                        </div> -->
                    </div>
                </div>
                <div class="dis_btn_nn" *ngIf="loginRole==1&&!editUserRole&&user.id!=loginId">
                    <button class=" btn pbtn " (click)="open(mymodal, user.id)">Edit User Role</button>
                </div>
            </div>
        </div>
        <!-- temrorary new card -->

        <!-- <div class="row clearfix">
            <div class="mem col-lg-3 col-md-6 col-sm-12" *ngFor="let user of users | selectBox:industry_id:industry_id | filter:searchText:['name', 'email', 'summary_description']">
                <div class="card card-mi-height cursor-pointer"  [routerLink]="['/user-details', user.id]">
                    <div class="layer">

                    </div>
                    <div class="body text-center">
                        <div class="chart easy-pie-chart-1" data-percent="100">
                            <span><img [src]="[user.profile_pic]" alt="user" class="rounded-circle" /></span>
                        </div>
                        <div class="details">
                            <h6>
                                <a [routerLink]="['/user-details', user.id]" >{{user.name}}</a>
                            </h6>
                        </div>
                        <p *ngIf="user.industry_id">{{user.industry.title}}</p>
                        <p [innerHTML]="user.summary_description | slice:0:25"></p>
                        <small>{{user.email}}</small>
                        <div class="dis_btn_nn" *ngIf="loginRole==1&&!editUserRole&&user.id!=loginId">
                            <button class=" btn-sm btn-outline-primary" (click)="open(mymodal, user.id)">Edit User Role</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <ng-template #mymodal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Edit Role</h4>
                <button type="button" class="close" aria-label="Close"  (click)="JoinAndClose()"> <!-- (click)="modal.dismiss('Cross click')" -->
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="editUserRole">
                    <form [formGroup]="editUserForm" enctype="multipart/form-data">
                        <div class="form-group">
                            <label>User Role <span>*</span></label>
                            <select formControlName="user_role" class="form-control">
                                <option [ngValue]="null" disabled>Select role</option>
                                <option *ngFor="let values of roleList" [ngValue]="values.id">{{values.name}}</option>
                            </select>
                            <span class="text-danger" *ngIf="eUserControl.user_role.dirty && eUserControl.user_role.hasError('required')">
                                User Role field Required.
                            </span>
                        </div>
                        <input type="hidden" formControlName="id">
                        <button type="submit" class="btn btn-primary m-l-5" (click)="updateUserRole()" [disabled]="!editUserForm.valid">
                            save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                        </button>
                    </form>
                </div>
            </div>
        </ng-template>

    </div>
</div>