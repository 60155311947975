<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
      <div class="block-header top-head-vt">
        <div class="row">
          <div class="col-lg-5 col-md-8 col-sm-12">
            <ul class="breadcrumb vt-a">
              <li class="breadcrumb-item">
                <a [routerLink]="['/Admin-Dashboard']">
                  <i class="icon-home"></i>
                </a>
              </li>
              <li class="breadcrumb-item">
                <a routerLink="/admin/jobs">Jobs</a>
              </li>
              <li class="breadcrumb-item">Job Applications</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="card">
            <div class="header">
              <h2>Job Applications</h2>
            </div>
            <div class="body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Job Title</th>
                      <th scope="col">Company</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Cover Letter</th>
                      <th scope="col">CV</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="jobsApplications?.length; else no_data_templ">
                    <tr *ngFor="let job of jobsApplications; index as i">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                        {{ job.job_title }}
                      </td>
                      <td>
                        {{ job.company_name }}
                      </td>
                      <td>
                        {{ job.first_name }}
                      </td>
                      <td>
                        {{ job.last_name }}
                      </td>
                      <td [innerHTML]="job.cover_letter">
                      </td>
                      <td>
                        <a [href]="job.cv" target="_blank" rel="noopener noreferrer"
                            [download]="job.job_title" class="btn btn-primary  pbtn">
                            <i class="fa fa-download"></i>
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="jobsApplications.length==0">
                      <td colspan="13">No Data Found</td>
                    </tr>
                  </tbody>
                  <ng-template #no_data_templ>
                    <tr>
                      <td colspan="7" class="text-center">No Data Found</td>
                    </tr>
                  </ng-template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
