import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Fambiz';
  private result: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute, private authService: AuthService) {

    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event['url'] === '/dashboard' || event['url'] === '/learn/generic-skills' || event['url'] === '/learn/testing-skills' || event['url'] === '/learn/soft-skills' || event['url'] === '/learn/training' ||  event['url'] === '/vt-members' ||  event['url'] === '/vt-coaches' || event['url'] === '/vt-events' || event['url'] === '/tools' || event['url'] === '/deal-room') {
    //       if(this.authService.user) {
    //         if (this.authService.user.user_role != 1) {
    //           // this.authService.getUserActivePlan().subscribe((res:any)=>{
    //           //   if(res.status) {
    //           //   } else {
    //           //     this.router.navigateByUrl('OurPlan');
    //           //   }
    //           // }, err=> {
    //           //   this.router.navigateByUrl('OurPlan');
    //           // })
    //         }
    //       }
    //     }
    //   }
    // });
  }
  ngOnInit() {
  }
}
