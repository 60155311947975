import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../common/global-constants';
import { FilterPipe } from '../../filter/filter.pipe';
import { SelectBoxPipe } from '../../filter/select-box.pipe';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  
  users:any = [];
  public searchText = null;
  industryList:any = [];
  industry_id = null;
  loginRole:any = null;
  loginId:any = null;
  editUserRole:boolean = false;
  user: any;
  roleList:any = [{ 'id': 2, 'name': 'Coach'}, { 'id': 3, 'name': 'User'}];
  modalReference: NgbModalRef;

  isPaginationShow = false;
  page = 1;
  isScrollStop = false;
  isLoadMore = true;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private _location: Location, 
    private modalService: NgbModal,
    private formBuilder: FormBuilder,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    //getUsersWithPage
    // this.authService.getUsers().subscribe((data: any)=>{
    //   if(data.status){
    //     this.users = data.result;
    //   }else{
    //     this.toastr.error(data.message, "Error");
    //     this.router.navigateByUrl('dashboard');
    //   }
    // });

    this.getUsers(1,0);

    this.authService.getAllIndustry().subscribe((data: any)=>{
        if(data.status){
          this.industryList = data.industries;
        }else{
          this.toastr.error(data.message, "Error");
        }
    });

    this.loginRole = this.authService.user.user_role;
    this.loginId = this.authService.user.id;
  }

  getUsers(page=1,search=0){
    if(this.searchText == null){
      this.searchText = ''
    }
    if(this.industry_id == null){
      this.industry_id = ''
    }
    if(search == 1){
      this.users = []
    }
    this.spinner.show()
    this.authService.getUsersWithPage(page,this.searchText,this.industry_id).subscribe((data: any)=>{
      if(data.status){
        if(data.result.length>0){
          this.isLoadMore = true;
        }else{
          this.isLoadMore = false;
        }
        //this.users = data.result;
        if(this.users.length>0){
          this.users = [ ...this.users, ...data.result];
          this.spinner.hide()
        }else{
          this.users = data.result;
        }
        this.page = page+1;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
        this.spinner.hide()
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isScrollStop = true;
      this.spinner.hide()
      //this.toastr.error(err, "Error");
    });
  }
  getFilterUsers(page=1,search=0){
    if(this.searchText == null){
      this.searchText = ''
    }
    if(this.industry_id == null){
      this.industry_id = ''
    }
    if(search == 1){
      this.users = []
    }
    this.authService.getUsersWithPage(page,this.searchText,this.industry_id).subscribe((data: any)=>{
      if(data.status){
        if(data.result.length>0){
          this.isLoadMore = true;
        }else{
          this.isLoadMore = false;
          this.toastr.error('No results found.');
        }
        //this.users = data.result;
        if(this.users.length>0){
          this.users = data.result;
        }else{
          this.users = data.result;
        }
        this.page = page+1;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isScrollStop = true;
      //this.toastr.error(err, "Error");
    });
  }

  onScroll(e) {
    if(this.isScrollStop || this.page == 1){
      return;
    }else{
      this.getUsers(this.page,0);
    }
  }

  onScrollUp(e) {

  }

  onMySelectChange(event){
      let selectedValue = event.target.value;

      return this.users.filter(item => {
        var itemFound: Boolean;
          if (this.users?.industry_id === selectedValue) {
            itemFound = true;
          }
          
        return itemFound;
      });
      //return this.users.filter(
          //users => users.industry_id === selectedValue);
      // You can implement filtering logic depending on the selectedValue
      /* if(selectedValue == 1){
        this.users = this.users.slice(0, 2);
      }else if(selectedValue == 2){
        this.users =  this.users.slice(0, 3);
      }else if(selectedValue == 3){
        this.users =  this.users;
      }else{
        this.users =  [];
      } */
  }

  backClicked() {
    this._location.back();
  }

  closeResult: string;
  editUserForm: FormGroup;
  public loading: boolean;

  open(content, id) {
    this.user = this.getUserByFind(id);
    this.editUserRole = true;
    this.editUserForm = this.formBuilder.group({
        id: ['', Validators.required],
        user_role: ['', Validators.required],
    });
    this.editUserForm.patchValue(this.user)
    /* this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //this.editUserRole = true;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); */

    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getUserByFind(id){
    return this.users.find(x => x.id === id);
  }

  private getDismissReason(reason: any): string {
    this.editUserRole = false;
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
    
  }

  get eUserControl() {
    return this.editUserForm.controls;
  }

  cancelEditUser(){
    this.editUserRole = false;
  }
  
  updateUserRole() {
    if(this.editUserForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authService.updateUserRole(this.editUserForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('User role updated successfully', "Success");
        this.editUserRole = false;
        this.getDismissReason('save');
        
        if(res.user){
          //this.user = res.user;
          this.users.find(item => item.id == res.user.id).user_role = res.user.user_role;
        }
        this.JoinAndClose();
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }

  JoinAndClose() {
    this.modalReference.close();
  }

  filter_records(){
    this.getFilterUsers(1,1)
  }

}
