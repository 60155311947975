<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">

        <div class="block-header top-head-vt">
          <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
              <!-- <h2>Project Leader Characteristics
              </h2> -->
              <ul class="breadcrumb vt-a">
                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i
                       class="icon-home"></i></a></li>
                <li class="breadcrumb-item"><a routerLink="/tools">Tools</a></li>
                <li class="breadcrumb-item active">Agile Project Leader Analysis</li>
              </ul>
            </div>
          </div>
        </div>

        <div id="htmlData">
           <div class="row">
                   <div class="col-md-12">
                    <form [formGroup]="questionForm" enctype="multipart/form-data">
                        <div class="row mb-3" id="myHeader">
                        <div class="col-md-5 tool-extra-style">
                          <h5 class="tool-extra tool-head-space">Generate Agile Project Leader Analysis Report</h5>
                        </div>

                        <div class="col-md-7">
                          <div style="float: right;" (click)="DeleteReport()" *ngIf="isDeleteShow">
                            <button type="button" class="btn pbtn"><i class="fa fa-trash"></i></button>
                          </div>
                          <div style="float: right;" (click)="generateReport()" *ngIf="isReportShow">
                            <button type="button" class="btn pbtn" [disabled]="!isSubmit || disGenBtn">Generate <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
                          </div>
                          <div style="float: right;" (click)="open(mymodal)" *ngIf="isSaveShow" class="m-r-5">
                            <button type="button" class="btn pbtn" [disabled]="!isSubmit">Save Report</button>
                          </div>
                          <div style="float: right;" (click)="openPDF()" *ngIf="isDownloadShowOnly" class="m-r-5">
                            <button type="button" class="btn pbtn" [disabled]="!isDownloadShow">Export As PDF</button>
                          </div>

                        </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                             <div class="AnalysisText">
                                <h5>Name of Analysis</h5>
                                <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                                </button> -->
                                <input type="text" placeholder="Add your report name" class=" Upgradebtn form-control" formControlName="analysis_name" >
     
                             </div>
                          </div>
                          </div>
                        <table id="example" class="table table-striped table-bordered ">
                            <thead class="head-table">
                                <tr>
                                <th colspan="1">Project Leader Characteristics</th>
                                <!-- <th>Priority</th> -->
                                <th colspan="1">Weight</th>
                                <th colspan="1">Description</th>
                                <th colspan="1">Rating <br/>
                                  <span (click)="openTwo(content)" class="f-small"> Scale(0-5)
                                  <i class="icon-info"></i>
                                </span></th>
                                <th colspan="3">Value <br /><span class="f-small">Value = Rating x Weight/100</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td class="sorting_1">Customer Focus</td>
                                        <!-- <td><input type="text" class="form-control" required="" name="question_1_priority" formControlName="question_1_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" name="question_1_weight" formControlName="question_1_weight" (change)="onWeightChange($event)"></td>
                                        <td>Degree to which the project leader focuses on the customer rather<br> than on following standard project management procedures</td>
                                        <td>
                                            <div class="newRate " [ngClass]="{'disabled': starDisable}">
                                                <!-- <span (click)="setMyRate(1, 5, $event)">☆</span>
                                                <span (click)="setMyRate(1, 4, $event)">☆</span>
                                                <span (click)="setMyRate(1, 3, $event)">☆</span>
                                                <span (click)="setMyRate(1, 2, $event)">☆</span>
                                                <span (click)="setMyRate(1, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(1, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(1, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(1, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(1, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(1, 5, $event)"></i>
                                                <input type="hidden" class="form-control" name="question_1_rating" formControlName="question_1_rating">
                                            </div>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="question_1_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_1_rating_value.errors }"></td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1">Flexibility</td>
                                        <!-- <td><input type="text" class="form-control" required="" formControlName="question_2_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" formControlName="question_2_weight" (change)="onWeightChange($event)"></td>
                                        <td>Degree to which the project leader values innovation and practical<br> processes over sticking with the plan</td>
                                        <td>
                                            <div class="newRate" [ngClass]="{'disabled': starDisable}">
                                                <!-- <span (click)="setMyRate(2, 5, $event)">☆</span>
                                                <span (click)="setMyRate(2, 4, $event)">☆</span>
                                                <span (click)="setMyRate(2, 3, $event)">☆</span>
                                                <span (click)="setMyRate(2, 2, $event)">☆</span>
                                                <span (click)="setMyRate(2, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(2, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(2, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(2, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(2, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(2, 5, $event)"></i>
                                                <input type="hidden" class="form-control" formControlName="question_2_rating">
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="question_2_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_2_rating_value.errors }">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1">Tolerance for Ambigulty</td>
                                        <!-- <td><input type="text" class="form-control" required="" formControlName="question_3_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" formControlName="question_3_weight" (change)="onWeightChange($event)"></td>
                                        <td>Degree to which the project leader is comfortable with an<br> uncertain and changing environment</td>
                                        <td>
                                            <div class="newRate" [ngClass]="{'disabled': starDisable}">
                                                <!-- <span (click)="setMyRate(3, 5, $event)">☆</span>
                                                <span (click)="setMyRate(3, 4, $event)">☆</span>
                                                <span (click)="setMyRate(3, 3, $event)">☆</span>
                                                <span (click)="setMyRate(3, 2, $event)">☆</span>
                                                <span (click)="setMyRate(3, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(3, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(3, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(3, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(3, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(3, 5, $event)"></i>
                                                <input type="hidden" class="form-control" formControlName="question_3_rating">
                                            </div>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="question_3_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_3_rating_value.errors }"></td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1">Communication</td>
                                        <!-- <td><input type="text" class="form-control" required="" formControlName="question_4_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" formControlName="question_4_weight" (change)="onWeightChange($event)"></td>
                                        <td>Project leaders skill and commitment to sharing information as<br> needed with all stakeholders</td>
                                        <td>
                                            <div class="newRate" [ngClass]="{'disabled': starDisable}">
                                                <!-- <span (click)="setMyRate(4, 5, $event)">☆</span>
                                                <span (click)="setMyRate(4, 4, $event)">☆</span>
                                                <span (click)="setMyRate(4, 3, $event)">☆</span>
                                                <span (click)="setMyRate(4, 2, $event)">☆</span>
                                                <span (click)="setMyRate(4, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(4, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(4, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(4, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(4, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(4, 5, $event)"></i>
                                                <input type="hidden" class="form-control" formControlName="question_4_rating">
                                            </div>
                                        </td>
                                        <td><input type="text" class="form-control weight" formControlName="question_4_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_4_rating_value.errors }"></td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1">Commitment</td>
                                        <!-- <td><input type="text" class="form-control" required="" formControlName="question_5_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" formControlName="question_5_weight" (change)="onWeightChange($event)"></td>
                                        <td>Project leader's skill and commitment to the team and the<br> willingness to promote team collaboration</td>
                                        <td>
                                            <div class="newRate" [ngClass]="{'disabled': starDisable}">
                                                <!-- <span (click)="setMyRate(5, 5, $event)">☆</span>
                                                <span (click)="setMyRate(5, 4, $event)">☆</span>
                                                <span (click)="setMyRate(5, 3, $event)">☆</span>
                                                <span (click)="setMyRate(5, 2, $event)">☆</span>
                                                <span (click)="setMyRate(5, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(5, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(5, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(5, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(5, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(5, 5, $event)"></i>
                                                <input type="hidden" class="form-control" formControlName="question_5_rating">
                                            </div>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="question_5_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_5_rating_value.errors }"></td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1">Leader</td>
                                        <!-- <td><input type="text" class="form-control" required="" formControlName="question_6_priority" ></td> -->
                                        <td><input type="text" class="form-control weight" [ngClass]="{'red-border': isWeightWrong}" required="" formControlName="question_6_weight" (change)="onWeightChange($event)"></td>
                                        <td>Project leader's ability to motivate the team, delegate and<br> then get out of the way</td>
                                        <td>
                                            <div class="newRate" [ngClass]="{'disabled': starDisable}"><!-- rating -->
                                                <!-- <span (click)="setMyRate(6, 5, $event)">☆</span>
                                                <span (click)="setMyRate(6, 4, $event)">☆</span>
                                                <span (click)="setMyRate(6, 3, $event)">☆</span>
                                                <span (click)="setMyRate(6, 2, $event)">☆</span>
                                                <span (click)="setMyRate(6, 1, $event)">☆</span> -->
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(6, 1, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(6, 2, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(6, 3, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(6, 4, $event)"></i>
                                                <i class="fa fa-star-o fa-sm" aria-hidden="true" (click)="setMyRate(6, 5, $event)"></i>
                                            </div>
                                            <input type="hidden" class="form-control" formControlName="question_6_rating">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="question_6_rating_value" readonly
                                            [ngClass]="{ 'has-error': isSubmitted && queControl.question_6_rating_value.errors }"></td>
                                    </tr>
                                    <tr>
                                        <td class="sorting_1"><b>Total Weight</b></td>
                                        <td><input type="text" class="form-control box-1" required="" formControlName="weightTotal" readonly></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                            </tbody>
                        </table>

                        <input type="hidden" class="form-control" formControlName="tools_id">
                    </form>
                </div>
            </div>
            <div class="col-md-12" *ngIf="isChartShow">
                <div class="row">
                    <div class="card">
                        <div class="header">
                            <h6>Project Leader Assessment</h6>
                        </div>
                        <div class="body">
                            <!-- <canvas id="radar_chart" height="150"></canvas> -->
                            <div class="chart-wrapper" >
                                <canvas
                                    id="radar_chart" baseChart
                                    [datasets]="radarChartData"
                                    [options]="radarChartOptions"
                                    [labels]="radarChartLabels"
                                    [chartType]="radarChartType"
                                    [colors]="barChartColors"
                                    height="20vh" width="40vw">
                                </canvas>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-12 col-sm-12 indicator-bottom card" *ngIf="isChartShow">
                      <h5>Project Leader Compatibility Indicator = {{totalRate}}</h5>
                      <span class="result-3" *ngIf="totalRate==5">Project leader has all the characteristics to be successful in an agile
                        environment
                      </span>
                      <span class="result-3" *ngIf="totalRate==4">Project leader has many of the key characteristics to be successful in an agile
                        environment</span>
                      <span class="result-2" *ngIf="totalRate==3">Project leader will need to change some aspects of his or her style to be
                        successful
                      </span>
                      <span class="result-2" *ngIf="totalRate==2">Project leader will need guidance to be successful in managing an agile project
                      </span>
                      <span class="result-1" *ngIf="totalRate==1">Project leader will find it difficult to lead an agile project</span>
                      <span class="result-1" *ngIf="totalRate==0">Project leader does not have the requisite skills or demeanor to lead an agile
                        project</span>

                        <div class="col-md-12 tool-bottom">
                          <h6>NOTE: The higher the score, the better it is for an agile environment
                            <span (click)="openThree(contents)">
                              <i class="icon-info"></i>
                            </span>
                          </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Save Report</h4>
    </div>
    <div class="modal-body save-box">
        <div *ngIf="editUserRole">
            <form [formGroup]="editUserForm" enctype="multipart/form-data">
            <div class="form-group">
                <label>Type Your File Name<span>*</span></label>
                <input type="text" class="form-control" formControlName="user_name" placeholder="Eg. John_12-4-2021_3:10">
            </div>
            <!-- <input type="hidden" formControlName="id"> -->
            <button style="float:right" type="submit" class="btn pbtn" (click)="updateUserName()">
                Save <!-- [disabled]="!editUserForm.valid" -->
            </button>
            </form>

        </div>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Explanation for the Project Manager (PM) Compatibility Indicator</h4>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item disabled sub-title">The ratings are 0 through 5. Please keep the ratings to whole numbers.</li>
            <li class="list-group-item"><p>0</p> Not at all</li>
            <li class="list-group-item"><p>1</p> Minimal</li>
            <li class="list-group-item"><p>2</p> Somewhat</li>
            <li class="list-group-item"><p>3</p> Average</li>
            <li class="list-group-item"><p>4</p> Above Average</li>
            <li class="list-group-item"><p>5</p> Competitive Advantage</li>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>

<ng-template #contents let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-titles">Explanation for the Project Manager (PM) Compatibility Indicator</h4>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <li class="list-group-item"><p>5</p> = Project leader has all the characteristics to be successful in an agile
        environment
      </li>
      <li class="list-group-item"><p>4</p> = Project leader has many of the key characteristics to be successful in an agile
        environment</li>
      <li class="list-group-item"><p>3</p> = Project leader will need to change some aspects of his or her style to be
        successful
      </li>
      <li class="list-group-item"><p>2</p> = Project leader will need guidance to be successful in managing an agile project
      </li>
      <li class="list-group-item"><p>1</p> = Project leader will find it difficult to lead an agile project</li>
      <li class="list-group-item"><p>0</p> = Project leader does not have the requisite skills or demeanor to lead an agile
        project</li>
    </ul>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
