<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
               <!-- <h2>Force Field Analysis
                  </h2> -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/dashboard">Upgrade / Renew</a></li>
               </ul>
            </div>
         </div>
      </div>
      <!-- Warnning massage -->
      <div class="Warnning Iconwarning-box mb-3">
         <div class="row">
            <div class="col-md-8">
             <div class="info-Iconwarning ">
                <img src="./assets/assets/images/renew-icon.svg" alt="renew-icon">
               </div>
                <div class="Iconwarning-box-content">
                 <p>
                  <span innerHtml="Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type | lowercase}} membership ({{CurrentPlan.plan.plan_name}}) is ending on  {{CurrentPlan_expiry_date | ordinalDate}}. Upgrade your subscription plan to keep enjoying the benefits of all features by fambiz community."></span>
              </p>
             </div>
            </div>
            <div class="col-md-4 text-right">
               <div class="Iconwarning-box-content" *ngIf="CurrentPlan && CurrentPlan.plan.plan_name !== 'Platinum Member'">
               <button class="text-Iconwarning btn" (click)="OpenPlanPopup(PlanModal)">Upgrade Your Subscription</button>
               </div>
            </div>
         </div>
      </div>
      <!-- Active subscriptions -->
      <div class="Upgradesubscriptions-card mb-4">
         <div class="Billing-card-title">
            <h5 class="card-title-text">Active subscriptions</h5>
            <!-- <div class="pull-right EditIcon">
               <a href="javascript:" (click)="ActiveSubscriptionDisable=!ActiveSubscriptionDisable" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div> -->
         </div>
         <div class="subscriptions-card-content">
            <div class="ACTIVE-title">
               <h4>Active subscriptions</h4>
            </div>
            <div class="PaymentCart">
               <div class="no-gutters row">
                  <div class="col-sm-6 col-md-4 col-xl-4">
                     <div class="billing-content">
                        <p *ngIf="CurrentPlan.plan_id == 1">Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} {{CurrentPlan.plan.plan_name}} subscription will expire <br> on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}</p>
                        <p *ngIf="CurrentPlan.plan_id != 1">Next billing date - <span innerHtml="{{CurrentPlan_expiry_date | ordinalDate}}"></span></p>
                        <p *ngIf="CurrentPlan.plan_id != 1">Price - £{{CurrentPlan.plan.price}}</p>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-4 col-xl-4">
                     <div class="Free-Trail">
                        <div class="FBN_CommunityLogo">
               <img src="./assets/assets/images/FBN_CommunityLogo.jpg" alt="FBN_CommunityLogo">

                        </div>
                        <button class="text-payment btn ">{{CurrentPlan.plan.plan_name}}</button>
                     </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-xl-4">
                     <div class="Total-content-wrapper">
                        <div class="Total-content-left">
                           <p>Auto Renewal</p>
                        </div>
                        <div class="Total-content-right">
                           <label class="switch">
                           <input type="checkbox" [checked]="CurrentPlan.auto_renewal == 1">
                           <span class="slider" ></span>
                           </label> 
                        </div>
                     </div>
                     <div class="Total-content-wrapper">
                        <div class="Total-content-left">
                           <p>Send Reminders</p>
                        </div>
                        <div class="Total-content-right">
                           <label class="switch">
                           <input type="checkbox" [checked]="CurrentPlan.send_reminder == 1">
                           <span class="slider"></span>
                           </label> 
                        </div>
                     </div>
                  </div>
                  <div class=" Membership-Plan">
                     <!-- <div class=" dropdown show">
                        <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn-shadow dropdown-toggle btn">
                        <span class="btn-icon-wrapper  opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                        </span>
                        Change Membership Plan 
                        </button>
                        <button type="button" (click)="OpenPlanPopup(PlanModal)" class="btn-shadow dropdown-toggle btn">
                        <span class="btn-icon-wrapper  opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                        </span>
                        Change Membership Plan 
                        </button>
                        <div role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                           <ul class="nav flex-column">
                              <li class="nav-item">
                                 <a class="nav-link">
                                 <span> Dummy</span>
                                 </a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link">
                                 <span> Dummy</span>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div> -->
                     <div class="MoreInformation">
                        <p>For More Information Please Visit <a href="javascript:" (click)="OurPlan();"> our Membership Subscriptions </a> </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Billing address -->
      <div class="Upgradesubscriptions-card mb-4">
         <div class="Billing-card-title">
            <h5 class="card-title-text" >Billing address</h5>
            <div class="pull-right EditIcon">
               <a href="javascript:" (click)="BillingAddressDisable=!BillingAddressDisable" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div>
         </div>
         <div class="subscriptions-card-content">
            <div class="AddBilling-title">
               <h4>Add your billing address</h4>
               <!-- <div class="pull-right CloseIcon">
                  <a href="javascript:" class="text-center btn" role="button" >
                  <i class="fa fa-close"></i>
                  </a>
               </div> -->
            </div>
            <!-- <form name="BillingAddress" class="BillingAddress" >
               <div class="row">
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="name">Name*</label>
                        <input type="text" class="form-control" name="name" id="name" >
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="Company">Company name</label>
                        <input type="text" class="form-control" name="Company" id="Company">
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="Country">Country*</label>
                        <input type="text" class="form-control" name="Country" id="Country" >
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="State">State*</label>
                        <input type="text" class="form-control" name="State" id="State">
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="City">City*</label>
                        <input type="text" class="form-control" name="City" id="City" >
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="Postal">Postal Code*</label>
                        <input type="text" class="form-control" name="Postal" id="Postal">
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="Address1">Address 1*</label>
                        <input type="text" class="form-control" name="Address1" id="Address1">
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label class="label" for="Address2">Address 2</label>
                        <input type="text" class="form-control" name="Address2" id="Address2" >
                     </div>
                  </div>
                  <div class="col-md-12">
                     <div class="text-right d-block subscriptions-card-footer">
                        <button class="mr-2 text-Save btn ">Save</button>
                        <button class="text-Next btn ">Next</button>
                     </div>
                  </div>
               </div>
               </form> -->
            <form  [formGroup]="billingAddressForm" enctype="multipart/form-data" (ngSubmit)="submitBillingAddress()" class="BillingAddress" >
            <div class="row">
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="name">Name*</label>
                     <input type="text" class="form-control" id="name" formControlName="user_name" [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }" [readonly]="BillingAddressDisable" (keypress)="keyPressCharacters($event)" >
                     <div *ngIf="billingAddressForm.controls['user_name'].invalid && (billingAddressForm.controls['user_name'].dirty || billingAddressForm.controls['user_name'].touched)" class="alert">
                        <div *ngIf="billingAddressForm.controls['user_name'].errors.required" class="text-danger">
                           Please enter name
                        </div>
                        <div *ngIf="billingAddressForm.controls['user_name'].errors.pattern" class="text-danger">
                           Please enter only characters
                        </div>
                    </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="Company">House Number/Name*</label>
                     <input type="text" class="form-control" formControlName="company_name" id="Company" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="Address1">Address Line 1*</label>
                     <input type="text" class="form-control" formControlName="address_1" id="Address1" [ngClass]="{ 'is-invalid': submitted && f.address_1.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="Address2">Address Line 2*</label>
                     <input type="text" class="form-control" formControlName="address_2" id="Address2" [ngClass]="{ 'is-invalid': submitted && f.address_2.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="City">City*</label>
                     <input type="text" class="form-control" formControlName="city" id="City" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="Postal">Postal Code*</label>
                     <input type="text" class="form-control" formControlName="postal_code" id="Postal" [ngClass]="{ 'is-invalid': submitted && f.postal_code.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="State">State/County*</label>
                     <input type="text" class="form-control" formControlName="state" id="State" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
              <div class="col-md-6">
                  <div class="form-group">
                     <label class="label" for="Country">Country*</label>
                     <input type="text" class="form-control" formControlName="country" id="Country"  [ngClass]="{ 'is-invalid': submitted && f.country.errors }" [readonly]="BillingAddressDisable">
                  </div>
               </div>
               <div class="col-md-12">
                  <div class="text-right d-block subscriptions-card-footer">
                     <button type="submit" class="mr-2 text-Save btn " [disabled]="BillingAddressDisable">Update</button>
                     <button type="button" class="text-Next btn ">Next</button>
                  </div>
               </div>
            </div>
            </form>
         </div>
      </div>
      <!-- payment Details -->
      <!-- <div class="Upgradesubscriptions-card mb-4">
         <div class="Billing-card-title">
            <h5 class="card-title-text">Payment Details</h5>
            <div class="pull-right EditIcon">
               <a href="javascript:" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div>
         </div>
         <div class="subscriptions-card-content">
            <div class="AddBilling-title">
               <h4>Add your billing address</h4>
               <div class="pull-right CloseIcon">
                  <a href="javascript:" class="text-center btn" role="button" >
                  <i class="fa fa-close"></i>
                  </a>
               </div>
            </div>
            <div class="PaymentCart">
               <img src="./assets/assets/images/PaymentCart.svg" alt="PaymentCart">
               <div class="text-center payment-details">
                  <button class="text-payment btn ">Add payment  here</button>
                  <p>We accept all major credit/debit cards and PayPal</p>
               </div>
            </div>
         </div>
      </div> -->
      <!-- Select your payment method -->
      <div class="Upgradesubscriptions-card mb-4" id="PaymentDetails">
         <div class="Billing-card-title">
            <h5 class="card-title-text">Payment Details</h5>
            <!-- <div class="pull-right EditIcon">
               <a href="javascript:" (click)="PaymentCardDisable=!PaymentCardDisable" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div> -->
         </div>
         <!-- <div class="payment-card-content">
            <div class="AddBilling-title">
               <h4>Select your payment method</h4>
               <div class="pull-right CloseIcon">
                  <a href="javascript:" class="text-center btn" role="button" >
                  <i class="fa fa-close"></i>
                  </a>
               </div>
            </div>
         </div> -->
         <div class="PaymentCart">
            <div class="no-gutters row">
               <div class="col-md-6 col-lg-6 col-xl-6 leftCard">
                  
                  <div class="Total-content-wrapper">
                     <div class="Total-content-left">
                        <p>Bill estimate date</p>
                     </div>
                     <div class="Total-content-right">
                        <p><span  innerHtml="{{todayDate | ordinalDate}} - {{nextBillingDate | ordinalDate}}"></span></p>
                     </div>
                  </div>
                  <div class="Total-content-wrapper">
                     <div class="Total-content-left">
                        <p>Order summary</p>
                     </div>
                     <div class="Total-content-right">        
                        <p>£{{CurrentPlan.plan.price}}</p>
                     </div>
                  </div>
                  <div class="Total-content-wrapper">
                     <div class="Total-content-left">
                        <p>Membership</p>
                      
                     </div>
                     <div class="Total-content-right">
                        <p>  <a href="javascript:;">({{CurrentPlan.plan.plan_name}})</a></p>
                     </div>
                  </div>
                  <hr>
                  <div class="Total-content-wrapper">
                     <div class="Total-content-left">
                        <p>Taxes</p>
                     </div>
                     <div class="Total-content-right">
                        <p>£0.00</p>
                     </div>
                  </div>
                  <div class="Total-content-wrapper">
                     <div class="Total-content-left">
                        <p>Total Amount (including tax)</p>
                     </div>
                     <div class="Total-content-right">
                        <p>£{{CurrentPlan.plan.price}}</p>
                     </div>
                  </div>
                  
                  </div>
               <!-- <div class="col-sm-6 col-md-6 col-xl-6 RightCard">
                  <div class="payment-info">
                     <p class="type">Add new credit card</p>
                     <p class="type">Current credit card</p>
                     <label class="radio"> <input type="radio" name="card" value="payment" checked> <span><img width="30" src="https://img.icons8.com/color/48/000000/mastercard.png" /></span> </label>
                     <label class="radio"> <input type="radio" name="card" value="payment"> <span><img width="30" src="https://img.icons8.com/officel/48/000000/visa.png" /></span> </label>
                     <div class="InputArea">
                        <label class="credit-card-label">Name of card holder</label>
                        <input type="text" class="form-control credit-inputs" placeholder="Name">
                     </div>
                     <div class="InputArea">
                        <label class="credit-card-label">Credit card number</label>
                        <input type="text" class="form-control credit-inputs" placeholder="0000 0000 0000 0000">
                     </div>
                     <div class="row InputArea">
                        <div class="col-md-6">
                           <label class="credit-card-label">Expiration</label>
                           <input type="text" class="form-control credit-inputs" placeholder="12/24">
                        </div>
                        <div class="col-md-6">
                           <label class="credit-card-label">CVV</label>
                           <input type="text" class="form-control credit-inputs" placeholder="342">
                        </div>
                     </div>
                     <div class="text-center d-block card_footer">
                        <button class="mr-3 text-Make btn ">Make Payment</button>
                        <button class="text-Cancel btn ">Cancel</button>
                     </div>
                  </div>
                  </div> -->
               <div class="col-md-6 col-lg-6 col-xl-6 RightCard">
                  <div class="payment-info">
                     <ul class="nav nav-tabs">
                        <li class="nav-item"><a class="nav-link" [ngClass]="existCards ? 'show' : ''" [ngClass]="existCards ? 'active' : ''" data-toggle="tab" href="#CurrentCard">Current card</a></li>
                        <li class="nav-item"><a class="nav-link" [ngClass]="!existCards ? 'show' : ''" [ngClass]="!existCards ? 'active' : ''" data-toggle="tab" href="#AddCard">Add new card</a></li>
                     </ul>
                     <div class="tab-content">
                        <div class="tab-pane" [ngClass]="existCards ? 'show' : ''" [ngClass]="existCards ? 'active' : ''" id="CurrentCard">
                           <div class="row">
                              <div class="col-sm-12 col-md-6 col-lg-6 mb-3 " *ngFor="let cardlist of UserCardList; let i=index;">
                                 <div class="pymentCard" >
                                    <p>
                                       Brand - {{cardlist.card_brand}}
                                    </p>
                                    <p>
                                       Card No - {{cardlist.card_number}}
                                       <span class="default_span" *ngIf="cardlist.is_default==1"> Default</span> 
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane show" id="AddCard" [ngClass]="!existCards ? 'show' : ''" [ngClass]="!existCards ? 'active' : ''">
                           <form [formGroup]="userCardForm" enctype="multipart/form-data" class="cc-form" (ngSubmit)="onSubmitUserCard()" novalidate>
                           <div class="InputArea">
                              <label class="credit-card-label">Name of card holder</label>
                              <input type="text" class="form-control credit-inputs" formControlName="card_holder" placeholder="Name" [ngClass]="{ 'is-invalid': submitted && uc.card_holder.errors }" (keypress)="keyPressCharacters($event)" >
                              <div *ngIf="userCardForm.controls['card_holder'].invalid && (userCardForm.controls['card_holder'].dirty || userCardForm.controls['card_holder'].touched)" class="alert">
                                 <div *ngIf="userCardForm.controls['card_holder'].errors.required" class="text-danger">
                                    Please enter card holder name
                                 </div>
                                 <div *ngIf="userCardForm.controls['card_holder'].errors.pattern" class="text-danger">
                                    Please enter only characters
                                 </div>
                             </div>
                           </div>
                           <div class="InputArea">
                              <label class="credit-card-label">Credit card number</label>
                              <input type="tel" formControlName="card_number" placeholder="0000 0000 0000 0000"
                              autocomplete="cc-number" id="cc-number" class="form-control credit-inputs" type="tel" ccNumber [ngClass]="{ 'is-invalid': submitted && uc.card_number.errors }" maxlength="19"/>
                              <div *ngIf="userCardForm.controls['card_number'].invalid && (userCardForm.controls['card_number'].dirty || userCardForm.controls['card_number'].touched)" class="alert">
                                 <div *ngIf="userCardForm.controls['card_number'].errors" class="text-danger">
                                    Please enter valid card number
                                 </div>
                                 
                              </div>
                           </div>
                           <div class="row InputArea">
                              <div class="col-md-6">
                                 <label class="credit-card-label">Expiration</label>
                                 <input type="tel" class="form-control credit-inputs" formControlName="expire_date" placeholder="MM/YY" [ngClass]="{ 'is-invalid': submitted && uc.expire_date.errors }" autocomplete="cc-exp" ccExp >
                                 <!-- <input id="cc-exp-date" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp> -->
                                 <div *ngIf="userCardForm.controls['expire_date'].invalid && (userCardForm.controls['expire_date'].dirty || userCardForm.controls['expire_date'].touched)" class="alert">
                                    <div *ngIf="userCardForm.controls['expire_date'].errors" class="text-danger">
                                       Invalid Expiry Date
                                    </div>
                                    
                              </div>
                              </div>
                              <div class="col-md-6">
                                 <label class="credit-card-label">CVV</label>
                                 <input type="password" class="form-control credit-inputs" formControlName="cvv" placeholder="***" [ngClass]="{ 'is-invalid': submitted && uc.cvv.errors }" minlength="3" maxlength="3" (keypress)="keyPressNumbers($event)">
                                 <div *ngIf="userCardForm.controls['cvv'].invalid && (userCardForm.controls['cvv'].dirty || userCardForm.controls['cvv'].touched)" class="alert" >
                                    <div *ngIf="userCardForm.controls['cvv'].errors.required" class="text-danger">
                                       Please enter cvv
                                    </div>
                                    <div *ngIf="userCardForm.controls['cvv'].errors.pattern" class="text-danger">
                                       Please enter valid cvv numbers not allowed
                                    </div>
                                </div>
                              </div>
                           </div>
                           <div class="text-center d-block card_footer">
                              <!-- <button class="text-Next btn" (click)="BackButton('step4')">Back</button> -->
                              <button type="submit" class="mr-3 text-Make btn " >Add Card</button>
                              <button type="button" class="text-Cancel btn ">Cancel</button>
                           </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <ng-template #PlanModal let-modal>
         <div class="modal-header">
             <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
             <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
             <div class="Iconwarning-box text-center mb-4" *ngIf="CurrentPlan.length">
                <span class="info-Iconwarning ">  <img src="./assets/assets/images/renew-icon.png" alt="renew-icon"></span>
                <div class="Iconwarning-box-content">
                   <h6>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                      enjoying the benefits of all features by fambizcommunity. 
                   </h6>
                </div>
             </div>
             <div class="row justify-content-center">
                <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                   <div class="single-price-plan mb-100">
                      <div class="price-plan-title">
                         <h4>{{singlePlan.plan_name}}
                         </h4>
                      </div>
                      <div class="price-plan-value">
                         <h2>£{{singlePlan.price}}</h2>
                         <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                      </div>
                        <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                        <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                   </div>
                </div>
                
                
             </div>
          </div>
     </ng-template>
   </div>
</div>