<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/admin-videos-blogs-list']">Videos & Blogs List</a></li>
                        <li class="breadcrumb-item">
                            <span *ngIf="isAddMode">Add Videos & Blogs</span>
                            <span *ngIf="!isAddMode">Edit Videos & Blogs</span></li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="body">
                        <form [formGroup]="CreateForm" enctype="multipart/form-data">
                            <div class="row clearfix">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title <span>*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter title" formControlName="title"/>
                                        <span class="text-danger"
                                            *ngIf="(control.title.dirty || control.title.touched) && control.title.hasError('required')">
                                            Title field is requied.
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Video/Blog<span>*</span></label>
                                        <select class="form-control" formControlName="type" [ngClass]="{ 'is-invalid': control.type.invalid && (control.type.dirty || control.type.touched) }" (change)="clear_value()">
                                            <option value="" selected hidden>Select</option>
                                            <option value="Video">Video</option>
                                            <option value="Blog">Blog</option>
                                        </select>
                                        <span class="text-danger" *ngIf="(control.type.dirty || control.type.touched) && control.type.hasError('required')">
                                            Video/Blog field required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="control.type.value == 'Video'">
                                    <div class="form-group">
                                        <label>Video Link<span>*</span></label>
                                        <input class="form-control" type="text" placeholder="Enter video link" formControlName="video_url">
                                        <span class="text-danger" *ngIf="control.video_url.hasError('required')">
                                            Video Link field required.
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="control.type.value == 'Blog'">
                                    <div class="form-group">
                                        <label>Upload Image<span>*</span></label>
                                        <input type="file" class="form-control" formControlName="image_url"
                                        (change)="onFileChange($event, 'image_url')" accept=".jpg, .jpeg, .png">
                                        <span class="text-danger" *ngIf="control.image_url.hasError('required')">
                                            Image field required.
                                        </span>
                                        <div class="preview-image" *ngIf="imageUrl != '' && control.type.value == 'Blog'">
                                            <img src="{{imageUrl}}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description <span>*</span></label>
                                        <!-- <textarea class="form-control post-style" placeholder="Enter description" formControlName="description" rows="10"></textarea> -->
                                        <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" formControlName="description"></angular-editor>
                                        <span class="text-danger" *ngIf="(control.description.dirty || control.description.touched) && control.description.hasError('required')">
                                            Description field is requied.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-primary" (click)="submit()"
                                [disabled]="!CreateForm.valid"> <!--  -->
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>


    </div>
</div>