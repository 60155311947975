<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <!-- <div class="block-header">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <h2>
                        <a href="javascript:void(0);" class="btn btn-xs btn-link" (click)="backClicked()" >
                            <i class="fa fa-arrow-left"></i>
                        </a> Learning add
                    </h2>
                </div>
            </div>
        </div> -->
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a routerLink="/admin/training-list">Learning Add</a></li>
                        <li class="breadcrumb-item"><span>Learning Add</span></li>
                      </ul>
            
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-md-6">
                <div class="card">
                    <div class="header text-center">
                        <h2 *ngIf="isAddMode">Add Learning</h2>
                        <h2 *ngIf="!isAddMode">Edit Learning</h2>
                        
                    </div>
                    <div class="body">
                        <form [formGroup]="CreateForm" enctype="multipart/form-data">
                            <input class="form-control" type="hidden" formControlName="id" />
                            <div class="form-group">
                                <label>Category <span>*</span></label>
                                <select formControlName="category_id" class="form-control">
                                    <option [ngValue]="null" disabled>Select Category</option>
                                    <option *ngFor="let values of categories" [ngValue]="values.id">
                                        {{values.category_name}}
                                    </option>
                                </select>
                                <span class="text-danger" *ngIf="control.category_id.dirty && control.category_id.hasError('required')">
                                    Category field Required.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Title <span>*</span></label>
                                <input class="form-control" type="text" formControlName="title" />
                                <span class="text-danger"
                                    *ngIf="control.title.dirty && control.title.hasError('required')">
                                    Title field is requied.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Description <span>*</span></label>
                                <textarea name="" class="form-control post-style" formControlName="description"  placeholder="Write Your description">
                                </textarea>
                                <span class="text-danger"
                                  *ngIf="control.description.dirty && control.description.hasError('required')">
                                  Description field is requied.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>PDF File <span>*</span></label>
                                <input type="file" formControlName="attachment" (change)="onFileChange($event, 'attachment')" 
                                    accept=".doc, .docx, .ppt, .pptx, .pdf">
                                <span class="text-danger" *ngIf="control.attachment.dirty && control.attachment.hasError('required')">
                                    File field Required.
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Sample PDF File <span>*</span></label>
                                <input type="file" formControlName="sample_pdf" (change)="onFileChange($event, 'sample_pdf')" 
                                    accept=".doc, .docx, .ppt, .pptx, .pdf">
                                <span class="text-danger" *ngIf="control.sample_pdf.dirty && control.sample_pdf.hasError('required')">
                                    Sample File field Required.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Icon <span>*</span></label>
                                <input type="file" formControlName="icon" (change)="onFileChange($event, 'icon')" 
                                    accept=".jpg, .jpeg, .png">
                                <span class="text-danger" *ngIf="control.icon.dirty && control.icon.hasError('required')">
                                    File field Required.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Hover Icon <span>*</span></label>
                                <input type="file" formControlName="hover_icon" (change)="onFileChange($event, 'hover_icon')" 
                                    accept=".jpg, .jpeg, .png">
                                <span class="text-danger" *ngIf="control.hover_icon.dirty && control.hover_icon.hasError('required')">
                                    File field Required.
                                </span>
                            </div>
        
                            <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="!CreateForm.valid" > <!--  -->
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>


    </div>
</div>