import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var $: any;

// import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-tool4view',
  templateUrl: './tool4view.component.html',
  styleUrls: ['./tool4view.component.css']
})
export class Tool4viewComponent implements OnInit {

  id = this.route.snapshot.params['id'];
  data:any = [];
  user_tool_data:any = [];
  total_opp:any=0;
  total_risk:any=0
  questionList: any = [];
  userFileName:any = null;
  total_score:any=0;

  chartOption:any;

  constructor(private _location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
  
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

    this.authService.getSingleTool(this.id).subscribe((res:any)=>{
      if(res.status) {
        this.data = res.usertool;
        if(res.usertool.user_tool_data.length!=0){
          this.user_tool_data = res.usertool.user_tool_data;
          this.total_score = res.usertool.rating_total
          //this.total_opp = this.data.opp_pro_score+this.data.opp_imp_score;
          //this.total_risk = this.data.risk_pro_score+this.data.risk_imp_score;
          this.userFileName =  res.usertool.tool_user_name;

          this.chartOption = {
            series: [{
              type: 'gauge',
              axisLine: {
                  lineStyle: {
                      width: 30,
                      color: [
                          [0.2, '#d81e99'],
                          [0.4, '#e050af'],
                          [0.6, '#e569bb'],
                          [0.8, '#e050af'],
                          [1, '#d81e99']
                      ]
                  }
              },
              pointer: {
                  itemStyle: {
                      color: 'auto'
                  }
              },
              axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                      color: '#fff',
                      width: 2
                  }
              },
              splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                      color: '#fff',
                      width: 4
                  }
              },
              axisLabel: {
                  color: 'auto',
                  distance: 40,
                  fontSize: 20
              },
              detail: {
                  valueAnimation: true,
                  formatter: '{value}',
                  color: 'auto'
              },
              data: [{
                  value: this.data.rating_total
              }],
              min:-100,
              max:100
          }]
          };
        }else{
          this.toastr.error("tool question score not found", "Error");
        }
      } else {
        this.toastr.error("Single toold data not found", "Error");
      }
    })

    
  }

  public openPDF():void {

    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName); // Generated PDF

    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
      doc.setTextColor(150);
      doc.text(120, doc.internal.pageSize.height/3, 'VT-Touch');
      return doc;
  }

}
