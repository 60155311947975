<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><span>Partners List</span></li>
                    </ul>

                </div>

                <div class="col-lg-7 col-md-8 col-sm-12">
                    <a [routerLink]="['/admin/partner-add']" class="btn pbtn color_cls"> Add </a>


                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="body">
                        <table class="table">
                            <thead>
                                <tr class="table-active">
                                    <th scope="col">S.no</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="partnerList?.length; else no_data_templ">
                                <tr *ngFor="let value of partnerList; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>
                                        {{ value.title }}
                                    </td>
                                    <td>
                                        <span *ngIf="value.icon">
                                            <img [src]="value.icon" width="50" height="50">
                                        </span>
                                        <span *ngIf="!value.icon">
                                            -
                                        </span>
                                    </td>
                                    <td>
                                        <a href="javascript:;" class="btn pbtn"
                                            (click)="editPartner(value.id)">
                                            <i class="fa fa-edit"></i>
                                        </a>&nbsp;
                                        <!-- <a [href]="value.attachment" target="_blank" rel="noopener noreferrer"
                                            [download]="value.title" class="btn btn-primary  pbtn">
                                            <i class="fa fa-download"></i>
                                        </a>&nbsp; -->
                                        <a href="javascript:;" class="btn btn-danger pbtn"
                                            (click)="deletePartner(value.id)">
                                            <i class="fa fa-trash"></i>
                                        </a>

                                    </td>
                                </tr>
                                <tr *ngIf="partnerList.length==0">
                                    <td colspan="3">No Data Found</td>
                                </tr>
                            </tbody>
                            <ng-template #no_data_templ>
                                <tr>
                                    <td colspan="4" class="text-center">No Data Found</td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>

                </div>
            </div>

        </div>


    </div>
</div>