import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authService.logout();
                this.authService.user = '';
                this.authService.userToken = '';
                location.reload(true);
            }

            if (!window.navigator.onLine) {
                // if there is no internet, throw a HttpErrorResponse error
                // since an error is thrown, the function will terminate here
                const error = {
                    status: 0,
                    error: {
                        description: 'Check Connectivity!'
                    },
                    statusText: 'Check Connectivity!'
                };
                this.toastr.error("Check Connectivity!", "Error");
                return throwError(error);
            }

            let error = "Oops!Something went wrong";
            if(err instanceof Array){
                this.toastr.error(err.join(), "Error");
                error = err.join();
            }else{
                error = err.error.message || err.statusText;
                //this.toastr.error(err.error.message, "Error");
            }
            return throwError(error);

            ////const error = err.error.message || err.statusText;
            //return throwError(error);
        }))
    }
}