<nav class="navbar navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-btn">
            <button type="button" class="btn-toggle-offcanvas"><i class="lnr lnr-menu fa fa-bars"></i></button>
        </div>

        <div class="navbar-brand">
            <a href="index.html"><img src="./assets/assets/images/logo.png" alt="Logo"
                class="img-responsive logo"> 
                <!-- <img src="./assets/assets/images/text-logo.png" alt="Logo"
                class="img-responsive logo textlogo"> -->
            </a>
        </div>

        <div class="menu-bar-toggle">
            <!--<a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </a>-->
        </div>

        <div class="navbar-right">
            <div id="navbar-menu">
                <ul class="nav navbar-nav">
                    <li class="dropdown">
                        <a href="javascript:void(0);" class=" Notification dropdown-toggle icon-menu" data-toggle="dropdown">
                            <img src="./assets/assets/images/notification.png"  alt="chat-img">
                            <!-- <span class="badge badge-light" *ngIf="notCount">{{notCount}}</span> -->
                            <span class="badge badge-light" *ngIf="notCount"></span>
                        </a>
                        <ul class="dropdown-menu notifications">
                            <div class="notification_scroll" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="300" [fromRoot]="true" (scrolled)="onScroll($event)" [scrollWindow]="false">
                                <!-- <li class="header"><strong>You have {{notCount}}Notifications</strong></li> -->
                                <li class="header"> <a href="javascript:void(0);">Notifications <span class="rounded-circle"></span></a></li>

                                <li class="unread" *ngFor="let value of notList">
                                    <a [routerLink]="['/notification/']">
                                        <div class="media">
                                            <div class="media-left ">
                                               <img src="{{value.sender?.profile_pic}}" alt="user image" />
                                            </div>
                                            <div class="media-body">
                                                <h5>{{value.sender?.name}} <span>{{value.created_at}}</span></h5>
                                                <!-- <p>Reacted to your post</p> -->
                                                <p [innerHtml]='value.notification_description'></p>
                                                <!-- <h6 class="unread">How to travel long way home</h6> -->
                                                <!-- <span class="timestamp">{{value.created_at}}</span> -->
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </div>
                            <li class="footer"><a [routerLink]="['/notification']" class="more">View All</a></li>
                        </ul>
                    </li>

                    <li class="dropdown">
                        <a href="javascript:void(0);" class="Notification dropdown-toggle icon-menu" data-toggle="dropdown">
                            <!-- <i class="icon-bubbles"></i> -->
                            <img src="./assets/assets/images/chat.png"  alt="chat-img">
                            <span class="badge badge-light" *ngIf="msgCount"></span>
                            <!-- <span class="badge badge-light" *ngIf="msgCount">{{msgCount}}</span> -->
                        </a>
                        <ul class="dropdown-menu notifications">
                            <div class="msg_scroll" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="300" [fromRoot]="true" (scrolled)="onScrollMsg($event)" [scrollWindow]="false">
                                <!-- <li class="header"><strong>You have {{msgCount}} new Messages</strong></li>  -->
                                <li class="header"> <a href="javascript:void(0);">You have {{msgCount}} new Messages <span class="rounded-circle"></span></a></li>
                                <li *ngFor="let value of messageList">
                                    <a [routerLink]="['/message/', value.m_session_id]">
                                        <div class="media">
                                            <div class="media-left message-data">
                                                <!-- <i class="icon-info text-warning"></i> -->
                                                <img src="{{value.sender?.profile_pic}}" alt="avatar" class="rounded-circle user-photo" width="50" height="50">
                                            </div>
                                            <div class="media-body">
                                                <p class="text">{{value.sender?.name}}</p>
                                                <p class="text">{{value.message}}</p>
                                                <span class="timestamp">{{value.created_at}}</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </div>
                            <li class="footer"><a [routerLink]="['/message']" class="more">See all messages</a></li>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                            <img src="./assets/assets/images/Setting.png"  alt="Setting-img"> 
                            <!-- <i class="icon-equalizer"></i> -->
                        </a>
                        <ul class=" message dropdown-menu user-menu menu-icon">
                            <li><a routerLink="/account-settings"><img src="./assets/assets/images/account-edit.svg"  alt="account-image"> <span>Account Setting</span></a></li>
                            <li><a routerLink="/OurPlan"><img src="./assets/assets/images/upgrade-renew.svg"  alt="upgrade-renew-img"> <span>Upgrade/Renew</span></a></li>
                            <!-- <li><a href="javascript:void(0);"><i class="icon-lock"></i> <span>Privacy</span></a></li>
              <li><a href="javascript:void(0);"><i class="icon-bell"></i> <span>Notifications</span></a></li>
              <li class="menu-heading">BILLING</li>
              <li><a href="javascript:void(0);"><i class="icon-credit-card"></i> <span>Payments</span></a></li>
              <li><a href="javascript:void(0);"><i class="icon-printer"></i> <span>Invoices</span></a></li>
              <li><a href="javascript:void(0);"><i class="icon-refresh"></i> <span>Renewals</span></a></li> -->
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" (click)="logout()" class="icon-menu"><img src="./assets/assets/images/logout.png"  alt="logout-img"> </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>