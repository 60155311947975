<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
              <!-- <h2> Member Details
              </h2> -->
              <ul class="breadcrumb vt-a">
                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                <li class="breadcrumb-item">Network</li>
                <li class="breadcrumb-item"><a [routerLink]="['/vt-members']">Subscribers</a></li>
                <li class="breadcrumb-item">Subscriber Details</li>
              </ul>
            </div>
          </div>
        </div>



        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card crd-brd">
                    <div class="main-profl row">
                        <div class="col-md-7 Rightline">
                            <div class="img-prrt1 clearfix">
                                <div class="i-pr1">
                                    <span><img [src]="[imageURL]" alt="user" class="rounded-circle" *ngIf="imageURL ; else dummyImage" /></span>
                                    <ng-template #dummyImage>
                                      <span><img src="./assets/assets/images/user.png" alt="user" class="rounded-circle" width="50" height="50" /></span>
                                    </ng-template>
                                </div>
                                <div class="contehnt-pr2">
                                    <h3 class="hd-con">
                                        {{user?.name}}
                                    </h3>
                                    <!-- <p class="dsg-ttl" *ngIf="user?.industry_id">{{industry_name}}</p> -->
                                    <p>{{summary_title}}</p>
                                    <!--<p class="cont-btns mt-4 mb-4">
                                        <a href="" class="brn-pnkbdr"><button type="button" class="btn pbtn"> Add Profile a Section</button></a>
                                        <a href=""><button type="button" class="btn pbtn"> More</button></a>
                                    </p>-->
                                      <p class="per-text"> {{user?.profile_percentage | number : '1.0-0'}}%</p>
                                      <div class="progress progress-xs">
                                        <div class="progress-bar progress-bar-warning" role="progressbar" attr.aria-valuenow="{{user?.profile_percentage}}"
                                          aria-valuemin="0" aria-valuemax="100" [style.width]="profilePercentage"> <span
                                            class="sr-only">{{user?.profile_percentage}}% Complete</span> </div>
                                      </div>

                                    <button *ngIf="!sameUser && !isFreeTrial" class="btn btn-vt" (click)="OpenMessageModal(user?.id, MessageModal)">Contact</button>
                                    <button *ngIf="!sameUser && isFreeTrial" class="btn btn-vt opacity75" (click)="OpenPlanPopup(PlanModal)">Contact</button>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="indus-content">
                                <ul>
                                    <li>
                                        <span class="rightHeading" style="font-weight: 500;">Industry :</span>
                                        <span *ngIf="user?.industry_id"> {{industry_name}}</span>
                                    </li>
                                    <!-- <li>
                                        <span>Experience Year :</span>
                                        <span *ngIf="user?.total_experience!=null"> {{user?.total_experience}}</span>
                                        <span *ngIf="user?.total_experience==null"> - </span>
                                    </li> -->
                                    <li>
                                      <span class="rightHeading" style="font-weight: 500;">Family or Non - Family Business :</span>
                                      <!-- <span *ngIf="user?.total_experience!=null"> {{user?.total_experience}}</span>
                                      <span *ngIf="user?.total_experience==null"> - </span> -->
                                      <span>Yes</span>
                                  </li>
                                    <li class="icn-indus">
                                      <span class="rightHeading" style="font-weight: 500;">Email : </span>
                                        <span>{{user?.email}}</span>
                                    </li>
                                    <li class="icn-indus">
                                      <span class="rightHeading" style="font-weight: 500;">Country : </span>
                                        <span>{{state_name}}, {{country_name}}</span>
                                    </li>
                                    <li>
                                      <p class="ic-img-cn">
                                        <span class="rightHeading" style="font-weight: 500;">Social Media Handles : </span>
                                        <a *ngIf="user?.linkedin_id!=null" href="{{ user?.linkedin_id }}"
                                            target="_blank"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                                        <!-- <a href="{{ user?.fb_id }}" target="_blank"><img src="./assets/assets/images/fb-hvr.png"></a> -->
                                        <a *ngIf="user?.fb_id!=null" href="{{ user?.fb_id }}" target="_blank"><img
                                                src="./assets/assets/images/twit-hvr.png"></a>

                                        <a href="javascript:" class="text-center" role="button"
                                            aria-expanded="false" *ngIf="sameUser && user?.linkedin_id==null;"
                                            (click)="showSocial()" [ngClass]="isSocialShow ? 'show' : ''">
                                            Add Social Details
                                        </a>
                                        
                                    </p>
                                       
                                    </li>
                               
                                </ul>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="body text-center">
                        <div class="chart easy-pie-chart-1" data-percent="100">
                            <span><img [src]="[imageURL]" alt="user" class="rounded-circle" *ngIf="imageURL ; else dummyImage" /></span>
                            <ng-template #dummyImage>
                                <span><img src="./assets/assets/images/user.png" alt="user" class="rounded-circle" width="50" height="50" /></span>
                            </ng-template>
                        </div>
                        <h2>{{user.name}}</h2>

                      <div *ngIf="sameUser; else notSameUser">
                            <a class="" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                                aria-controls="collapseExample" *ngIf="sameUser && user.industry_id==null;" (click)="clickEvent()" [ngClass]="isIndustryShow ? 'show' : ''">
                                Add your main industry
                            </a>
                            <h5 *ngIf="sameUser&&user.industry_id">{{industry_name}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false" (click)="clickEvent()"
                                    [ngClass]="isHeadlineShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>

                            <br/>
                            <a href="javascript:" class="text-center" role="button" aria-expanded="false" *ngIf="sameUser && user.country_id==null;"
                                (click)="showCountry()" [ngClass]="isCountryShow ? 'show' : ''">
                                Add Location you are currently based at...
                            </a>
                            <h5 *ngIf="sameUser&&user.country_id">
                                <small>Country :</small> {{country_name}} , <small>State :</small> {{state_name}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false" (click)="showCountry()"
                                    [ngClass]="isCountryShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>
                            <br />
                            <a href="javascript:" class="text-center" role="button" aria-expanded="false" *ngIf="sameUser&&summary_title==null ;"
                                (click)="showHeadline()" [ngClass]="isHeadlineShow ? 'show' : ''">
                                Add Headline
                            </a>
                            <h5 *ngIf="sameUser&&summary_title">{{summary_title}}
                                <a href="javascript:" class="text-center" role="button" aria-expanded="false" (click)="showHeadline()" [ngClass]="isHeadlineShow ? 'show' : ''">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </h5>
                        </div>

                        <ng-template #notSameUser>
                            <h5 *ngIf="user.industry_id">{{industry_name}}</h5>
                            <h5 *ngIf="user.country_id"><small>Country :</small> {{country_name}} , <small>State :</small> {{state_name}}</h5>
                            <h5 *ngIf="summary_title">{{summary_title}}</h5>
                        </ng-template>

                        <div>
                            <h5 *ngIf="user.industry_id">{{industry_name}}</h5>
                            <h5 *ngIf="user.country_id"><small>Country :</small> {{country_name}} , <small>State :</small> {{state_name}}</h5>
                            <h5 *ngIf="summary_title">{{summary_title}}</h5>
                        </div>
                        <div *ngIf="loginRole==1&&!editUserRole">
                            <button type="button" class="btn btn-primary" (click)="editUser()">Edit User Role</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->

        <div class="body">
            <div class="row">
                <div class="col-md-12">
                    <div class="collapse"  [ngClass]="isIndustryShow ? 'show' : ''"> <!-- id="collapseExample" -->
                        <div class="card card-body">
                            <form [formGroup]="editForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <label>Industry </label>
                                    <select formControlName="industry_id" class="form-control" [(ngModel)]="SelectedIndustry_id">
                                        <option [ngValue]="null" disabled>Select Industry</option>
                                        <option *ngFor="let industry of industryList" [ngValue]="industry.id">{{industry.title}}</option>
                                    </select>
                                    <span class="text-danger" *ngIf="control.industry_id.dirty && control.industry_id.hasError('required')">
                                        Industry field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample"
                                    aria-expanded="false" aria-controls="collapseExample" (click)="cancelEvent()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5" (click)="submit()" [disabled]="!editForm.valid">
                                    save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isCountryShow ? 'show' : ''" >
                        <div class="card card-body" *ngIf="(isCountryShow)">
                            <form [formGroup]="countryForm" enctype="multipart/form-data" >
                                <div class="form-group">
                                    <label>Country </label>
                                    <select formControlName="country_id" class="form-control" [(ngModel)]="Selectedcountry_id" (change)="onChangeCountry($event.target.value)">
                                        <option [ngValue]="null" disabled>Select Country</option>
                                        <option *ngFor="let country of countryList" [ngValue]="country.id">{{country.name}}</option>
                                    </select>
                                    <span class="text-danger" *ngIf="ccontrol.country_id.dirty && ccontrol.country_id.hasError('required')">
                                        Country field Required.
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label>State </label>
                                    <select formControlName="state_id" class="form-control" [(ngModel)]="Selectedstate_id">
                                        <option [ngValue]="null" disabled>Select State</option>
                                        <option *ngFor="let state of stateList" [ngValue]="state.id">{{state.name}}</option>
                                    </select>
                                    <span class="text-danger" *ngIf="ccontrol.state_id.dirty && ccontrol.state_id.hasError('required')">
                                        State field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample"
                                    aria-expanded="false" aria-controls="collapseExample" (click)="cancelCountry()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5" (click)="saveCountry()" [disabled]="!countryForm.valid">
                                    save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="collapse" [ngClass]="isHeadlineShow ? 'show' : ''">
                        <div class="card card-body" *ngIf="(isHeadlineShow)">
                            <form [formGroup]="headlineForm" enctype="multipart/form-data">
                                <div class="form-group">
                                    <label>Summery Headline </label>
                                    <input class="form-control" type="text" formControlName="summary_title" [(ngModel)]="summary_title" />
                                    <span class="text-danger" *ngIf="hcontrol.summary_title.dirty && hcontrol.summary_title.hasError('required')">
                                        Headline field Required.
                                    </span>
                                </div>
                                <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample"
                                    aria-expanded="false" aria-controls="collapseExample" (click)="cancelHeadline()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary m-l-5" (click)="saveHeadline()" [disabled]="!headlineForm.valid">
                                    save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card" *ngIf="editUserRole">
                    <div class="card-body" *ngIf="editUserRole">
                        <form [formGroup]="editUserForm" enctype="multipart/form-data">
                            <div class="form-group">
                                <label>User Role <span>*</span></label>
                                <select formControlName="user_role" class="form-control">
                                    <option [ngValue]="null" disabled>Select role</option>
                                    <option *ngFor="let values of roleList" [ngValue]="values.id">{{values.name}}</option>
                                </select>
                                <span class="text-danger" *ngIf="eUserControl.user_role.dirty && eUserControl.user_role.hasError('required')">
                                    User Role field Required.
                                </span>
                            </div>
                            <input type="hidden" formControlName="id">
                            <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample"
                                aria-expanded="false" aria-controls="collapseExample" (click)="cancelEditUser()">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary m-l-5" (click)="updateUserRole()" [disabled]="!editUserForm.valid">
                                save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active show" data-toggle="tab" href="#Summary">Summary</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Experience">Experience</a></li>
            <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Education">Education</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Certificate">Certification</a></li> -->
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Achievement">Qualifications</a></li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane show active" id="Summary">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card" >
                    <div class="card-body card-summary-vt" *ngIf="user?.summary_description">
                      <h5>Summary</h5>
                      <p [innerHTML]="user.summary_description"></p>
                    </div>
                    <div class="card-body card-summary-vt" *ngIf="user?.summary_description==null">
                        <h5>Summary</h5>
                        <label class="px-3">No Summary Found</label>
                    </div>
                  </div>
                </div>
              </div>
            </div><!--./end summary-->
            <div class="tab-pane" id="Experience">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-experiences-vt">
                    <h5>Experience</h5>
                    <div class="card-body" *ngFor="let value of experiences; let j=index;let last = last;">
                    <span><h6>{{value.job_title}} </h6><p>{{j+1}}</p></span>
                    <div class="col-md-12 col-sm-12">
                      <div class="row">
                        <div class="col-md-4 col-sm-4">
                          <div class="list-group">
                            <ul>
                            <li><b>Company:</b> {{value.company}}</li>
                            <li><b>Start Date:</b> <em *ngIf="value.start_month!=null">{{ getMonth(value.start_month) }}, {{value.start_year}}</em></li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                          <div class="list-group">
                            <ul>
                              <li><b>Location:</b> {{value.location}}</li>
                              <span *ngIf="value.is_current==1" >
                                <li><b>Currently working: </b> Yes </li>
                              </span>
                              <span *ngIf="value.is_current==0">
                                <li><b>End Date:</b> <em *ngIf="value.end_month!=null">{{ getMonth(value.end_month) }}, {{value.end_year}}</em></li>
                              </span>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                          <label>Description</label>
                          <p [innerHTML]="value.description"></p>
                    </div>
                    <hr *ngIf="!last">
                    </div>
                    <div class="card-body" *ngIf="experiences.length==0">
                      <div class="col-md-12 col-sm-12">
                        <label>No Experience Found</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div><!--./end Profile-->
            <div class="tab-pane" id="Education">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-experiences-vt">
                    <h5>Education</h5>
                    <div class="card-body" *ngFor="let value of educations; let k=index;let last = last">
                      <span>
                        <h6>{{value.qualification}} </h6>
                        <p>{{k+1}}</p>
                      </span>

                      <div class="col-md-12 col-sm-12">
                        <div class="row">
                          <div class="col-md-4 col-sm-4">
                            <div class="list-group">
                              <ul>
                                <li><b>Institution/College:</b> {{value.institution}}</li>

                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-4">
                            <div class="list-group">
                              <ul>
                                <li><b>Start Date:</b> <em *ngIf="value.completion_month!=null">{{ getMonth(value.completion_month) }},
                                    {{value.completion_year}}</em></li>
                                <li><b>Currently Studing:</b>
                                  <em *ngIf="value.is_ongoing==1">Yes</em>
                                  <em *ngIf="value.is_ongoing==0">No</em>
                                </li>
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-md-12 col-sm-12">
                        <label>Description</label>
                        <p [innerHTML]="value.description"></p>
                      </div>
                      <hr *ngIf="!last">
                    </div>
                    <div class="card-body" *ngIf="educations.length==0">
                      <div class="col-md-12 col-sm-12">
                        <label>No Education Found</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!--./end Education-->
            <div class="tab-pane" id="Certificate">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-experiences-vt">
                    <h5>Certification</h5>
                    <div class="card-body" *ngFor="let value of certificates; let k=index;let last = last">
                      <span>
                        <h6>{{value.name}} </h6>
                        <p>{{k+1}}</p>
                      </span>


                      <div class="col-md-12 col-sm-12">
                        <div class="row">
                          <div class="col-md-4 col-sm-4">
                            <div class="list-group">
                              <ul>
                                <li><b>Authority:</b> {{value.authority}}</li>
                                <li><b>Start Date:</b> <em *ngIf="value.start_month!=null">{{ getMonth(value.start_month) }}, {{value.start_year}}</em></li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-4">
                            <div class="list-group">
                              <ul>
                                <li><b>License Number:</b> {{value.licence_number}}</li>
                                <li>
                                  <span *ngIf="value.is_ongoing==1">
                                    <b>Currently Studing:</b> <em *ngIf="value.is_ongoing==1"> Yes</em>
                                  </span>
                                  <span *ngIf="value.is_ongoing==0">
                                    <b>End Date:</b> <em *ngIf="value.end_month!=null">{{ getMonth(value.end_month) }}, {{value.end_year}}</em>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-md-12 col-sm-12">
                        <label><b>URL</b></label>
                        <p><a href="{{value.url}}" target="_blank">{{value.url}}</a></p>
                      </div>

                      <hr *ngIf="!last">
                    </div>
                    <div class="card-body" *ngIf="certificates.length==0">
                      <div class="col-md-12 col-sm-12">
                        <label>No Certification Found</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--./end Certificate-->
            <div class="tab-pane" id="Achievement">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card card-experiences-vt">
                    <h5>Qualifications</h5>
                    <div class="card-body" *ngFor="let value of achievements; let k=index;let last = last">
                      
                      

                      <div class="col-md-12 col-sm-12">
                       
                        <p [innerHTML]="value.description"></p>
                      </div>

                      <hr *ngIf="!last">
                    </div>
                    <div class="card-body" *ngIf="achievements.length==0">
                      <div class="col-md-12 col-sm-12">
                        <label>No Qualifications Found</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!--./end Achievement-->
          </div>
        </div>

        <ng-template #MessageModal let-modal>
          <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Send Message</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body save-box">
              <div *ngIf="isSend">
                  <form [formGroup]="sendMessage" enctype="multipart/form-data">
                      <div class="form-group">
                          <!-- <input type="text" class="form-control" placeholder="Write Your message" formControlName="message_description"> -->
                          <textarea class="form-control" placeholder="Write Your message" formControlName="message_description"></textarea>
                          <span class="text-danger"
                              *ngIf="FormControl.message_description.dirty && FormControl.message_description.hasError('required')">
                              This field is requied.
                          </span>
                          <input type="hidden" class="form-control" formControlName="reciver_user_id">
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn pbtn" (click)="SendMessageStore()">Send</button>
                    </div>
                  </form>
              </div>
          </div>
      </ng-template>
      
    <ng-template #PlanModal let-modal>
      <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">You have to upgrade your plan</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
             <span aria-hidden="true">&times;</span>
           </button>
       </div>
       <div class="modal-body">
          <div class="Iconwarning-box mb-4">
             <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
             <div class="Iconwarning-box-content">
                <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                   enjoying the benefits of all features by VTTouch. 
                </p>
             </div>
          </div>
          <div class="row justify-content-center">
             <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                <div class="single-price-plan mb-100">
                   <div class="price-plan-title">
                      <h4>{{singlePlan.plan_name}}
                      </h4>
                   </div>
                   <div class="price-plan-value">
                      <h2>£{{singlePlan.price}}</h2>
                      <p>{{singlePlan.duration}} {{singlePlan.duration_type}} DAYS</p>
                   </div>
                     <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                     <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                </div>
             </div>
             
             
          </div>
       </div>
  </ng-template>

    </div>
</div>
