<div *ngIf="userRole==null">
    <div id="left-sidebar" class="sidebar">
        <div class="sidebar-scroll">
            <div class="user-account">
                <div class="dropdown">
                    <span>Welcome,</span>
                    <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
                    <!-- <ul class="dropdown-menu dropdown-menu-right account">
            <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
            <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
            </li>
            <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
            <li class="divider"></li>
            <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
          </ul> -->
                </div>
                <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo"
                    alt="User Profile Picture" width="50" height="50">
                <div class="profile-update-info">
                    <h6>To Update your profile <br> <a href="javascript:void(0);" (click)="updateUser()">Click here</a>
                    </h6>
                </div>
            </div>
            <nav id="left-sidebar-nav" class="sidebar-nav">
                <ul metis-menu>
                    <!-- id="main-menu" class="metismenu" -->
                    <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                        <a href="javascript:" (click)="goToPage('dashboard')"><i class="icon-home"></i>
                            <span>Dashboard</span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div *ngIf="userRole==2">
    <div id="left-sidebar" class="sidebar">
        <div class="sidebar-scroll">
            <div class="user-account">
                <div class="dropdown">
                    <span>Welcome,</span>
                    <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
                    <!-- <ul class="dropdown-menu dropdown-menu-right account">
              <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
              <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a></li>
              <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
              <li class="divider"></li>
              <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
            </ul> -->
                </div>
                <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo"
                    alt="User Profile Picture" width="50" height="50">
                <div class="profile-update-info">
                    <h6>To Update your profile<br> <a href="javascript:void(0);" (click)="updateUser()">Click here</a>
                    </h6>
                </div>
            </div>
            <nav id="left-sidebar-nav" class="sidebar-nav">
                <ul metis-menu>
                    <!-- id="main-menu" class="metismenu" -->
                    <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                        <a href="javascript:" (click)="goToPage('dashboard')"><i class="icon-home"></i>
                            <span>Dashboard</span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div *ngIf="userRole==3">
    <div id="left-sidebar" class="sidebar">
        <div class="sidebar-scroll">
            <div class="user-account">
                <div class="dropdown">
                    <span>Welcome,</span>
                    <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
                    <!-- <ul class="dropdown-menu dropdown-menu-right account">
              <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
              <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a></li>
              <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
              <li class="divider"></li>
              <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
            </ul> -->
                </div>
                <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo"
                    alt="User Profile Picture" width="50" height="50">
                <div class="profile-update-info">
                    <h6>To Update your profile <br> <a href="javascript:void(0);" (click)="updateUser()">Click here</a>
                    </h6>
                </div>
            </div>
            <nav id="left-sidebar-nav" class="sidebar-nav">
                <ul id="main-menu" class="metismenu">
                    <!-- id="main-menu" class="metismenu" -->
                    <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                        <a href="javascript:" (click)="goToPage('dashboard')"><i class="icon-home"></i>
                            <span>Dashboard</span></a>
                    </li>
                    <!-- <li class="" [routerLink]="['/userslist']" [routerLinkActive]="'active'">
                  <a href="javascript:" (click)="goToPage('userslist')"><i class="icon-users"></i> <span>User List</span></a>
                </li> -->
                    <li>
                        <a href="#" class="has-arrow"><i class="icon-envelope"></i> <span>Learn</span></a>
                        <ul>
                            <li>
                                <a href="javascript:">
                                    <span>Generic Skills</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:">
                                    <span>Testing Skills</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:">
                                    <span>Soft Skills</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:">
                                    <span>Training</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a href="#" class="has-arrow"><i class="icon-bar-chart"></i> <span>Network</span></a>
                        <ul [routerLinkActive]="['mm-show']">
                            <li [routerLink]="['/vt-members']" [routerLinkActive]="['active', 'mm-show']">
                                <a href="javascript:" (click)="goToPage('vt-members')">
                                    <i class="icon-users"></i><span>Members</span>
                                </a>
                            </li>
                            <li [routerLinkActive]="'active'">
                                <a routerLink="/vt-events">
                                    <i class="icon-calendar"></i><span>Events</span></a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#" class="has-arrow"><i class="icon-user"></i> <span>Grow</span></a>
                        <ul>
                            <li>
                                <a href="javascript:">
                                    <span>Job Search</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:">
                                    <span>CV/LinkedIN</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:">
                                    <span>Coaching</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a routerLink="/tools"><i class="icon-grid"></i> <span>Tools</span></a>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a href="javascript:" routerLink="/deal-room">
                            <i class="icon-users"></i> <span>Chat room</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:"><i class="icon-globe"></i> <span>Upgrade / Renew</span></a>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a routerLink="/account-settings"><i class="icon-settings"></i> <span>Account
                                Settings</span></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div *ngIf="userRole==1">
    <div id="left-sidebar" class="sidebar">
        <div class="sidebar-scroll">
            <div class="user-account">
                <div class="dropdown">
                    <span>Welcome,</span>
                    <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
                    <!-- <ul class="dropdown-menu dropdown-menu-right account">
            <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
            <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
            </li>
            <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
            <li class="divider"></li>
            <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
          </ul> -->
                </div>
                <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo"
                    alt="User Profile Picture" width="50" height="50">
                <div class="profile-update-info">
                    <h6>To Update your profile <br> <a href="javascript:void(0);" (click)="updateUser()">Click here</a>
                    </h6>
                </div>
            </div>
            <nav id="left-sidebar-nav" class="sidebar-nav">
                <ul metis-menu>
                    <!--  id="main-menu" class="metismenu" -->
                    <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                        <a href="javascript:" (click)="goToPage('dashboard')"><i class="icon-home"></i>
                            <span>Dashboard</span></a>
                    </li>
                    <!-- <li class="" [routerLink]="['/userslist']" [routerLinkActive]="'active'">
                  <a href="javascript:" (click)="goToPage('userslist')"><i class="icon-users"></i> <span>User List</span></a>
                </li> -->
                    <li>
                        <a href="javascript:"><i class="icon-envelope"></i> <span>Learn</span></a>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a href="javascript:" class="has-arrow"><i class="icon-bar-chart"></i> <span>Network</span></a>
                        <ul [routerLinkActive]="['mm-show']">
                            <li [routerLink]="['/vt-members']" [routerLinkActive]="['active', 'mm-show']">
                                <a href="javascript:" (click)="goToPage('vt-members')">
                                    <i class="icon-users"></i><span>Members</span>
                                </a>
                            </li>
                            <li [routerLinkActive]="'active'">
                                <a routerLink="/event-list">
                                    <i class="icon-users"></i><span>Events</span></a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:"><i class="icon-user"></i> <span>Grow</span></a>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a routerLink="/tools"><i class="icon-grid"></i> <span>Tools</span></a>
                    </li>
                    <li>
                        <a href="javascript:"><i class="icon-users"></i> <span>Chat room</span></a>
                    </li>

                </ul>
            </nav>
        </div>
        <div class="bottom-side" style="margin-top:30px;">
            <nav class="margin-top-side">
                <ul>
                    <li>
                        <a href="javascript:"><i class="icon-globe"></i> <span>Upgrade / Renew</span></a>
                    </li>
                    <li [routerLinkActive]="'active'">
                        <a routerLink="/account-settings"><i class="icon-settings"></i> <span>Account
                                Settings</span></a>
                    </li>
                </ul>>
            </nav>
        </div>
    </div>
</div>