import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {

  page = 1;
  count = 0;
  tableSize = 7;
  tableSizes = [3, 6, 9, 12];
  isFreeTrial:boolean = false;
  nextBillingDate=new Date();
  CurrentPlan:any;
  planData:any;
  CurrentPlanID=0;
  closeResult: string;
  roleId = null;

  modalReference: NgbModalRef;

 /*  toolData = [
        { name: 'Tool 1', id: '1' },
        { name: 'Tool 2', id: '2' },
        { name: 'Tool 3', id: '3' },
        { name: 'Tool 4', id: '4' },
        { name: 'Tool 5', id: '5' },
        { name: 'Tool 6', id: '6' },
        { name: 'Tool 7', id: '7' },
        { name: 'Tool 8', id: '8' },
        { name: 'Tool 9', id: '9' },
        { name: 'Tool 10', id: '10' },
        { name: 'Tool 11', id: '11' },
        { name: 'Tool 12', id: '12' }
    ]; */
  toolData = [];
  constructor(private _location: Location,  private router: Router, private route: ActivatedRoute, private authService: AuthService,
    private toastr: ToastrService,private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.authService.user) {
      this.roleId = this.authService.user.user_role;
    }
    this.authService.getAllTool().subscribe((res:any)=>{
        if(res.status) {
          this.toolData = res.tools;
        } else {
          
        }
      })
    this.authService.getUserActivePlan().subscribe((res:any)=>{
        if(res.status) {
          if(res.current_active_plan.plan_id == 1){
              this.isFreeTrial = true
          }
          console.log(res,'response --------');
        } else {
          
        }
      })
      this.getCurrentPlan()
      this.getAllPlanList()
  }

  getCurrentPlan(){
    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        console.log(res,'current plan ')
        this.CurrentPlanID=res.current_active_plan.plan_id
          this.CurrentPlan = res.current_active_plan
          this.nextBillingDate = new Date();
            if(this.CurrentPlan.plan.duration_type == 'Days'){
              this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration); 
            }else if(this.CurrentPlan.plan.duration_type == 'Years'){
              this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration); 
            }
          console.log(this.CurrentPlan)
          // this.router.navigateByUrl('dashboard');
      } else {
        if (this.roleId == 1) {
          this.isFreeTrial = false;
        } else {
         // this.router.navigateByUrl('OurPlan');
        }
      }
    }, err=> {
      if (this.roleId == 1) {
        this.isFreeTrial = false;
      } else {
        //this.router.navigateByUrl('OurPlan');
      }
    })
  }

  getAllPlanList(){
    this.authService.getAllPlanList({}).subscribe((res:any)=>{
      if(res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }
  
  OpenPlanPopup(modalId){
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

  backClicked() {
    this._location.back();
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
  } 
  
  onTableDataChange(event){
    this.page = event;
  }

  gotomaster(id, i){
      // if(i==1){
      //   this.router.navigateByUrl('/project-tool/'+id);
      // }else{
      //   this.router.navigateByUrl('/mastertools/'+id);
      // }

      if(i==1){
        this.router.navigateByUrl('/mastertools/'+id);
      }else if(i==2){
        this.router.navigateByUrl('/project-tool/'+id);
      }else if(i==3){
        this.router.navigateByUrl('/tools/tool'+i+'/'+id);
      }else if(i==4){
        this.router.navigateByUrl('/tools/tool'+i+'/'+id);
      }else{
        this.toastr.error("something wrong", "Error");
      }
  }

  viewReport(id, i){
      // if(i==1){
      //   this.router.navigateByUrl('project-tool-view/'+id);
      // }else{
      //   this.router.navigateByUrl('viewtool/'+id);
      // }
      if(i==1){
        this.router.navigateByUrl('viewtool/'+id);
      }else if(i==2){
        this.router.navigateByUrl('project-tool-view/'+id);
	// }else if(i==3){
    //     this.toastr.success("Comming Soon", "Success");
      }else if(i==4 || i== 3 ){
        this.router.navigateByUrl('/tools/tool'+i+'view/'+id);
      }else{
        this.toastr.error("something wrong", "Error");
      }
  }

  DeleteReport(id,i){
	if(i==10){
        this.toastr.success("Comming Soon", "Success")
	}else{
    Swal.fire({
      title: 'Are you sure want to delete this report?',
      text: 'Once you delete it, you will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/delete-icon.png" alt="delete-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, save it!'
      }).then((result) => {
          if (result.value) {
              this.authService.deleteUserTool(id).subscribe((res:any)=>{
                  if(res.status) {
                    this.toolData[0].user_tools = this.toolData[0].user_tools.filter(item => item.id !== id);
                    this.toolData[1].user_tools = this.toolData[1].user_tools.filter(item => item.id !== id);
                    this.toolData[2].user_tools = this.toolData[2].user_tools.filter(item => item.id !== id);
                    this.toolData[3].user_tools = this.toolData[3].user_tools.filter(item => item.id !== id);
                    this.toastr.success('User report deleted successfully', "Success");
                  } else {
                    this.toastr.error("User report delete error", "Error");
                  }    //this.router.navigateByUrl('tools');
              })
          }
    })
}
  }

}
