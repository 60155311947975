<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item">
              <a [routerLink]="['/Admin-Dashboard']">
                <i class="icon-home"></i>
              </a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/admin/jobs">Jobs</a>
            </li>
            <li class="breadcrumb-item" *ngIf="isAddMode">Company Add</li>
            <li class="breadcrumb-item" *ngIf="!isAddMode">Company Edit</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-md-12">
        <div class="card">
          <div class="header text-center">
            <h2 *ngIf="isAddMode">Add Company</h2>
            <h2 *ngIf="!isAddMode">Edit Company</h2>
          </div>
          <div class="body">
            <form [formGroup]="AddCompanyForm" enctype="multipart/form-data">
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company Name <span>*</span></label>
                    <input class="form-control" type="text" formControlName="company_name" />
                    <span class="text-danger" *ngIf="control.company_name.dirty && control.company_name.hasError('required')"> Company Name field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Logo <span *ngIf="isAddMode">*</span></label>
                    <input type="file" class="form-control post-style" formControlName="logo" (change)="onFileChange($event)" accept=".jpg, .jpeg, .png">
                    <span class="text-danger" *ngIf="control.logo.dirty && control.logo.hasError('required')"> Logo field Required.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company Email <span>*</span></label>
                    <input class="form-control" type="email" formControlName="company_email" />
                    <span class="text-danger" *ngIf="control.company_email.dirty && control.company_email.hasError('required')"> Company Email field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Country <span>*</span></label>
                    <input class="form-control" type="text" formControlName="country" />
                    <span class="text-danger" *ngIf="control.country.dirty && control.country.hasError('required')"> Country field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>State <span>*</span></label>
                    <input class="form-control" type="text" formControlName="state" />
                    <span class="text-danger" *ngIf="control.state.dirty && control.state.hasError('required')"> State field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>City <span>*</span></label>
                    <input class="form-control" type="text" formControlName="city" />
                    <span class="text-danger" *ngIf="control.city.dirty && control.city.hasError('required')"> City field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company Address <span>*</span></label>
                    <input class="form-control" type="text" formControlName="company_address" />
                    <span class="text-danger" *ngIf="control.company_address.dirty && control.company_address.hasError('required')"> Company Address field is requied.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Industry <span>*</span></label>
                    <select formControlName="industry_id" class="form-control">
                      <option [ngValue]="null" disabled>Select Industry</option>
                      <option *ngFor="let industry of industries" [ngValue]="industry.id">
                        {{industry.title}}
                      </option>
                    </select>
                    <span class="text-danger" *ngIf="control.industry_id.dirty && control.industry_id.hasError('required')"> Industry field Required.</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Facebook URL <span>*</span></label>
                    <input class="form-control" type="text" formControlName="facebook_url" placeholder="https://www.facebook.com" />
                    <span class="text-danger" *ngIf="control.facebook_url.dirty && control.facebook_url.hasError('required')"> Facebook URL field is requied.</span>
                    <span class="text-danger"
                        *ngIf="control.facebook_url.hasError('pattern')">
                        Invalid Facebook URL.
                    </span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>LinkedIn URL <span>*</span></label>
                    <input class="form-control" type="text" formControlName="linkedin_url" placeholder="https://www.linkedin.com" />
                    <span class="text-danger" *ngIf="control.linkedin_url.dirty && control.linkedin_url.hasError('required')"> LinkedIn URL field is requied.</span>
                    <span class="text-danger"
                        *ngIf="control.linkedin_url.hasError('pattern')">
                        Invalid LinkedIn URL.
                    </span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Twitter URL <span>*</span></label>
                    <input class="form-control" type="text" formControlName="twitter_url" placeholder="https://twitter.com" />
                    <span class="text-danger" *ngIf="control.twitter_url.dirty && control.twitter_url.hasError('required')"> Twitter URL field is requied.</span>
                    <span class="text-danger"
                        *ngIf="control.twitter_url.hasError('pattern')">
                        Invalid Twitter URL.
                    </span>
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Company Description <span>*</span></label>
                    <textarea class="form-control post-style" rows="5" formControlName="company_description" placeholder="Writer company description"></textarea>
                    <span class="text-danger" *ngIf="control.company_description.dirty && control.company_description.hasError('required')"> Company Description field is requied. </span>
                  </div>
                </div>
                
                <div class="col-md-6">
                  <button type="submit" class="btn btn-primary" (click)="addCompany()" [disabled]="!AddCompanyForm.valid">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>