import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { CreditCardValidators } from 'angular-cc-library';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {
  CurrentPlan: any;
  billingAddressForm: FormGroup;
  userCardForm: FormGroup;
  userExistCardForm: FormGroup;
  UserCardList = [];
  submitted = false;
  nextBillingDate = new Date();
  todayDate = new Date();
  BillingAddressDisable: boolean = false;
  PaymentCardDisable: boolean = true;
  ActiveSubscriptionDisable: boolean = true;
  modalReference: NgbModalRef;
  closeResult: string;
  existCards: boolean = false;

  //free trial
  isFreeTrial: boolean = false;
  planData: any;
  CurrentPlanID = 0;
  CurrentPlan_expiry_date:any;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private formBuilder: FormBuilder, private modalService: NgbModal) {

    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }
        this.CurrentPlan_expiry_date = new Date(this.CurrentPlan.expiry_date)
       // console.log("this.CurrentPlan.expiry_date",this.CurrentPlan_expiry_date)
         //this.router.navigateByUrl('dashboard');
      } else {
       // this.router.navigateByUrl('OurPlan');

        //alert(res.message);
      }

    }, err => {
      this.router.navigateByUrl('OurPlan');
    })
    this.getAllPlanList()
    this.billingAddressForm = this.formBuilder.group({
      user_name: ['', Validators.required, Validators.pattern('[a-zA-Z ]*')],
      company_name: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      postal_code: ['', Validators.required],
      address_1: ['', Validators.required],
      address_2: ['', Validators.required],
    })
    this.userCardForm = this.formBuilder.group({
      card_holder: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      expire_date: ['', [<any>CreditCardValidators.validateExpDate]],
      card_number: ['', [<any>CreditCardValidators.validateCCNumber]],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern('[0-9]*')]],
    })
  }
  ngOnInit(): void {

    this.getUserBillingAddress();
    this.getUserCardList();

  }
  OurPlan = function () {
    this.router.navigateByUrl('/OurPlan');
  };
  getUserBillingAddress() {
    this.authService.getUserBillingAddress().subscribe((res: any) => {
      if (res.status) {
        console.log(res, 'res user billing address')
        this.billingAddressForm = this.formBuilder.group({
          user_name: [res.user_address.name, [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
          company_name: [res.user_address.company_name, Validators.required],
          country: [res.user_address.country, Validators.required],
          state: [res.user_address.state, Validators.required],
          city: [res.user_address.city, Validators.required],
          postal_code: [res.user_address.postal_code, Validators.required],
          address_1: [res.user_address.address_1, Validators.required],
          address_2: [res.user_address.address_2, Validators.required],
        })
        this.BillingAddressDisable = true;

      }
    })
  }
  getUserCardList() {
    this.authService.getUserCardList().subscribe((res: any) => {
      if (res.status) {
        this.UserCardList = res.card_list;
        this.existCards = true;
        console.log(this.UserCardList, 'res user cards')

      }
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.billingAddressForm.controls;
  }
  // convenience getter for easy access to form fields
  get uc() {
    return this.userCardForm.controls;
  }
  submitBillingAddress() {
    this.submitted = true;
    //console.log(this.questionForm.value);
    if (!this.billingAddressForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      return;
    } else {

      this.authService.saveBillingAddress(this.billingAddressForm.value).subscribe((res: any) => {
        if (res.status) {
          this.toastr.success('User billing address added successfully', "Success");
          this.BillingAddressDisable = true;
        } else {
          this.toastr.error("User billing address add error", "Error");
        }
      })

    }
  }

  async onSubmitUserCard() {
    this.submitted = true;
    if (!this.userCardForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      return;
    } else {
      console.log(this.userCardForm.controls['expire_date'].value.replace(/\s/g, ""), "")
      this.userCardForm.controls['expire_date'].setValue(this.userCardForm.controls['expire_date'].value.replace(/\s/g, ""))
      this.authService.saveUserCard(this.userCardForm.value).subscribe((res: any) => {
        if (res.status) {
          if (res.card) {
            this.toastr.success('User card saved successfully', "Success");
            this.getUserCardList();
          }

        } else {
          this.toastr.error("User card add error", "Error");
        }
      },
        (error) => {                              //Error callback
          console.error(error, 'error caught in component')
          this.toastr.error(error, "Error");

          //throw error;   //You can also throw the error to a global error handler
        })
    }
  }
  deleteUserCard(card_id) {
    console.log(card_id, 'card_id')
  }


  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }


  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }

  keyPressNumbers(event) {

    console.log(event.which, 'event.which')
    if (event.which != 8 && event.which != 0 && event.which != 110 && (event.which < 48 || event.which > 57) && event.which != 46) {
      return false;
    }
    return true;
  }

  keyPressCharacters(event) {

    console.log(event.which, 'event.which')
    if ((event.which < 65 || event.which > 90) && (event.which < 97 || event.which > 122) && event.which != 32) {
      return false;
    }
    return true;
  }



}
