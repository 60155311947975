import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { PartnerService } from 'src/app/services/partner.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.css']
})
export class PartnerAddComponent implements OnInit {

  CreateForm: FormGroup;
  isAddMode: boolean;
  id: string;
  changeIcon: boolean = false;
  changeHoverIcon: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private partnerService: PartnerService,
    private router: Router, private route: ActivatedRoute,
    private toastr: ToastrService,
    private _location: Location) {
    this.CreateForm = this.formBuilder.group({
      id: [],
      title: ['', [Validators.required]],
      visit_link: ['', [Validators.required]],
      description: ['', [Validators.required]],
      icon: ['', [Validators.required]],
      hover_icon: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    if (!this.isAddMode) {
      this.partnerService.getSinglePartner({ 'id': this.id }).subscribe((res: any) => {
        if (res.status) {
          this.CreateForm = this.formBuilder.group({
            id: [],
            title: ['', [Validators.required]],
            visit_link: ['', [Validators.required]],
            description: ['', [Validators.required]],
            icon: [''],
            hover_icon: [''],
          })
          this.CreateForm.patchValue(res.data)
        } else {
          this.toastr.error("partner not found", "Error");
        }
      })
    }
  }

  get control() {
    return this.CreateForm.controls;
  }

  submit() {
    if (this.CreateForm.invalid) {
      return;
    }
    if (this.isAddMode) {
      console.log('Create')
      this.createPartner();
    } else {
      console.log('update')
      this.updatePartner();
    }
  }

  createPartner() {
    const formData = new FormData();
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('visit_link', this.CreateForm.get('visit_link').value);
    formData.append('description', this.CreateForm.get('description').value);
    formData.append('icon', this.CreateForm.get('icon').value);
    formData.append('hover_icon', this.CreateForm.get('hover_icon').value);

    this.partnerService.store(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data add successfully', "Success");
        this.router.navigateByUrl('admin/partner-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  updatePartner() {
    const formData = new FormData();
    formData.append('partner_id', this.CreateForm.get('id').value);
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('visit_link', this.CreateForm.get('visit_link').value);
    formData.append('description', this.CreateForm.get('description').value);

    if (this.changeIcon) {
      formData.append('icon', this.CreateForm.get('icon').value);
    }
    if (this.changeHoverIcon) {
      formData.append('hover_icon', this.CreateForm.get('hover_icon').value);
    }

    this.partnerService.update(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data update successfully', "Success");
        this.router.navigateByUrl('admin/partner-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  isFileAdd = false;
  onFileChange(event, isIcon = '') {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
        this.toastr.error("Allow only 20 mb file size", "Error");
        return;
      }
      if (isIcon == 'icon') {
        this.changeIcon = true;
        this.control.icon.setValue(file);
      } else if (isIcon == 'hover_icon') {
        this.changeHoverIcon = true;
        this.control.hover_icon.setValue(file);
      } else {

        this.control.attachment.setValue(file);
      }
    }
  }

  backClicked() {
    this._location.back();
  }

}
