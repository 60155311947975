import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { GlobalConstants } from '../common/global-constants';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  getAll() {
    return this.http.get(`${this.url}/training/get_all`, this.httpOptions);
  }
  store(req) {
    return this.http.post(`${this.url}/training/store`, req);
  }
  update(req) {
    return this.http.post(`${this.url}/training/update`, req);
  }
  getSingleTraining(req) {
    return this.http.post(`${this.url}/training/get_training_by_id`, req);
  }
  getAllTrainingByType(req) {
    return this.http.post(`${this.url}/training/get_all_by_type`, req);
  }
  delete(req) {
    return this.http.post(`${this.url}/training/delete`, req);
  }
  trainingQuestion(req) {
    return this.http.post(`${this.url}/training/training_question`, req);
  }
}
