import { Component, OnInit, EventEmitter } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from '../../common/global-constants';
declare var $: any;

import { DataService } from "src/app/services/data.service";
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  userDisplayName = '';
  userProfile = '';
  userId = '';
  userRole: any = null;
  /* isMouceIn = false;
  isFliphClassAdd = false;
  isInnerMenuShow = false;
  isShortMenu = false;
  isToggleClick = false;
  isMenuClassAdd = true; */
  stars: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  rate: number;
  isFreeTrial: boolean = false;
  nextBillingDate = new Date();
  CurrentPlan: any;
  planData: any;
  CurrentPlanID = 0;
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(
    private authService: AuthService,
    private dateService: DataService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.authService.isLogedin) {
      this.dateService.rateCount.subscribe(c => {
        this.rate = c;
      });

      if (this.authService.user) {
        this.userDisplayName = this.authService.user.name;
        if (this.authService.user.profile_pic) {
          //let path = GlobalConstants.ImagePATH+'/'+'/uploads/users/photo/';
          //this.imageURL = path+this.authService.profile_pic;
          let path = GlobalConstants.user_profile_path;
          this.userProfile = path + this.authService.user.profile_pic;
        } else {
          this.userProfile = "./assets/assets/images/user.png";
        }
        this.userId = this.authService.user.id;
        this.userRole = this.authService.user.user_role;
        this.rate = this.authService.user.rate;
        this.dateService.changeMessage(this.authService.user.rate);

        this.authService.getUserActivePlan().subscribe((res: any) => {
          if (res.status) {
            if (res.current_active_plan.plan_id == 1 || res.current_active_plan.plan_id == 2 || res.current_active_plan.plan_id == 3) {
              this.isFreeTrial = true
            }
          } else {

          }
          this.getCurrentPlan()
        }, err => {
          this.isFreeTrial = true
        })
        this.getAllPlanList()
      }
    }

    $(".btn-toggle-fullwidth").on("click", function () {
      $("body").hasClass("layout-fullwidth") ? $("body").removeClass("layout-fullwidth") : $("body").addClass("layout-fullwidth"), $(this).find(".fa").toggleClass("fa-arrow-left fa-arrow-right")
    })

    /* $('#left-sidebar .sidebar-scroll').slimScroll({
      height: 'calc(100vh - 65px)',
      wheelStep: 10,
      touchScrollStep: 50,
      color: '#efefef',
      size: '2px',
      borderRadius: '3px',
      alwaysVisible: false,
      position: 'right',
    }); */

  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.toastr.success('Logout successfully', "Success");
    this.router.navigateByUrl('login');
  }

  updateUser() {
    this.router.navigate(['/edit-profile', this.userId]);
  }

  changePassword() {
    //this.router.navigateByUrl('change-password');
    this.router.navigateByUrl('account-settings');
  }

  profile() {
    this.router.navigateByUrl('profile');
  }

  goToPage(page_name) {
    this.router.navigateByUrl(page_name);
  }

  getCurrentPlan() {
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }
      } else {
        //this.router.navigateByUrl('OurPlan');
      }

    }, err => {
     // this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
      }
    })
  }

  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

}
