import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { GlobalConstants } from '../common/global-constants';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class VideoAndBlogService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  getAll() {
    return this.http.post(`${this.url}/videoandblog/get_all`,'', this.httpOptions);
  }
  store(req) {
    return this.http.post(`${this.url}/videoandblog/store`, req);
  }
  update(req) {
    return this.http.post(`${this.url}/videoandblog/update`, req);
  }
  getSingleVideoBlogs(req) {
    return this.http.post(`${this.url}/videoandblog/get_video_blog_by_id`, req);
  }
  delete(req) {
    return this.http.post(`${this.url}/videoandblog/delete`, req);
  }
}
