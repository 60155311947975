import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label,Color } from 'ng2-charts';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-viewtool',
  templateUrl: './viewtool.component.html',
  styleUrls: ['./viewtool.component.css']
})
export class ViewtoolComponent implements OnInit {

  @ViewChild('htmlData') htmlData:ElementRef;

  modalReference: NgbModalRef;

  id = this.route.snapshot.params['id'];
  data:any = [];
  tool_questions_ratings = [];
  question:any = [];

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
        ticks: {
            beginAtZero: true,
            max: 5,
            min: 0,
            stepSize: 1
        }
    }
  };
  public radarChartLabels: Label[] = [];
  public radarChartData: ChartDataSets[] = [
    { data: [], label: 'Project Leader Compabilty indicator' }
  ];
  public radarChartType: ChartType = 'radar';

  public barChartColors: Color[] = [
    /* { backgroundColor: 'rgba(213, 211, 210, 0.5)' }, */
    /* { borderColor: 'rgba(213, 211, 210, 0.5)' }, */
    { pointBackgroundColor: '#bd007d', },
  ]

  questionForm: FormGroup;
  saveToolId = null;
  closeResult: string;

  rating_total:any = null;
  userFileName:any = null;

  constructor(private _location: Location,
            private formBuilder: FormBuilder,
            private toastr: ToastrService,
            private authService: AuthService,
            private router: Router,
            private route: ActivatedRoute,
            private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.authService.getSingleTool(this.id).subscribe((res:any)=>{
      if(res.status) {
        this.data = res.usertool;
        if(res.usertool.tool_questions_ratings.length!=0){
          this.tool_questions_ratings = res.usertool.tool_questions_ratings;
          //let showData = this.tool_questions_ratings.map(x => x.value);
          let showData = this.tool_questions_ratings.map(x => x.rate);
          this.radarChartData = [
            { data: showData, label: 'Project Leader Compabilty indicator' }
          ];
          this.rating_total = this.data.rating_total.toFixed();
          this.userFileName =  res.usertool.tool_user_name;
        }else{
          this.toastr.error("tool question rating not found", "Error");
        }
      } else {
        this.toastr.error("Single toold data not found", "Error");
      }

    })

    this.authService.getAllQuestions().subscribe((res:any)=>{
      if(res.status) {
        this.question = res.question;
        let ListData = this.question.map(x => x.question_title);
        this.radarChartLabels = ListData;
      } else {
        this.toastr.error("Question list not found", "Error");
      }
    })

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

    /* this.questionForm = this.formBuilder.group({
        question_1_weight: ['', Validators.required],
        question_1_rating: ['', [Validators.required]],
        question_1_rating_value: ['', Validators.required],
        question_2_weight: ['', Validators.required],
        question_2_rating: ['', [Validators.required]],
        question_2_rating_value: ['', Validators.required],
        question_3_weight: ['', Validators.required],
        question_3_rating: ['', [Validators.required]],
        question_3_rating_value: ['', Validators.required],
        question_4_weight: ['', Validators.required],
        question_4_rating: ['', [Validators.required]],
        question_4_rating_value: ['', Validators.required],
        question_5_weight: ['', Validators.required],
        question_5_rating: ['', [Validators.required]],
        question_5_rating_value: ['', Validators.required],
        question_6_weight: ['', Validators.required],
        question_6_rating: ['', [Validators.required]],
        question_6_rating_value: ['', Validators.required],
        user_rating_value: [],
        tools_id: [],
        weightTotal: ['', [Validators.required, Validators.min(100), Validators.max(100)]],
    }); */

   /*  setTimeout(() => {
      //console.log(this.tool_questions_ratings[0]);
      if(this.tool_questions_ratings[0]){
        this.data['question_1_weight'] = this.tool_questions_ratings[0].weight;
        this.data['question_1_rating']  = this.tool_questions_ratings[0].rate;
      }
      if(this.tool_questions_ratings[1]){
        this.data['question_2_weight'] = this.tool_questions_ratings[1].weight;
        this.data['question_2_rating']  = this.tool_questions_ratings[0].rate;
      }
      if(this.tool_questions_ratings[2]){
        this.data['question_3_weight'] = this.tool_questions_ratings[2].weight;
        this.data['question_3_rating']  = this.tool_questions_ratings[0].rate;
      }
      if(this.tool_questions_ratings[3]){
        this.data['question_4_weight'] = this.tool_questions_ratings[3].weight;
        this.data['question_4_rating']  = this.tool_questions_ratings[0].rate;
      }
      if(this.tool_questions_ratings[4]){
        this.data['question_5_weight'] = this.tool_questions_ratings[4].weight;
        this.data['question_5_rating']  = this.tool_questions_ratings[0].rate;
      }
      if(this.tool_questions_ratings[5]){
        this.data['question_6_weight'] = this.tool_questions_ratings[5].weight;
        this.data['question_6_rating']  = this.tool_questions_ratings[0].rate;
      }
      this.data['weightTotal']= this.data['question_1_weight']+this.data['question_2_weight']+this.data['question_3_weight']+
      this.data['question_4_weight']+this.data['question_5_weight']+this.data['question_6_weight'];

      this.questionForm.patchValue(this.data);
    }, 1500); */


  }

  get queControl() {
    return this.questionForm.controls;
  }


  public openPDF():void {
    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName); // Generated PDF
    });
}

 addWaterMark(doc) {
  var totalPages = doc.internal.getNumberOfPages();
    doc.setTextColor(150);
    doc.text(143, doc.internal.pageSize.height/2, 'VT-Touch');
    return doc;
}

  backClicked() {
    this._location.back();
  }

  DeleteReport(id){
    Swal.fire({
        title: 'Are you sure want to remove?',
        text: 'You will not be able to recover this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
          if (result.value) {
            this.authService.deleteUserTool(id).subscribe((res:any)=>{
                if(res.status) {
                  this.toastr.success('User report deleted successfully', "Success");
                } else {
                  this.toastr.error("User report delete error", "Error");
                }
                this.router.navigateByUrl('tools');
            })
        }
    })
  }

  openTwo(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openThree(contents) {
    this.modalService.open(contents, { ariaLabelledBy: 'modal-basic-titles', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    //this.editUserRole = false;

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }

  }

}
