<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">

        <div class="block-header top-head-vt">
          <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
              <!-- <h2>Project Leader Characteristics
              </h2> -->
              <ul class="breadcrumb vt-a">
                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i
                       class="icon-home"></i></a></li>
                <li class="breadcrumb-item"><a routerLink="/tools">Tools</a></li>
                <li class="breadcrumb-item active">Agile Project Leader Analysis</li>
              </ul>
            </div>
          </div>
        </div>

        <section id="htmlData">
            <div id="myHeader"  class="mb-3">
              <div class="row">
                <div class="col-md-6 tool-extra-style">
                    <h5 class="tool-extra tool-head-space"> Generate Agile Project Leader Analysis Report</h5>
                </div>

                <div class="col-md-6">
                    <div style="float: right;" (click)="DeleteReport(id)" class="m-l-5">
                      <button type="button" class="btn pbtn"><i class="fa fa-trash"></i></button>
                    </div>

                    <div (click)="openPDF()" class="pull-right ">
                      <button type="button" class="btn pbtn">Export PDF</button>
                    </div>

                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                   <div class="AnalysisText">
                      <h5>Name of Analysis</h5>
                      <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                      </button> -->
                      <input type="text" placeholder="{{userFileName}} " class=" Upgradebtn form-control" >

                   </div>
                </div>
                </div>
            <div class="col-md-12 clearfix">
                <div class="row" >
                    <!-- <form [formGroup]="questionForm" enctype="multipart/form-data"> -->
                        <table id="example" class="table table-striped table-bordered " style="width:100%" >
                            <thead>
                                <tr>
                                    <th colspan="1">Project Leader Characteristics</th>
                                    <th colspan="1">Weight</th>
                                    <th colspan="1">Description</th>
                                    <th colspan="1">Rating <br />
                                      <span (click)="openTwo(content)" class=" f-small"> Scale(0-5)
                                        <i class="icon-info"></i>
                                      </span>
                                    </th>
                                    <th colspan="3">Value <br /><span class="f-small">Value = Rating x Weight/100</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let value of tool_questions_ratings">
                                    <td class="sorting_1">{{ value.question.question_title }}</td>
                                    <!-- <td>
                                        {{ value.weight }}
                                    </td> -->
                                    <td><input type="text" class="form-control weight" value="{{ value.weight }}" disabled></td>

                                    <td ><span [innerHTML]="value.question.description"></span></td>
                                    <td>
                                        <div class="newRate">
                                            <i class="fa fa-star fa-sm" aria-hidden="true" [ngClass]="value.rate >= '1' ? 'fa fa-star' : 'fa fa-star-o' "></i>&nbsp;
                                            <i class="fa fa-star fa-sm" aria-hidden="true" [ngClass]="value.rate >= '2' ? 'fa fa-star' : 'fa fa-star-o' "></i>&nbsp;
                                            <i class="fa fa-star fa-sm" aria-hidden="true" [ngClass]="value.rate >= '3' ? 'fa fa-star' : 'fa fa-star-o' "></i>&nbsp;
                                            <i class="fa fa-star fa-sm" aria-hidden="true" [ngClass]="value.rate >= '4' ? 'fa fa-star' : 'fa fa-star-o' "></i>&nbsp;
                                            <i class="fa fa-star fa-sm" aria-hidden="true" [ngClass]="value.rate >= '5' ? 'fa fa-star' : 'fa fa-star-o' "></i>&nbsp;
                                            <!-- <i class="fa fa-star-o" aria-hidden="true"></i>
                                            <span [ngClass]="{'selected': value.rate >= '1'}">☆</span>
                                            <span [ngClass]="{'selected': value.rate >= '2'}">☆</span>
                                            <span [ngClass]="{'selected': value.rate >= '3'}">☆</span>
                                            <span [ngClass]="{'selected': value.rate >= '4'}">☆</span>
                                            <span [ngClass]="{'selected': value.rate >= '5'}">☆</span> -->
                                            <!-- {{ value.rate }} -->
                                        </div>
                                    </td>
                                    <!-- <td>{{ value.value }}</td> -->
                                    <td><input type="text" class="form-control weight" value="{{ value.value }}" disabled></td>
                                </tr>
                            </tbody>
                            <!--<tr>
                                <td class="sorting_1 text-center" colspan="4">Total</td>
                                <td colspan="">{{data.rating_total}}</td>
                            </tr>-->
                        </table>
                    <!-- </form> -->
                </div>
            </div>

            <div style="margin-top: 20px;" class="col-md-12">
                <div class="row">
                    <div class="card">
                        <div class="header">
                            <h2>Project Leader Assessment</h2>
                        </div>
                        <div class="body">
                            <!-- <canvas id="radar_chart"></canvas> -->
                            <div class="chart-wrapper">
                                <canvas
                                    id="radar_chart"
                                    baseChart [datasets]="radarChartData"
                                    [options]="radarChartOptions"
                                    [labels]="radarChartLabels"
                                    [chartType]="radarChartType"
                                    [colors]="barChartColors"
                                    height="20vh" width="40vw">
                                </canvas>
                            </div>
                        </div>


                    </div>

                    <div class="col-md-12 col-sm-12 indicator-bottom card">
                      <h5>Project Leader Compatibilty Indicator = {{rating_total}}</h5>
                      <span class="result-3" *ngIf="rating_total==5">Project leader has all the characteristics to be successful in an agile
                        environment
                      </span>
                      <span class="result-3" *ngIf="rating_total==4">Project leader has many of the key characteristics to be successful in an
                        agile
                        environment</span>
                      <span class="result-2" *ngIf="rating_total==3">Project leader will need to change some aspects of his or her style to be
                        successful
                      </span>
                      <span class="result-2" *ngIf="rating_total==2">Project leader will need guidance to be successful in managing an agile
                        project
                      </span>
                      <span class="result-1" *ngIf="rating_total==1">Project leader will find it difficult to lead an agile project</span>
                      <span class="result-1" *ngIf="rating_total==0">Project leader does not have the requisite skills or demeanor to lead an
                        agile
                        project</span>

                        <div class="col-md-12 tool-bottom">
                          <h6>NOTE: The higher the score, the better it is for an agile environment
                            <span (click)="openThree(contents)">
                              <i class="icon-info"></i>
                            </span>
                          </h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Explanation for the Project Manager (PM) Compatibility Indicator
        </h4>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item disabled sub-title">The ratings are 0 through 5. Please keep the ratings to whole numbers.
            </li>
            <li class="list-group-item"><p>0</p> Not at all</li>
            <li class="list-group-item"><p>1</p> Minimal</li>
            <li class="list-group-item"><p>2</p> Somewhat</li>
            <li class="list-group-item"><p>3</p> Average</li>
            <li class="list-group-item"><p>4</p> Above Average</li>
            <li class="list-group-item"><p>5</p> Competitive Advantage</li>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>

<ng-template #contents let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-titles">Explanation for the Project Manager (PM) Compatibility Indicator
        </h4>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item"><p>5</p> = Project leader has all the characteristics to be successful in an agile
                environment
            </li>
            <li class="list-group-item"><p>4</p> = Project leader has many of the key characteristics to be successful in an
                agile
                environment</li>
            <li class="list-group-item"><p>3</p> = Project leader will need to change some aspects of his or her style to be
                successful
            </li>
            <li class="list-group-item"><p>2</p> = Project leader will need guidance to be successful in managing an agile
                project
            </li>
            <li class="list-group-item"><p>1</p> = Project leader will find it difficult to lead an agile project</li>
            <li class="list-group-item"><p>0</p> = Project leader does not have the requisite skills or demeanor to lead an
                agile
                project</li>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>

