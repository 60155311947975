<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item">
              <a [routerLink]="['/Admin-Dashboard']">
                <i class="icon-home"></i>
              </a>
            </li>
            <li class="breadcrumb-item">Job List</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-md-12">
        <div class="card">
          <div class="header">
            <h2>Job List</h2>
            <a [routerLink]="['/admin/company-add']" class="btn pbtn color_cls ml-2"> Add Company </a>
            <a [routerLink]="['/admin/job-add']" class="btn pbtn color_cls"> Add Job </a>
          </div>
          <div class="body">
            <table class="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Logo</th>
                  <th scope="col">Job Name</th>
                  <th scope="col">Company Email</th>
                  <th scope="col">Address</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">Country</th>
                  <th scope="col">Salary</th>
                  <th scope="col">Published Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody *ngIf="jobs?.length; else no_data_templ">
                <tr *ngFor="let job of jobs; index as i">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>
                    {{ job.company.company_name }}
                  </td>
                  <td>
                    <span *ngIf="job.company.logo">
                      <img [src]="job.company.logo" style="width: 50px; height: auto;">
                    </span>
                    <span *ngIf="!job.company.logo"> - </span>
                  </td>
                  <td>
                    {{ job.job_name }}
                  </td>
                  <td>
                    {{ job.company.company_email }}
                  </td>
                  <td>
                    {{ job.company.company_address }}
                  </td>
                  <td>
                    {{ job.company.city }}
                  </td>
                  <td>
                    {{ job.company.state }}
                  </td>
                  <td>
                    {{ job.company.country }}
                  </td>
                  <td>
                    {{ job.salary }}
                  </td>
                  <td>
                    {{ job.published_date | date:'longDate'}}
                  </td>
                  <td>
                    {{ job.end_date | date:'longDate'}}
                  </td>
                  <td>
                    <a href="javascript:;" [routerLink]="['/admin/job-applications/', job.id]" class="btn pbtn">
                      Job Applications
                    </a>&nbsp;
                    <a href="javascript:;" class="btn pbtn" (click)="editJob(job.id)">
                      <i class="fa fa-edit"></i>
                    </a>&nbsp; <a href="javascript:;" class="btn btn-danger pbtn" (click)="deleteJob(job.id)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
                <tr *ngIf="jobs.length==0">
                  <td colspan="13">No Data Found</td>
                </tr>
              </tbody>
              <ng-template #no_data_templ>
                <tr>
                  <td colspan="13" class="text-center">No Data Found</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>