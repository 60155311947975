<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Learn</li>
                        <li class="breadcrumb-item">{{category_name}}</li>
                    </ul>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12" >
                    <section class="learning-banner learning-banner--sm mb-30px">
                        <div class="container">
                        <div class="learning-banner--sm__content">
                            <h6> VT TOUCH LEARNING - </h6>
                            <h1>{{category_name}}</h1>
                            <h6 *ngIf="isFreeTrial">You have a free trial/gold member plan so you can donwload sample PDF. please upgrade your plan for donwload main PDF</h6>
                   </div>
                </div>
                </section>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="learningList.length!=0">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row clearfix top-xcode" >
                    <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-6 col-sm-12 text-center" *ngFor="let singleList of learningList; let i = index">
                        <div class="card-cd">
                            <div class="Defoult-Card">
                                <img src="./assets/assets/card-img/templateSoft-hover.png" class="roundright-cd border-round">
                                <!-- <img [src]="value.icon" class="sideleft-cd"> -->
                                <img src="{{singleList.icon}}" class="sideleft-cd">
                                <!--./sidebar-cd-->
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">"VT Touch Learning - </h5>
                                    <h3 class="skill-cd">{{singleList.category_name}}"</h3>
                                </div>
                                <div class="sidebar-cd">
                                    <h2 class="h5-cd">{{singleList.title}}</h2>
                                </div>
                                <div *ngIf="isFreeTrial && i == 0">
                                    <a href="{{singleList.sample_pdf}}"target="_blank" rel="noopener noreferrer" >
                                    <img src="./assets/assets/images/Download-hover.png" class="sideright-cd">
                                    </a>
                                </div>
                                <div *ngIf="isFreeTrial && i > 0">
                                    <a href="#" onclick="return false;" (click)="OpenPlanPopup(PlanModal)" rel="noopener noreferrer">
                                    <img src="./assets/assets/images/Download-hover.png" class="sideright-cd">
                                    </a>
                                </div>
                                <div *ngIf="!isFreeTrial">
                                    <a href="{{singleList.attachment}}"target="_blank" rel="noopener noreferrer" >
                                    <img src="./assets/assets/images/Download-hover.png" class="sideright-cd">
                                    </a>
                                </div>
                            </div>
                            <!--./Defoult card-->
                            
                            <div class="overlay-cd">
                                <img src="./assets/assets/card-img/template.png" class="roundright-cd border-round">
                                <!-- <img [src]="value.hover_icon" class="sideleft-cd"> -->
                                <img src="{{singleList.hover_icon}}" class="sideleft-cd">
                                <!--./sidebar-cd-->
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">"VT Touch Learning - </h5>
                                    <h3 class="skill-cd">{{singleList.category_name}}"</h3>
                                </div>
                                <div class="sidebar-cd">
                                    <h2 class="h5-cd">{{singleList.title}}</h2>
                                </div>
                                <div *ngIf="isFreeTrial && i == 0">
                                    <a href="{{singleList.sample_pdf}}"target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/assets/images/Download.png" class="sideright-cd">
                                    </a>
                                </div>
                                <div *ngIf="isFreeTrial && i > 0">
                                    <a href="#" onclick="return false;" (click)="OpenPlanPopup(PlanModal)" rel="noopener noreferrer">
                                    <img src="./assets/assets/images/Download.png" class="sideright-cd">
                                    </a>
                                </div>
                                <div *ngIf="!isFreeTrial">
                                    <a href="{{singleList.attachment}}"target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/assets/images/Download.png" class="sideright-cd">
                                    </a>
                                </div>
                            </div>
                            <!--./overlay-->
                        </div>
                        <!--./card-->
                    </div>
                    <!--./col-lg-4 col-md-6 col-sm-12 text-center-->
                </div>
                <!--./div-->
            </div>
            <!--./col-md-12-->
        </div>
        <!--./row-->
        <div class="row clearfix text-center" *ngIf="learningList.length==0">
            <div class="col-md-12 text-center">
                No Data Found
            </div>
        </div>

        <ng-template #PlanModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">You have to upgrade your plan</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                   <span aria-hidden="true">&times;</span>
                 </button>
             </div>
             <div class="modal-body">
                <div class="Iconwarning-box mb-4">
                   <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
                   <div class="Iconwarning-box-content">
                      <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                         enjoying the benefits of all features by VTTouch. 
                      </p>
                   </div>
                </div>
                <div class="row justify-content-center">
                   <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                      <div class="single-price-plan mb-100">
                         <div class="price-plan-title">
                            <h4>{{singlePlan.plan_name}}
                            </h4>
                         </div>
                         <div class="price-plan-value">
                            <h2>£{{singlePlan.price}}</h2>
                            <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                         </div>
                           <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                           <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                      </div>
                   </div>
                   
                   
                </div>
             </div>
        </ng-template>
    </div>
</div>