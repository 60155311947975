import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { CreditCardValidators } from 'angular-cc-library';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.css']
})
export class PurchasePlanComponent implements OnInit {
  @ViewChild("SubscriptionSuccessModal") modalContent: TemplateRef<any>;
  
  CurrentPlan: any = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  planDetail: any;
  planID: number;
  nextBillingDate: any;
  todayDate: any;
  subscriptionSettingsForm: FormGroup;
  billingAddressForm: FormGroup;
  userCardForm: FormGroup;
  userExistCardForm: FormGroup;
  submitted = false;
  UserCardList = [];
  isActivePlanShow = true;
  BillingAddressStep = true;
  AddPaymentDetailStep = true;
  AddCardDetailStep = true;
  modalReference: NgbModalRef;
  closeResult: string;
  existCards: boolean = false;

  //free trial
  isFreeTrial: boolean = false;
  planData: any;
  CurrentPlanID = 0;
  formReady: boolean = false;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private formBuilder: FormBuilder, private modalService: NgbModal) {
    this.todayDate = new Date();
    this.route.params
      .subscribe(
        (params: any) => {
          this.planID = params['id']
          console.log(params['id'])
          this.authService.getAllPlanList({ 'plan_id': this.planID }).subscribe((res: any) => {
            if (res.status) {
              this.planDetail = res.plans;
              this.nextBillingDate = new Date();
              if (this.planDetail.duration_type == 'Days') {
                this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.planDetail.duration);
              } else if (this.planDetail.duration_type == 'Years') {
                this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.planDetail.duration);
              }
              console.log(this.nextBillingDate, 'nextBillingDate')
            }
          })

        }

      );
    this.authService.getUserActivePlan().subscribe((res: any) => {
      this.formReady = true;
      let disabledAR = this.planID == 1 ? true : false;
      if (res.status) {
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }
        console.log(this.CurrentPlan)
        var auto_renewal = this.CurrentPlan.auto_renewal == 1 ? true : false;
        var send_reminder = this.CurrentPlan.send_reminder == 1 ? true : false;
        this.subscriptionSettingsForm = this.formBuilder.group({
          auto_renewal: [{value: auto_renewal, disabled: disabledAR}, Validators.required],
          send_reminder: [send_reminder, Validators.required]
        })
        // this.router.navigateByUrl('dashboard');
      } else {
        this.subscriptionSettingsForm = this.formBuilder.group({
          auto_renewal: [{value: false, disabled: disabledAR}, Validators.required],
          send_reminder: [false, Validators.required]
        })
        // this.router.navigateByUrl('OurPlan');

        //alert(res.message);
      }

    }, err => {
      this.formReady = true;
      let disabledAR = this.planID == 1 ? true : false;
      this.subscriptionSettingsForm = this.formBuilder.group({
        auto_renewal: [{value: false, disabled: disabledAR}, Validators.required],
        send_reminder: [false, Validators.required]
      })
      // this.router.navigateByUrl('OurPlan');
    })
    this.getAllPlanList()
    this.userCardForm = this.formBuilder.group({
      card_holder: ['', [Validators.required,Validators.pattern('[a-zA-Z ]*')]],
      expire_date: ['', [<any>CreditCardValidators.validateExpDate]],
      card_number: ['', [<any>CreditCardValidators.validateCCNumber]],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    })
    this.userExistCardForm = this.formBuilder.group({
      card_id: ['', [Validators.required]],
    })

  }

  ngOnInit(): void {

    // const id = 'step1';
    // const yOffset = -10; 
    // const element = document.getElementById(id);
    // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // window.scrollTo({top: y-30, behavior: 'smooth'});
    // this.ScrollScreen('step1');
    // document.getElementById("step1").scrollIntoView({behavior: 'smooth',block: "start"});;
    this.firstFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
    this.secondFormGroup = new FormGroup({
      password: new FormControl('', Validators.required)
    });
    this.billingAddressForm = this.formBuilder.group({
      user_name: ['', [Validators.required,Validators.pattern('[a-zA-Z ]*')]],
      company_name: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      postal_code: ['', Validators.required],
      address_1: ['', Validators.required],
      address_2: ['', Validators.required],
    })

    // this.nextBillingDate = new Date();
    // this.nextBillingDate.setMonth(this.nextBillingDate.getMonth() - 3);

    this.getUserCardList();
    this.getUserBillingAddress();
  }


  get email() { return this.firstFormGroup.get('email'); }
  get password() { return this.secondFormGroup.get('password'); }
  getUserCardList() {
    this.authService.getUserCardList().subscribe((res: any) => {
      if (res.status) {
        this.UserCardList = res.card_list;
        this.existCards = true;
        console.log(this.UserCardList, 'res user cards')

      }
    })
  }


  isActivePlanShowBtn() {
    this.isActivePlanShow = false;
  }
  NextStep(step: string) {

    if (step == 'step1') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = true;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = false;
      // document.getElementById("step2").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step2');
      const id = 'step2';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      // document.getElementById("step2").scrollIntoView({behavior: 'smooth',block: "start"});
      window.scrollTo({ top: y - 80, behavior: 'smooth' });
    } else if (step == 'step2') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = true;
      // this.AddCardDetailStep = false;
      // document.getElementById("step3").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step3');
      const id = 'step3';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 630, behavior: 'smooth' });
    } else if (step == 'step3') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = true;
      // document.getElementById("step4").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step4');
      const id = 'step4';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 620, behavior: 'smooth' });
    } else if (step == 'step4') {
      this.isActivePlanShow = false;
      this.BillingAddressStep = false;
      this.AddPaymentDetailStep = false;
      this.AddCardDetailStep = true;
    }
  }
  BackButton(step: string) {
    if (step == 'step2') {
      // this.isActivePlanShow = true;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = false;
      // document.getElementById("step1").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step1');
      const id = 'step1';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 100, behavior: 'smooth' });
    } else if (step == 'step3') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = true;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = false;
      // document.getElementById("step2").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step2');
      const id = 'step2';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 100, behavior: 'smooth' });
    } else if (step == 'step4') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = true;
      // this.AddCardDetailStep = false;
      // document.getElementById("step3").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step3');
      const id = 'step3';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 600, behavior: 'smooth' });
    }

  }
  StepOpen(step: string) {
    if (step == 'step1') {
      // this.isActivePlanShow = true;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = false;
      // document.getElementById("step3").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step3');
      const id = 'step1';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 30, behavior: 'smooth' });
    }
    else if (step == 'step2') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = true;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = false;
      // document.getElementById("step2").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step2');
      const id = 'step2';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 60, behavior: 'smooth' });
    } else if (step == 'step3') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = true;
      // this.AddCardDetailStep = false;
      // document.getElementById("step3").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step3');
      const id = 'step3';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 50, behavior: 'smooth' });
    } else if (step == 'step4') {
      // this.isActivePlanShow = false;
      // this.BillingAddressStep = false;
      // this.AddPaymentDetailStep = false;
      // this.AddCardDetailStep = true;
      // document.getElementById("step4").scrollIntoView({behavior: 'smooth',block: "start"});;
      // this.ScrollScreen('step4');
      const id = 'step4';
      const yOffset = -10;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y - 60, behavior: 'smooth' });
    }
  }
  ScrollScreen(Elementid) {
    const id = Elementid;
    const yOffset = -10;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y - 30, behavior: 'smooth' });
  }
  getUserBillingAddress() {
    this.authService.getUserBillingAddress().subscribe((res: any) => {
      if (res.status) {
        console.log(res, 'res user billing address')
        this.billingAddressForm = this.formBuilder.group({
          user_name: [res.user_address.name, [Validators.required,Validators.pattern('[a-zA-Z ]*')]],
          company_name: [res.user_address.company_name, Validators.required],
          country: [res.user_address.country, Validators.required],
          state: [res.user_address.state, Validators.required],
          city: [res.user_address.city, Validators.required],
          postal_code: [res.user_address.postal_code, Validators.required],
          address_1: [res.user_address.address_1, Validators.required],
          address_2: [res.user_address.address_2, Validators.required],
        })

      }
    })
  }

  async onSubmitUserExistingCard() {
    if (!this.userExistCardForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      this.toastr.error("Please select any card", "Error");
      return;
    } else {
      Swal.fire({
        title: 'You are purchasing '+this.planDetail.duration + ' ' + this.planDetail.duration_type +' membership Price £' + this.planDetail.price,
        text: ''+ this.planDetail.plan_name,
         // icon: 'warning',
         iconHtml: ' <img src="./assets/assets/images/renew-icon.png" alt="renew-icon">',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          //this.loading = true;
          var reqData = {
            card_id: this.userExistCardForm.controls['card_id'].value,
            plan_id: this.planID,
            auto_renewal: this.subscriptionSettingsForm.value.auto_renewal == true ? 1 : 0,
            send_reminder: this.subscriptionSettingsForm.value.send_reminder == true ? 1 : 0
          }
          this.authService.purchasePlan(reqData).subscribe((res: any) => {
            if (res.status) {
              this.toastr.success('Plan subscribed successfully', "Success");
              this.modalService.open(this.modalContent, { size: 'md' }).result.then((result) => {
        
              });
              this.router.navigateByUrl('upgrade-plan')
            }
          },
            (error) => {                              //Error callback
              console.error(error, 'error caught in component')
              this.toastr.error(error, "Error");
    
              //throw error;   //You can also throw the error to a global error handler
            })
          
        }
      })
      console.log(this.userExistCardForm, 'this.userCardForm')
      
    }
  }

  async onSubmitUserCard() {
    this.submitted = true;
    if (!this.userCardForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      return;
    } else {
      console.log(this.userCardForm.controls['expire_date'].value.replace(/\s/g, ""), "")
      this.userCardForm.controls['expire_date'].setValue(this.userCardForm.controls['expire_date'].value.replace(/\s/g, ""))
      this.authService.saveUserCard(this.userCardForm.value).subscribe((res: any) => {
        if (res.status) {
          if (res.card) {
            this.toastr.success('User card saved successfully', "Success");
            var reqData = {
              card_id: res.card.card_id,
              plan_id: this.planID,
              auto_renewal: this.subscriptionSettingsForm.value.auto_renewal == true ? 1 : 0,
              send_reminder: this.subscriptionSettingsForm.value.send_reminder == true ? 1 : 0
            }
            this.authService.purchasePlan(reqData).subscribe((res: any) => {
              if (res.status) {
                this.toastr.success('Plan subscribed successfully', "Success");
                this.modalService.open(this.modalContent, { size: 'md' }).result.then((result) => {
        
                });
                this.router.navigateByUrl('upgrade-plan')
              }
            },
              (error) => {                              //Error callback
                console.error(error, 'error caught in component')
                this.toastr.error(error, "Error");

                //throw error;   //You can also throw the error to a global error handler
              })

          }

        } else {
          this.toastr.error("User card add error", "Error");
        }
      },
        (error) => {                              //Error callback
          console.error(error, 'error caught in component')
          this.toastr.error(error, "Error");

          //throw error;   //You can also throw the error to a global error handler
        })
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.billingAddressForm.controls;
  }
  // convenience getter for easy access to form fields
  get uc() {
    return this.userCardForm.controls;
  }

  submitBillingAddress() {
    this.submitted = true;
    //console.log(this.questionForm.value);
    if (!this.billingAddressForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      return;
    } else {


      this.authService.saveBillingAddress(this.billingAddressForm.value).subscribe((res: any) => {
        if (res.status) {
          this.toastr.success('User billing address added successfully', "Success");
          // this.isActivePlanShow = false;
          // this.BillingAddressStep = false;
          // this.AddPaymentDetailStep = false;
          // this.AddCardDetailStep = true;
          const id = 'step4';
          const yOffset = -10;
          const element = document.getElementById(id);
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y - 80, behavior: 'smooth' });
        } else {
          this.toastr.error("User billing address add error", "Error");
        }
      })

    }
  }
  submitBillingAddressAndPurchase() {
    this.submitted = true;
    //console.log(this.questionForm.value);
    if (!this.billingAddressForm.valid) {
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      return;
    } else {


      this.authService.saveBillingAddress(this.billingAddressForm.value).subscribe((res: any) => {
        if (res.status) {
          this.toastr.success('User billing address added successfully', "Success");
          var reqData = {
            plan_id: this.planID,
            auto_renewal: this.subscriptionSettingsForm.value.auto_renewal == true ? 1 : 0,
            send_reminder: this.subscriptionSettingsForm.value.send_reminder == true ? 1 : 0
          }
          this.authService.purchasePlan(reqData).subscribe((res: any) => {
            if (res.status) {
              this.toastr.success('Plan subscribed successfully', "Success");
              this.modalService.open(this.modalContent, { size: 'md' }).result.then((result) => {
        
              });
              this.router.navigateByUrl('dashboard')
            }
          },
            (error) => {                              //Error callback
              console.error(error, 'error caught in component')
              this.toastr.error(error, "Error");
    
              //throw error;   //You can also throw the error to a global error handler
            })
          // this.isActivePlanShow = false;
          // this.BillingAddressStep = false;
          // this.AddPaymentDetailStep = false;
          // this.AddCardDetailStep = true;
          // const id = 'step4';
          // const yOffset = -10;
          // const element = document.getElementById(id);
          // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          // window.scrollTo({ top: y - 640, behavior: 'smooth' });
        } else {
          this.toastr.error("User billing address add error", "Error");
        }
      })

    }
  }

  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }


  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }

  
  keyPressNumbers(event) {
    
    console.log(event.which,'event.which')
    if (event.which != 8 && event.which != 0 && event.which != 110 && (event.which < 48 || event.which > 57) && event.which != 46) {
                  return false;
              }
    return true;
  }

  keyPressCharacters(event) {
    
    console.log(event.which,'event.which')
    if ((event.which < 65 || event.which > 90) && (event.which < 97 || event.which > 122) && event.which != 32) {
      return false;
    }
    return true;
  }

}
