<div id="wrapper">
<!-- <app-header></app-header> 
<app-sidebar></app-sidebar> -->
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(0, 0, 0, 1)" 
template="<img src='./assets/assets/images/loader-5.gif' 
style='opacity: 0.8;width:160px;height:90px !important;' />" 
size="small" class="loader_bg">
</ngx-spinner>
<router-outlet></router-outlet>
</div>