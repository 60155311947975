import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { DashboardComponent } from './secure/dashboard/dashboard.component';
import { UsersComponent } from './secure/users/users.component';
import { ProfileComponent } from './secure/profile/profile.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecureComponent } from './secure/secure.component';
import { EditProfileComponent } from './secure/edit-profile/edit-profile.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './secure/change-password/change-password.component';
import { ViewProfileComponent } from './secure/view-profile/view-profile.component';

import {TokenInterceptor} from "./core/interceptor";
import {AuthService} from "./services/auth.service";
import {DealroomService} from "./services/dealroom.service";
import { EmailVarifyComponent } from './auth/email-varify/email-varify.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { FilterPipe } from './filter/filter.pipe';
import { NotFoundComponent } from './auth/not-found/not-found.component';
import { UserDetailsComponent } from './secure/user-details/user-details.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { SelectBoxPipe } from './filter/select-box.pipe';
import {ErrorInterceptor} from "./core/error.interceptor";
import {LoaderInterceptor} from "./core/loader.interceptor";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EventAddComponent } from './secure/event-add/event-add.component';
import { EventListComponent } from './secure/event-list/event-list.component';
import { UserEventListComponent } from './secure/user-event-list/user-event-list.component';
import { ToolsComponent } from './secure/tools/tools.component';
import { MastertoolsComponent } from './secure/mastertools/mastertools.component';

import { MetismenuAngularModule } from "@metismenu/angular";
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewtoolComponent } from './secure/viewtool/viewtool.component';

import { ChartsModule } from 'ng2-charts';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ChatComponent } from './secure/chat/chat.component';
import { MyFavouriteComponent } from './secure/my-favourite/my-favourite.component';
//import { SecondHeaderComponent } from './layout/second-header/second-header.component';

import { SharedModule } from './shared/shared.module';

//import { ChatModule } from './secure/chat/chat.module';
//import { MyFavouriteModule } from './secure/my-favourite/my-favourite.module';

import { NgxSpinnerModule } from "ngx-spinner";
import {DataService} from "./services/data.service";
import { AdminDealroomComponent } from './secure/admin-dealroom/admin-dealroom.component';
import { AddDealroomComponent } from './secure/admin-dealroom/add-dealroom/add-dealroom.component';
import { RoomUsersComponent } from './secure/admin-dealroom/room-users/room-users.component';
import { RoomUserRequestComponent } from './secure/admin-dealroom/room-user-request/room-user-request.component';
import { ProjectToolComponent } from './secure/tools/project-tool/project-tool.component';
import { ProjectToolViewComponent } from './secure/tools/project-tool-view/project-tool-view.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NgxEchartsModule } from 'ngx-echarts';
import { UserMessageComponent } from './secure/user-message/user-message.component';

import { LearningAddComponent } from './secure/admin/learning/learning-add/learning-add.component';
import { LearningListComponent } from './secure/admin/learning/learning-list/learning-list.component';
import { LearningService } from "./services/learning.service";
import { CategoryListComponent } from './secure/user/learn/category-list/category-list.component';
import { NotificationListComponent } from './secure/user/notification/notification-list/notification-list.component';

/** calendor **/
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { PostViewComponent } from './secure/user/post/post-view/post-view.component';
import { Tool3Component } from './secure/tools/tool3/tool3.component';
import { Tool4Component } from './secure/tools/tool4/tool4.component';
import { Tool4viewComponent } from './secure/tools/tool4view/tool4view.component';
import { Tool3viewComponent } from './secure/tools/tool3view/tool3view.component';
import { FollowingPostComponent } from './secure/following-post/following-post.component';
 
import timeGrigPlugin from '@fullcalendar/timegrid';
import { UpgradeComponent } from './secure/upgrade-renew/upgrade/upgrade.component';
import { OurPlanComponent } from './secure/our-plan/our-plan.component';
import { PurchasePlanComponent } from './secure/purchase-plan/purchase-plan.component'; 
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { TestingSkillsComponent } from './secure/user/learn/testing-skills/testing-skills.component';
import { SoftSkillsComponent } from './secure/user/learn/soft-skills/soft-skills.component';
import { GenericSkillsComponent } from './secure/user/learn/generic-skills/generic-skills.component';
import { TrainingComponent } from './secure/user/learn/training/training.component';
import { CvLinkdinComponent } from './secure/grow/cv-linkdin/cv-linkdin.component';
import { CoachingComponent } from './secure/grow/coaching/coaching.component';
import { AdminDashboardComponent } from './secure/admin-dashboard/admin-dashboard.component';
import { JobDetailsComponent } from './secure/grow/job-details/job-details.component';
import { JobSearchComponent } from './secure/grow/job-search/job-search.component';
import { JobAddComponent } from './secure/admin/grow/job-add/job-add.component';
import { JobListComponent } from './secure/admin/grow/job-list/job-list.component';
import { CompanyAddComponent } from './secure/admin/grow/company-add/company-add.component';
import { JobApplicationsComponent } from './secure/admin/grow/job-applications/job-applications.component';
import { CoachesComponent } from './secure/coaches/coaches.component';
import { PartnersComponent } from './secure/grow/partners/partners.component';
import { PartnerListComponent } from './secure/admin/partner-list/partner-list.component';
import { PartnerAddComponent } from './secure/admin/partner-add/partner-add.component';
import { MessagePipe } from './filter/message.pipe';
import { TrainingListComponent } from './secure/admin/training-list/training-list.component';
import { TrainingAddComponent } from './secure/admin/training-add/training-add.component';

import { OrdinalDatePipe } from './filter/ordinal-date.pipe';
import { VideoBlogComponent } from './secure/video-blog/video-blog.component';
import { BlogDetailsComponent } from './secure/blog-details/blog-details.component';
import { AdminVideoBlogsListComponent } from './secure/admin/admin-video-blogs-list/admin-video-blogs-list.component';
import { AdminVideoBlogsAddComponent } from './secure/admin/admin-video-blogs-add/admin-video-blogs-add.component';

FullCalendarModule.registerPlugins([ 
  interactionPlugin,
  dayGridPlugin,
  timeGrigPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    UsersComponent,
    ProfileComponent,
    ForgotpasswordComponent,
    HeaderComponent,
    SidebarComponent,
    SecureComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ViewProfileComponent,
    EmailVarifyComponent,
    ResetPasswordComponent,
    FilterPipe,
    NotFoundComponent,
    UserDetailsComponent,
    SelectBoxPipe,
    EventAddComponent,
    EventListComponent,
    UserEventListComponent,
    ToolsComponent,
    MastertoolsComponent,
    ViewtoolComponent,
    ChatComponent,
    MyFavouriteComponent,
    AdminDealroomComponent,
    AddDealroomComponent,
    RoomUsersComponent,
    RoomUserRequestComponent,
    ProjectToolComponent,
    ProjectToolViewComponent,
    UserMessageComponent,
    LearningAddComponent,
    LearningListComponent,
    CategoryListComponent,
    NotificationListComponent,
    PostViewComponent,
    Tool3Component,
    Tool4Component,
    Tool4viewComponent,
    Tool3viewComponent,
    FollowingPostComponent,
    UpgradeComponent,
    OurPlanComponent,
    PurchasePlanComponent,
    TestingSkillsComponent,
    SoftSkillsComponent,
    GenericSkillsComponent,
    TrainingComponent,
    CvLinkdinComponent,
    CoachingComponent,
    AdminDashboardComponent,
    JobDetailsComponent,
    JobSearchComponent,
    JobAddComponent,
    JobListComponent,
    CompanyAddComponent,
    JobApplicationsComponent,
    CoachesComponent,
    PartnersComponent,
    PartnerListComponent,
    PartnerAddComponent,
    MessagePipe,
    TrainingListComponent,
    TrainingAddComponent, 
    OrdinalDatePipe, 
    VideoBlogComponent, 
    BlogDetailsComponent, 
     AdminVideoBlogsListComponent,
     AdminVideoBlogsAddComponent,



    //SecondHeaderComponent
    
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    MetismenuAngularModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right'
    }),
    AngularEditorModule,
    NgxPaginationModule,
    ChartsModule,
    TooltipModule,
    //ChatModule,
    //MyFavouriteModule,
    SharedModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FullCalendarModule,
    CreditCardDirectivesModule
    
    
  ],
  providers: [AuthService, DataService, DealroomService, LearningService,
    {provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi : true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true,},
    ],
  /* providers: [], */
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
