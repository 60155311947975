import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../confirmed.validator';
import {Location} from '@angular/common';
import{ GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  ChangePassForm: FormGroup;
  public loading: boolean;

  user: any;
  imageURL:string;
  viewForm: FormGroup;
  country_name:string;
  state_name:string;
  profile_percentage: number;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    
    this.ChangePassForm = this.formBuilder.group({
        current_password: ['', [Validators.required]],
        new_password: ['', [Validators.required, Validators.minLength(8)]],
        confirm_password: ['', [Validators.required, Validators.minLength(8)]],
    },{ 
      validator: ConfirmedValidator('new_password', 'confirm_password')
    })

    this.authService.getUserById(this.authService.user.id).subscribe((data: any)=>{
      if(data.status){
        this.user = data.user;
        if(this.user){
         
          if(this.user.country_id && this.user.state_id && this.user.country && this.user.state){
            this.country_name = this.user.country.name;
            this.state_name = this.user.state.name;
          }
          this.profile_percentage = this.user.profile_percentage;
        }
        
        if(this.user.profile_pic){
          //let path = GlobalConstants.ImagePATH+'/'+'/uploads/users/photo/';
          let path =  GlobalConstants.user_profile_path;
          this.imageURL = path+this.user.profile_pic;
        } else {
          let path =  GlobalConstants.ImagePATH;
          this.imageURL = path+'images/user.png';
        }
      }else{
        //alert(data.message);
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });

    this.viewForm = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, Validators.email])],
        name: ['', Validators.required],
        id: [''],
        profile_image: []
    });

  }

  get profilePercentage() {
    return `${this.profile_percentage}%`;
  }
  
  get control() {
    return this.ChangePassForm.controls;
  }

  submit() {
    if(this.ChangePassForm.invalid) {
      return;
    }
    this.loading = true;

    const formData = new FormData();
    formData.append('current_password', this.ChangePassForm.get('current_password').value);
    formData.append('new_password', this.ChangePassForm.get('new_password').value);
    formData.append('confirm_password', this.ChangePassForm.get('confirm_password').value);
    formData.append('id', this.authService.user.id);

    this.authService.changePassword(this.ChangePassForm.value).subscribe((res:any)=>{
    // this.authService.changePassword(formData).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Password changed successfully', "Success");
        //this.router.navigateByUrl('dashboard');
        this.ChangePassForm.reset();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
        //alert(res.message);
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
      //alert(err.error.message);
    })
  }

  backClicked() {
    this._location.back();
  }

}
