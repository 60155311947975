import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingService } from 'src/app/services/training.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { VideoAndBlogService } from 'src/app/services/videoandblog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-admin-video-blogs-add',
  templateUrl: './admin-video-blogs-add.component.html',
  styleUrls: ['./admin-video-blogs-add.component.css']
})
export class AdminVideoBlogsAddComponent implements OnInit {

  CreateForm: FormGroup;
  imageUrl ='';
  isAddMode: boolean;
  id: string;
  changeIcon: boolean = false;
  changeHoverIcon: boolean = false;
  selectedvalue: number=0;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '7rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    customClasses: [
      {
        name: 'bgcolor',
        class: 'bgcolor',
      },
    ]
  };
  constructor(private formBuilder: FormBuilder,
    private videoAndBlogService: VideoAndBlogService,
    private router: Router, private route: ActivatedRoute,
    private toastr: ToastrService,
    private _location: Location) {

    this.CreateForm = this.formBuilder.group({
      id: [],
      type: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      image_url: [''],
      video_url: [''],
    })
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    if (!this.isAddMode) {
      this.videoAndBlogService
        .getSingleVideoBlogs({ 'id': this.id }).subscribe((res: any) => {
          if (res.status) {
            this.CreateForm = this.formBuilder.group({
              id: [],
              type: ['', [Validators.required]],
              title: ['', [Validators.required]],
              description: ['', [Validators.required]],
              image_url: [''],
              video_url: [''],
            })
            this.CreateForm.get('id').setValue(res.data.id);
            this.CreateForm.get('type').setValue(res.data.type);
            this.CreateForm.get('title').setValue(res.data.title);
            this.CreateForm.get('description').setValue(res.data.description);
            this.CreateForm.get('video_url').setValue(res.data.video_url);
            this.imageUrl = res.data.type == 'Blog'?res.data.image_url:'';
            this.selectedvalue = res.data.type;
          } else {
            this.toastr.error("data not found.", "Error");
          }
        })
    }
  }

  get control() {
    return this.CreateForm.controls;
  }

  submit() {
    if (this.CreateForm.invalid) {
      return;
    }
    if(this.CreateForm.get('type').value == 'Blog' && (this.CreateForm.get('image_url').value == undefined)){
      this.CreateForm.get('image_url').setErrors({required:true});
      return;
    }else{
      this.CreateForm.get('image_url').setErrors(null);
    }
    if(this.CreateForm.get('type').value == 'Video' && (this.CreateForm.get('video_url').value == '' || this.CreateForm.get('video_url').value == undefined)){
      this.CreateForm.controls.video_url.setErrors({required:true});
      return;
    }else{
      this.CreateForm.controls.video_url.setErrors(null);
    }
    if (this.isAddMode) {
      console.log('Create')
      this.createVideoAndBlog();
    } else {
      console.log('update')
      this.updateVideoAndBlog();
    }
  }

  createVideoAndBlog() {
    const formData = new FormData();
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('type', this.CreateForm.get('type').value);
    if(this.CreateForm.get('type').value == 'Video'){
      formData.append('video_url', this.CreateForm.get('video_url').value);
    }else{
      formData.append('image_url', this.CreateForm.get('image_url').value);
    }
    formData.append('description', this.CreateForm.get('description').value);

    this.videoAndBlogService.store(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data add successfully', "Success");
        this.router.navigateByUrl('/admin-videos-blogs-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  updateVideoAndBlog() {
    console.log('update2')
    const formData = new FormData();
    formData.append('id', this.CreateForm.get('id').value);
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('type', this.CreateForm.get('type').value);
    if(this.CreateForm.get('type').value == 'Video'){
      formData.append('video_url', this.CreateForm.get('video_url').value);
    }else{
      if(this.CreateForm.get('image_url').value != '' && this.CreateForm.get('image_url').value != undefined && this.CreateForm.get('image_url').value != null){
        formData.append('image_url', this.CreateForm.get('image_url').value);
      }
    }
    formData.append('description', this.CreateForm.get('description').value);

    this.videoAndBlogService.update(formData).subscribe((res: any) => {
      if (res.status) {
        this.toastr.success('Data update successfully', "Success");
        this.router.navigateByUrl('/admin-videos-blogs-list');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  isFileAdd = false;
  onFileChange(event, isIcon = '') {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
        this.toastr.error("Allow only 20 mb file size", "Error");
        return;
      }
      this.control.image_url.setValue(file);
    }
  }

  backClicked() {
    this._location.back();
  }

  clear_value(){
    // if(this.CreateForm.get('type').value == 'Video'){
    //   this.CreateForm.get('image_url').setValue('');
    // }else{
    //   this.CreateForm.get('video_url').setValue('');
    // }
  }
}
