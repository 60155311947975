import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  saveEvent(req){
    return this.http.post(`${this.url}/events/store`, req);
  }

  getAllEvent(){
    return this.http.get(`${this.url}/events/get_all`, this.httpOptions);
  }

  delete(id){
    return this.http.get(`${this.url}/events/delete/`+id, this.httpOptions);
  }

  // getAllUserEvent(){
  //   return this.http.get(`${this.url}/events/get_all_events`, this.httpOptions);
  // }

  getAllUserEvent(search_text, event_type, selected_date){
    return this.http.get(`${this.url}/events/get_all_events?search=`+search_text+`&event_type=`+event_type+`&event_date=`+selected_date, this.httpOptions);
  }

  register(id){
    return this.http.get(`${this.url}/events/register/`+id, this.httpOptions);
  }

  getLatestEvent(){
    return this.http.get(`${this.url}/events/get_latest_events`, this.httpOptions);
  }

  cancelRegistraion(id){
    return this.http.get(`${this.url}/events/cancel_registration/`+id, this.httpOptions);
  }
  
  getAllEventList(){
    return this.http.get(`${this.url}/events/get_all_event_list`, this.httpOptions);
  }

  getLatest3Event(){
    return this.http.get(`${this.url}/events/get_latest_3_registered_event`, this.httpOptions);
  }

}
